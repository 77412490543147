import { Box, Card, Skeleton } from '@mui/material'

import { getStyles } from '../Card/DonationCard.styles'

import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function PastDonationCardSkeleton({ isMatchRequestVariant }: { isMatchRequestVariant?: boolean }) {
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <Card sx={Styles.Wrapper}>
      <Box display="flex" flexDirection="column" gap={2}>
        {isMatchRequestVariant ? (
          <Box display="flex" flexDirection="row" gap={1.5} alignItems="center">
            <Skeleton variant="rectangular" width={75} height={24} style={{ borderRadius: 2 }} />
            <Skeleton variant="circular" width={8} height={8} />
            <Skeleton variant="rectangular" width={125} height={24} />
          </Box>
        ) : (
          <Skeleton variant="rectangular" width={150} height={16} />
        )}
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Skeleton variant="rectangular" width="65%" height={27} />
          <Skeleton variant="rectangular" width={64} height={64} />
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Skeleton variant="rectangular" width={120} height={21} />
            <Skeleton variant="rectangular" width={50} height={21} />
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Skeleton variant="rectangular" width={260} height={21} />
            <Skeleton variant="rectangular" width={50} height={21} />
          </Box>
          <Skeleton variant="rectangular" width="100%" height={1} />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Skeleton variant="rectangular" width={120} height={24} />
            <Skeleton variant="rectangular" width={70} height={24} />
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
