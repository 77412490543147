import { Box, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom-v6'

import { ModalProps } from '../../../../../../libs/shared/ui-lemonade/src/components/modal'

import { Alert, Button, Modal } from '@percent/lemonade'
import { SuccessIcon } from '@percent/workplace-giving/common/components/SuccessIcon/SuccessIcon'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

type OpportunityFormSuccessModalProps = Pick<ModalProps, 'open'> & {
  isEditMode?: boolean
  onClose: VoidFunction
  opportunityId: string
  sessionsFailedToCreateError?: boolean
}

export function OpportunityFormSuccessModal({
  open,
  onClose,
  isEditMode,
  opportunityId,
  sessionsFailedToCreateError
}: Readonly<OpportunityFormSuccessModalProps>) {
  const { t, i18n } = useTranslation()
  const { theme } = useColorTheme()

  return (
    <Modal onClose={onClose} open={open}>
      <Box padding={6} display="flex" flexDirection="column" alignItems="center">
        <SuccessIcon />
        <Typography my={2} textAlign="center" fontSize={24} fontWeight={600}>
          {t(
            isEditMode
              ? 'workplace_giving.wizard.edit.success.modal.title'
              : 'workplace_giving.wizard.success.modal.title'
          )}
        </Typography>
        <Typography mb={sessionsFailedToCreateError ? 1 : 4} textAlign="center" fontSize={14} color={theme.neutral300}>
          {t(
            isEditMode
              ? 'workplace_giving.wizard.edit.success.modal.description'
              : 'workplace_giving.wizard.success.modal.description'
          )}
        </Typography>
        {sessionsFailedToCreateError ? (
          <Box width="100%" mb={4}>
            <Alert variant="warning" title={t('workplace_giving.wizard.sessionsCreationError.title')}>
              <Trans
                i18n={i18n}
                i18nKey="workplace_giving.wizard.sessionsCreationError.description"
                components={{
                  1: <Link to={AppRoute.EDIT_OPPORTUNITY.replace(':opportunityId', opportunityId)} />
                }}
              />
            </Alert>
          </Box>
        ) : null}
        <Button variant="primary" onPress={onClose}>
          {t('workplace_giving.opportunity_wizard.success.close')}
        </Button>
      </Box>
    </Modal>
  )
}
