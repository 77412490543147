import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Table: {
    border: `1px solid ${customPalette.gray80}`,
    borderRadius: lemonadeTheme.borderRadii.medium
  },
  TableRow: {
    th: {
      borderBottom: `1px solid ${customPalette.gray80}`
    }
  },
  TableRowHeader: {
    th: {
      borderBottom: `1px solid ${customPalette.gray80}`
    }
  },
  TableFooter: {
    borderTop: `1px solid ${customPalette.gray80}`
  },
  Chip: {
    color: customPalette.gray600,

    '&.Mui-disabled': {
      opacity: 1,
      fontWeight: lemonadeTheme.fontWeights.medium,
      color: customPalette.black,
      backgroundColor: customPalette.gray70
    }
  },
  LocationLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '2px 10px',
    backgroundColor: customPalette.gray90,
    borderRadius: lemonadeTheme.borderRadii.medium,

    svg: {
      height: lemonadeTheme.sizes[5],
      width: lemonadeTheme.sizes[5],
      minWidth: lemonadeTheme.sizes[5],
      marginRight: lemonadeTheme.sizes[1]
    }
  }
})
