import styled from 'styled-components'

import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

const Flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}

const TotalAmountBox = {
  ...Flex,

  '& > p:first-of-type': {
    flex: 1,
    marginRight: lemonadeTheme.sizes[3]
  },

  '& > p:last-of-type, & > div:last-of-type': {
    flex: 1,
    textAlign: 'right'
  }
}

const PartialAmountBox = {
  ...Flex,
  justifyContent: 'flex-start',
  '& > p:first-of-type': {
    marginRight: lemonadeTheme.sizes[3]
  },

  '& > p:last-of-type': {
    flexGrow: 1,
    textAlign: 'right'
  }
}

export const getStyles = (customPalette: ColorPaletteType) => ({
  Wrapper: {
    padding: lemonadeTheme.sizes.m,
    borderRadius: '8px',
    boxShadow: '0px 2px 16px 0px #0D0D0D14',
    display: 'flex',
    flexDirection: 'column',
    gap: lemonadeTheme.sizes.s
  },
  Header: {
    display: 'flex',
    alignItems: 'center',
    height: '16px',
    gap: '8px',
    position: 'relative',

    '> *:not(:last-child)': {
      position: 'relative'
    },

    '> *:nth-last-of-type(2)': {
      marginRight: '16px'
    },

    '> *:nth-last-of-type(2)::after': {
      content: '"\\2022"',
      color: lemonadeTheme.colors.gray100,
      fontSize: '1rem',
      position: 'absolute',
      right: '-16px',
      top: '50%',
      transform: 'translateY(-50%)'
    }
  },
  HeaderTime: {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    fontWeight: lemonadeTheme.fontWeights.regular
  },
  NameBox: {
    ...Flex,
    flexDirection: 'row',

    a: {
      textDecorationColor: customPalette.gray600,
      textUnderlineOffset: '4px'
    }
  },
  Name: {
    fontSize: lemonadeTheme.fontSizes.bodyLarge,
    fontWeight: lemonadeTheme.fontWeights.semiBold,
    color: lemonadeTheme.colors.black,
    marginRight: lemonadeTheme.sizes[3]
  },
  PartialAmountBox,
  PendingPartialAmountBox: {
    ...PartialAmountBox,
    color: customPalette.gray100,

    '& > p:first-of-type': {
      flex: 1,
      marginRight: lemonadeTheme.sizes[3]
    },

    '& > p:last-of-type, & > div:last-of-type': {
      flex: 1,
      textAlign: 'right'
    }
  },
  PartialAmountText: {
    fontSize: lemonadeTheme.fontSizes.bodySmall
  },
  Separator: {
    height: '1px',
    backgroundColor: lemonadeTheme.colors.gray90
  },
  TotalAmountBox,
  PendingTotalAmountBox: {
    ...TotalAmountBox,
    color: customPalette.gray100
  },
  TotalAmountText: {
    fontSize: lemonadeTheme.fontSizes.body,
    fontWeight: lemonadeTheme.fontWeights.semiBold
  },
  ContentBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: lemonadeTheme.sizes.xs
  },
  ResendReceiptButton: {
    position: 'absolute',
    right: 0,
    padding: 0,
    minWidth: lemonadeTheme.sizes.m,
    width: lemonadeTheme.sizes.m,
    height: lemonadeTheme.sizes.m,
    ':hover': {
      background: 'none'
    }
  },
  DonationStatus: styled.span`
    cursor: default;
  `
})
