import { theme as lemonadeTheme } from '@percent/lemonade'
import { getFormBasicStyles } from '@percent/workplace-giving/app/Volunteer/OpportunityForm/CreateOpportunity.styles'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Wrapper: {
    ...getFormBasicStyles(customPalette),
    display: 'flex',
    flexFlow: 'column',
    gap: lemonadeTheme.sizes[4],
    boxSizing: 'border-box',
    overflowY: 'auto',
    height: '100vh',
    padding: `${lemonadeTheme.sizes[4]} ${lemonadeTheme.sizes[6]} ${lemonadeTheme.sizes[6]}`,

    '.mantine-Input-input': {
      paddingLeft: lemonadeTheme.sizes[4],
      paddingRight: lemonadeTheme.sizes[16]
    },

    '@media (min-width: 768px)': {
      height: 'auto',
      maxHeight: '92vh'
    }
  },
  Header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: lemonadeTheme.sizes[3],
    paddingBottom: lemonadeTheme.sizes[4],

    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1px',
      background: lemonadeTheme.colors.gray80,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto'
    }
  },
  Title: {
    fontSize: lemonadeTheme.sizes[5],
    lineHeight: lemonadeTheme.sizes[6],
    fontWeight: lemonadeTheme.fontWeights.semiBold
  },
  CloseBtn: {
    '&:focus': {
      outline: `1px solid ${customPalette.primary400}`
    }
  },
  SectionTitle: {
    marginBottom: lemonadeTheme.sizes[2],
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    fontWeight: lemonadeTheme.fontWeights.medium,
    lineHeight: lemonadeTheme.sizes[6]
  },
  Chip: {
    color: customPalette.gray600,

    '&.Mui-disabled': {
      opacity: 1,
      fontWeight: 500,
      color: customPalette.black,
      backgroundColor: customPalette.gray70
    }
  },
  ButtonsWrapper: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'nowrap',
    gap: lemonadeTheme.sizes[4],
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',

    '& > div': {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: lemonadeTheme.sizes[4],
      width: '100%',

      '@media (min-width: 450px)': {
        width: 'auto'
      }
    },

    '@media (min-width: 450px)': {
      flexFlow: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  },
  DropdownRightSectionWrapper: {
    display: 'flex',
    gap: lemonadeTheme.sizes[1],

    '& > button': {
      minWidth: lemonadeTheme.sizes[6],
      cursor: 'pointer',
      pointerEvents: 'all'
    }
  }
})
