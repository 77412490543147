import { Box, Card } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getStyles } from '../SessionEvent.styles'

import { ChildSessionEvent } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { dayjs } from '@percent/workplace-giving/utils/dayjs/dayjs'
import { Badge, ButtonText } from '@percent/lemonade'
import { SessionEventDetails } from '@percent/workplace-giving/common/components/SessionEventDetails/SessionEventDetails'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

type SessionEventItemProps = {
  sessionEvent: ChildSessionEvent
  onEdit: VoidFunction
  onRemove: VoidFunction
  isEditFlow: boolean
  testId: string
}

export function SessionEventItem({
  sessionEvent,
  onEdit,
  onRemove,
  isEditFlow,
  testId
}: Readonly<SessionEventItemProps>) {
  const { t } = useTranslation()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const isPastEvent = sessionEvent?.startDate ? dayjs(sessionEvent.startDate).isBefore(dayjs()) : false

  return (
    <Card sx={Styles.ItemWrapper(isPastEvent)} data-testid={`sessionItem-${testId}`}>
      <SessionEventDetails {...sessionEvent}>
        {sessionEvent?.participantSpots ? (
          <Badge variant="informative" icon="clock">
            {t('workplace_giving.volunteering.participantSpotsAvailable', {
              participantSpots: sessionEvent?.participantSpots
            })}
          </Badge>
        ) : null}
        {!isEditFlow || (isEditFlow && !isPastEvent) ? (
          <Box display="flex" flexDirection="row" flexWrap="nowrap" gap={1} marginTop={0.5}>
            <ButtonText onPress={onEdit}>{t('workplace_giving.common.edit')}</ButtonText>
            <ButtonText onPress={onRemove}>
              {t(`workplace_giving.common.${isEditFlow ? 'cancel' : 'remove'}`)}
            </ButtonText>
          </Box>
        ) : null}
      </SessionEventDetails>
    </Card>
  )
}
