import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  ParentBackLink: {
    display: 'flex',
    alignItems: 'center',
    color: customPalette.gray500,
    textDecorationColor: customPalette.gray70
  }
})
