import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { Alpha2Code } from 'i18n-iso-countries'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { FlagAvatar, SearchInput, Select } from '@percent/lemonade'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { getAccountFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import countries from '@percent/workplace-giving/i18n/countries'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'
import { getStyles } from './HomePageSearch.styles'
import { getElementHeight } from '@percent/workplace-giving/utils/getElementDimensions'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { useSharedValidationRules } from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export type HomePageSearchProps = Readonly<{
  search?: string
  countryCode: string
  scrolled?: boolean
  onSearch: (_: Record<string, string>) => void
  isError?: boolean
  setSearchElemHeight: (height: number) => void
}>

const SEARCH_ELEM_ID = 'animatedSearch'
const GREETING_ELEM_ID = 'animatedGreeting'

export function HomePageSearch({
  search,
  countryCode,
  onSearch,
  isError,
  setSearchElemHeight,
  scrolled = false
}: HomePageSearchProps) {
  const { t } = useTranslation()
  const authState = useAuth()
  const { track } = useAnalytics()
  const account = getAccountFromAuthState(authState.state)!
  const targetRef = useRef()
  const { validateString } = useSharedValidationRules()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const greetingHeight = getElementHeight(GREETING_ELEM_ID)!

  const isFirstLogin = useMemo(() => {
    return !account?.preferredName

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (targetRef.current) {
      setSearchElemHeight(getElementHeight(SEARCH_ELEM_ID)!)
    }
  }, [setSearchElemHeight])

  const alpha3CountryCodes = useMemo(
    () => [
      {
        value: '',
        label: t('workplace_giving.search.world'),
        prefix: <WorldFlagAvatar />
      },
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, 'en'),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    [t]
  )

  const countryData = useMemo((): typeof alpha3CountryCodes[number] => {
    const countryOption = alpha3CountryCodes.filter(a => a.value === countryCode)[0]

    if (countryOption) {
      return countryOption
    }

    // return global if not found
    return alpha3CountryCodes[0]
  }, [alpha3CountryCodes, countryCode])

  const { errors, values, setFieldValue, handleChange, submitForm } = useFormik({
    initialValues: {
      query: search ?? ''
    },
    validateOnChange: true,
    validationSchema: () =>
      Yup.object().shape({
        query: validateString({ optional: true })
      }),
    onSubmit: ({ query }) => {
      const trimmed = query.trim()

      if (trimmed !== '') {
        onSearch({
          query: trimmed,
          country: countryCode
        })
      }
    }
  })

  useEffect(() => {
    if (!search || search === '') {
      setFieldValue('query', '')
      window.scrollTo({
        top: 0
      })
    }
  }, [search, setFieldValue])

  return (
    <Box sx={Styles.Wrapper(scrolled, greetingHeight)} id={SEARCH_ELEM_ID} ref={targetRef}>
      <Box sx={Styles.ContentWrapper}>
        <Typography variant="h2" sx={Styles.Greeting(scrolled)} id={GREETING_ELEM_ID}>
          {isFirstLogin
            ? t('workplace_giving.home.firstGreeting', {
                name: account?.fullName || undefined,
                interpolation: { escapeValue: false }
              })
            : t('workplace_giving.home.greeting', {
                name: account?.preferredName || undefined,
                interpolation: { escapeValue: false }
              })}
        </Typography>

        <Box sx={Styles.SearchBoxWrapper}>
          <Box sx={Styles.SearchInput}>
            <SearchInput
              name="query"
              handleClearValue={() => setFieldValue('query', '')}
              placeholder={t('workplace_giving.home.causeSearchPlaceholder')}
              value={values.query}
              onChange={handleChange}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  track({
                    event: 'Donation Organisation Search',
                    properties: {
                      origin: 'Home',
                      countryCode: countryData.value
                    }
                  })
                  submitForm()
                }
              }}
              aria-label="search"
              status={errors.query || isError ? 'danger' : 'default'}
              statusMessage={isError ? t('workplace_giving.errors.unexpected') : errors.query}
            />
          </Box>
          <Box sx={Styles.SearchSelect(scrolled)}>
            <Select
              placeholder="Select country"
              searchable
              onChange={event => {
                onSearch({
                  query: values.query,
                  country: event.value
                })
              }}
              data-testid="country"
              defaultValue={countryData}
              options={alpha3CountryCodes}
              showPrefixForSelectedOption
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
