import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Title: {
    marginBottom: lemonadeTheme.sizes[4],
    fontSize: lemonadeTheme.sizes[6],
    lineHeight: lemonadeTheme.sizes[8],
    fontWeight: lemonadeTheme.fontWeights.semiBold
  },
  ItemWrapper: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    gap: lemonadeTheme.sizes[4],
    padding: `${lemonadeTheme.sizes[4]} 0`,
    borderBottom: `1px solid ${customPalette.gray90}`
  },
  ActionsWrapper: {
    marginLeft: 'auto'
  }
})
