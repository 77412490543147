import { Link, useNavigate, useParams } from 'react-router-dom-v6'
import { t } from 'i18next'
import { Box } from '@mui/material'
import { InfiniteData } from '@tanstack/react-query'

import { OpportunityDetailsSidebar } from './OpportunityDetailsSidebar/OpportunityDetailsSidebar'
import { OpportunityDescription } from './OpportunityDescription/OpportunityDescription'
import { Icon, IllustratedMessage, Loader } from '@percent/lemonade'
import { getOpportunityDetails } from '@percent/workplace-giving/api/opportunity/getOpportunityDetails'
import { useInfiniteQuery, useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { PageContent } from '@percent/workplace-giving/common/components/Layout/PageContent'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { Attendees } from './Attendees/Attendees'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { SkillsAndImpact } from './SkillsAndImpact/SkillsAndImpact'
import { getOpportunities } from '@percent/workplace-giving/api/opportunity/getOpportunities/getOpportunities'
import { Opportunity } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { OpportunitySessions } from './OpportunitySessions/OpportunitySessions'
import { getStyles } from './OpportunityDetails.styles'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function OpportunityDetails() {
  const { opportunityId } = useParams()
  const { logError } = useLogger()
  const navigate = useNavigate()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    ['getOpportunityDetails', opportunityId!],
    getOpportunityDetails,
    {
      enabled: !!opportunityId
    }
  )

  const {
    data: sessionsData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    error: sessionsDataError,
    refetch: refetchSessions,
    isRefetching: isRefetchingSessions
  } = useInfiniteQuery(['getOpportunities', { parentId: opportunityId, pageSize: 10 }], getOpportunities, {
    enabled: !!opportunityId
  })

  const mappedSessionEventsData: InfiniteData<Opportunity[]> | undefined = sessionsData && {
    ...sessionsData,
    pages: sessionsData.pages?.map(page => page.data)
  }

  if (isLoading) {
    return <Loader />
  }

  if (error || sessionsDataError) {
    logError(error)

    return (
      <Page testId="opportunity-details-page-wrapper-error">
        <Box width="100%" textAlign="center" paddingTop={4}>
          <IllustratedMessage
            illustration="no-results"
            title={t('workplace_giving.errors.pageUnavailable')}
            description={t('workplace_giving.errors.invalidPageUrl')}
            cta={{
              label: t('workplace_giving.volunteering.discoverOpportunities'),
              onPress: () => navigate(AppRoute.VOLUNTEER)
            }}
          />
        </Box>
      </Page>
    )
  }

  return (
    <Page testId="opportunity-details-page-wrapper">
      {data && (
        <>
          <PageContent>
            {data.parentId && (
              <Link to={`/volunteer/opportunities/${data.parentId}`} style={Styles.ParentBackLink}>
                <Icon size="m" name="arrow-left" />
                {t('workplace_giving.volunteering.sessionLinkToEventSeries')}
              </Link>
            )}
            <OpportunityDescription {...data} />
            {!!data.skills?.length || !!data.activities?.length ? (
              <SkillsAndImpact activities={data.activities} skills={data.skills} />
            ) : null}
            {data.isSeries && mappedSessionEventsData ? (
              <OpportunitySessions
                sessions={mappedSessionEventsData}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isLoading={isFetchingNextPage || isRefetchingSessions}
                refetchSessions={refetchSessions}
              />
            ) : null}
            {!data.isSeries ? (
              <Attendees
                participantSpots={data.spots}
                currentUserAttending={data.participation.status === 'participating'}
              />
            ) : null}
          </PageContent>
          <OpportunityDetailsSidebar
            {...data}
            refetch={refetch}
            isLoading={isLoading || isRefetching}
            noSessions={!mappedSessionEventsData?.pages.flat(1).length}
          />
        </>
      )}
    </Page>
  )
}
