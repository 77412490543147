import { Styles } from '@percent/workplace-giving/types'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'

export const SidePanelWrapper: Styles = {
  borderRadius: lemonadeTheme.borderRadii.large,
  padding: '24px',
  boxShadow: '0px 2px 16px 0px #0D0D0D14',
  backgroundColor: lemonadeTheme.colors.white,
  marginBottom: '1rem',

  [queryWidthLessThan('l')]: {
    padding: '0px',
    boxShadow: 'none'
  }
}
