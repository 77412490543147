import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'
import { hexToRgba } from '@percent/workplace-giving/utils/hexToRgba'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Wrapper: {
    paddingY: '24px',
    background: `radial-gradient(43.49% 51.92% at 88.61% 88.8%, ${hexToRgba(
      customPalette.primary,
      '0.10'
    )} 0%, ${hexToRgba(customPalette.primary, '0')} 100%), radial-gradient(87.11% 94.04% at 83.75% 31.88%, ${hexToRgba(
      customPalette.primary,
      '0.10'
    )} 0%, ${hexToRgba(customPalette.primary, '0')} 100%), #FFF`,
    borderBottom: `1px solid ${customPalette.gray90}`,
    overflow: 'hidden',
    borderRadius: '16px 16px 0 0'
  },
  TextLine: {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: '24px'
  }
})
