import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  PickerStyles: {
    width: '100%',

    '*': {
      fontFamily: 'Inter, sans-serif !important',
      fontSize: lemonadeTheme.fontSizes.bodySmall
    },

    '.mantine-InputPlaceholder-placeholder': {
      color: customPalette.gray300
    },

    '.mantine-DateTimePicker-input': {
      transition: 'none',
      borderColor: lemonadeTheme.colors.gray80,
      color: `${lemonadeTheme.colors.black} !important`,

      '[class*="placeholder"]': {
        color: customPalette.gray300
      },

      '&:not([data-disabled="true"]):hover': {
        outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.gray80}`,
        borderColor: lemonadeTheme.colors.black
      },

      '&:not([data-disabled="true"]):focus': {
        borderColor: lemonadeTheme.colors.black
      },

      '&[data-error="true"]': {
        borderColor: lemonadeTheme.colors.alert400
      },

      '&[data-error="true"]:hover': {
        outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.alert100}`,
        borderColor: lemonadeTheme.colors.alert400
      },

      '&:not([data-disabled="true"]):active': {
        borderColor: lemonadeTheme.colors.black
      }
    }
  }
})
