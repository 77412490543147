import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'
import { hexToRgba } from '@percent/workplace-giving/utils/hexToRgba'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Wrapper: (withBudget?: boolean) => ({
    paddingY: lemonadeTheme.sizes[6],
    background: `radial-gradient(43.49% 51.92% at 88.61% 88.8%, ${hexToRgba(
      customPalette.primary,
      '0.10'
    )} 0%, ${hexToRgba(customPalette.primary, '0')} 100%), radial-gradient(87.11% 94.04% at 83.75% 31.88%, ${hexToRgba(
      customPalette.primary,
      '0.10'
    )} 0%, ${hexToRgba(customPalette.primary, '0')} 100%), #FFF`,
    borderBottom: withBudget ? `1px solid ${customPalette.gray90}` : 'none',
    overflow: 'hidden',
    borderRadius: withBudget
      ? `${lemonadeTheme.borderRadii.large} ${lemonadeTheme.borderRadii.large}  0 0`
      : lemonadeTheme.borderRadii.large
  })
})
