import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Button: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    gap: lemonadeTheme.sizes[1],

    '& > span': {
      fontWeight: lemonadeTheme.fontWeights.medium
    }
  },

  BtnText: {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes[6],
    borderBottom: `1px solid ${customPalette.gray600}`
  },

  FiltersCounter: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: lemonadeTheme.sizes[4],
    width: 'auto',
    height: lemonadeTheme.sizes[4],
    paddingX: lemonadeTheme.sizes[1],
    borderRadius: lemonadeTheme.borderRadii.large,
    background: customPalette.primary400,
    color: lemonadeTheme.colors.white,
    fontSize: lemonadeTheme.sizes[3]
  }
})
