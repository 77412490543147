import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Alpha2Code } from 'i18n-iso-countries'
import { useDebounce } from 'usehooks-ts'
import { identity } from 'lodash'

import countries from '@percent/workplace-giving/i18n/countries'
import { Alert, AsyncSelect, Card, FileInput, FlagAvatar, FormField, Select, TextInput } from '@percent/lemonade'
import { RichTextEditor } from '@percent/workplace-giving/common/components'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'
import { useAuth, useQuery } from '@percent/workplace-giving/common/hooks'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { searchVerifiedOrganisations } from '@percent/workplace-giving/api/search/searchOrganisations/searchVerifiedOrganisations'
import { createShortLink, Money } from '@percent/utility'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { DynamicCurrencyInput, OrganisationHeaderWithImage } from '@percent/domain/giving'
import * as Styles from './FundraiserWizard.styles'
import { config } from '@percent/workplace-giving/config/config'
import { FundraiserWizardFormProps } from './FundraisrWizardForm.types'
import { VerifiedOrganisationSearchResult } from '@percent/workplace-giving/api/search/searchOrganisations/searchVerifiedOrganisations.types'
import { FILE_SIZE_2MB_HUMAN } from '@percent/workplace-giving/constants/files'

export function FundraiserWizardForm({
  handleCoverImageChange,
  coverImageError,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  currency,
  currencies
}: Readonly<FundraiserWizardFormProps>) {
  const { t, i18n } = useTranslation()
  const authState = useAuth()
  const codeFromAuthState = getCountryCodeFromAuthState(authState.state)
  const [selectedOrgDetails, setSelectedOrgDetails] = useState<VerifiedOrganisationSearchResult | undefined>(undefined)
  const intlConfigLang = i18n.language

  const alpha3CountryCodes = useMemo(
    () => [
      {
        value: '',
        label: t('workplace_giving.search.world'),
        prefix: <WorldFlagAvatar />
      },
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, 'en'),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    [t]
  )

  const defaultCountryValue = alpha3CountryCodes.filter(a => a.value === codeFromAuthState)[0]

  const resetOrgIdFieldAfterCountryChange = useCallback(async () => {
    await setFieldValue('organisationId', '')
    await setFieldTouched('organisationId', false, true)
    setSelectedOrgDetails(undefined)
  }, [setFieldTouched, setFieldValue])

  const [query, setQuery] = useState<string>('')
  const [searchCountryCode, setSearchCountryCode] = useState<string>(defaultCountryValue.value)

  const debouncedQuery = useDebounce(query, 200)
  const { data: searchData, isFetching } = useQuery(
    ['searchNonProfits', { query: debouncedQuery, pageSize: 10, countryCode: searchCountryCode }],
    searchVerifiedOrganisations,
    { enabled: !!debouncedQuery }
  )
  const shortLanguage = i18n.language.split('-')[0]

  const getOrganisationDescription = useCallback(
    (organisation: VerifiedOrganisationSearchResult): string => {
      return [
        organisation.website ? createShortLink(organisation.website) : undefined,
        `${t('workplace_giving.common.id')}: ${organisation.organisationIdInRegistry}`,
        countries.getName(organisation.countryCode, shortLanguage)
      ]
        .filter(identity)
        .join(' | ')
    },
    [shortLanguage, t]
  )

  const searchResults: SelectOption[] = useMemo(() => {
    return searchData
      ? searchData.data.map(organisation => ({
          value: organisation.organisationId,
          label: organisation.name,
          description: getOrganisationDescription(organisation)
        }))
      : []
  }, [searchData, getOrganisationDescription])

  useEffect(() => {
    if (searchData?.data && values.organisationId) {
      const selectedOrg = searchData.data.find(org => org.organisationId === values.organisationId)
      setSelectedOrgDetails(selectedOrg)
    }
  }, [searchData?.data, values.organisationId])

  return (
    <Box sx={Styles.Form} component="form" onSubmit={event => event.preventDefault()}>
      <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.fundraiserDetails')}</Typography>
      <FormField
        label={t('workplace_giving.wizard.coverImage.label')}
        necessity="required"
        status={coverImageError ? 'danger' : 'default'}
        statusMessage={coverImageError}
        data-testid="coverImageField"
      >
        <FileInput
          placeholder={t('workplace_giving.wizard.coverImage.placeholder', {
            acceptedFileFormats: config.acceptedBannerImgFormatsHuman,
            maxFileSize: FILE_SIZE_2MB_HUMAN
          })}
          accept={config.acceptedBannerImgFormats}
          dataTestId="coverImageInput"
          onChange={file => handleCoverImageChange(file)}
          showPreview
        />
      </FormField>
      <Alert variant="info">{t('workplace_giving.wizard.coverImage.description')}</Alert>

      <FormField
        label={t('workplace_giving.wizard.name.label')}
        necessity="required"
        status={touched.title && errors.title ? 'danger' : 'default'}
        statusMessage={errors.title}
        data-testid="nameField"
      >
        <TextInput
          name="title"
          value={values.title}
          placeholder={t('workplace_giving.wizard.name.placeholder')}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FormField>

      <FormField
        label={t('workplace_giving.wizard.description.label')}
        necessity="required"
        status={touched.story && errors.story ? 'danger' : 'default'}
        statusMessage={errors.story}
        data-testid="descriptionField"
      >
        <RichTextEditor
          fieldName="description"
          error={!!errors.story && !!touched.story}
          placeholder={t('workplace_giving.wizard.fundraiser.description.placeholder')}
          content={values.story}
          handleBlur={e => {
            handleBlur(e)
            setFieldTouched('story')
          }}
          handleUpdate={(newValue: string) => setFieldValue('story', newValue)}
        />
      </FormField>

      <Typography sx={Styles.SectionTitle} data-testid="goal">
        {t('workplace_giving.wizard.goal.label')}
      </Typography>
      <FormField
        label={t('workplace_giving.wizard.goal.label')}
        necessity="required"
        status={touched?.money?.amount && errors?.money?.amount ? 'danger' : 'default'}
        statusMessage={errors?.money?.amount}
        data-testid="amountInput"
      >
        <DynamicCurrencyInput
          id="money.amount"
          name="money.amount"
          defaultCurrency={currency}
          locale={intlConfigLang}
          currencies={currencies}
          value={values?.money?.amount ? (values.money as Money) : undefined}
          placeholder={{ amount: 0, currency }}
          onValueChange={value => {
            if (value) {
              setFieldValue('money', value)
            }
            setFieldValue('money.amount', value?.amount)
          }}
          onBlur={handleBlur}
          allowNegativeValue={false}
        />
      </FormField>

      <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.supportedNonprofit')}</Typography>
      <FormField
        label={t('workplace_giving.wizard.nonprofitCountry.label')}
        description={t('workplace_giving.wizard.nonprofitCountry.description')}
        status={touched.organisationCountry && errors.organisationCountry ? 'danger' : 'default'}
        statusMessage={errors.organisationCountry}
        data-testid="organisationCountryField"
        necessity="required"
      >
        <Select
          status={errors.organisationCountry ? 'danger' : 'default'}
          placeholder={t('workplace_giving.wizard.nonprofitCountry.placeholder')}
          options={alpha3CountryCodes}
          defaultValue={defaultCountryValue}
          onChange={event => {
            setFieldValue('nonprofitCountry', event.value)
            setSearchCountryCode(event.value)
            resetOrgIdFieldAfterCountryChange()
          }}
          searchable
          showPrefixForSelectedOption
        />
      </FormField>

      <FormField
        label={t('workplace_giving.wizard.selectNonprofit.label')}
        status={touched.organisationId && errors.organisationId ? 'danger' : 'default'}
        statusMessage={errors.organisationId}
        data-testid="organisationSearchField"
        necessity="required"
      >
        <AsyncSelect
          status={errors.organisationId ? 'danger' : 'default'}
          name="organisationId"
          key={searchCountryCode}
          placeholder={t('workplace_giving.wizard.selectNonprofit.placeholder')}
          onChange={e => {
            setFieldValue('organisationId', e?.value ?? '')
            setFieldTouched('organisationId')
          }}
          options={searchResults}
          setQuery={e => {
            setQuery(e)
            setFieldTouched('organisationId')
            setFieldValue('organisationId', e)
          }}
          query={query}
          loading={isFetching}
          loadingText={t('workplace_giving.donationMatchRequest.organisationSearch.loading')}
          noResultsFoundText={
            query
              ? t('workplace_giving.donationMatchRequest.organisationSearch.noResults')
              : t('workplace_giving.donationMatchRequest.organisationSearch.emptyQuery')
          }
          data-testid="organisationSearch"
        />
      </FormField>

      {selectedOrgDetails ? (
        <Card>
          <Box paddingX={3} paddingTop={3} width="100%" data-testid="organisationDetailsPreview">
            <OrganisationHeaderWithImage
              title={selectedOrgDetails.displayName || selectedOrgDetails.name}
              countryCode={selectedOrgDetails.countryCode}
              registryId={selectedOrgDetails.organisationIdInRegistry}
              iconUrl={selectedOrgDetails.logoUrl}
              website={selectedOrgDetails.website}
            />
          </Box>
        </Card>
      ) : null}
    </Box>
  )
}
