import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { InfiniteData } from '@tanstack/react-query'
import { Alpha2Code, Alpha3Code, toAlpha2 } from 'i18n-iso-countries'
import { SetURLSearchParams } from 'react-router-dom-v6'
import { Skeleton } from '@mantine/core'

import { Alert, FlagAvatar, IllustratedMessage } from '@percent/lemonade'
import { OrganisationCard } from '@percent/domain/giving'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { getPartnerFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { VerifiedOrganisationSearchResult } from '@percent/workplace-giving/api/search/searchOrganisations/searchVerifiedOrganisations.types'
import { SearchResultCardSkeleton } from '@percent/workplace-giving/common/components/SearchResultCardSkeleton/SearchResultCardSkeleton'
import countries from '@percent/workplace-giving/i18n/countries'
import * as Styles from './SearchResults.styles'
import { useDonationForm } from '@percent/workplace-giving/common/hooks/useDonationForm/useDonationForm'
import { TooltipTaxInformation } from '@percent/workplace-giving/common/components/TooltipTaxInformation/TooltipTaxInformation'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'
import { useInfiniteScroll } from '@percent/workplace-giving/common/hooks/useInfiniteScroll/useInfinteScroll'

export type SearchResultsParams = Readonly<{
  totalResults: number
  searchTerm: string
  data?: InfiniteData<VerifiedOrganisationSearchResult[]>
  isLoading?: boolean
  hasMore?: boolean
  loadMore?: () => void
  countryCode?: string
  setSearchParams: SetURLSearchParams
  replacedByGlobalSearch: boolean
  userCountryCode?: string | null
  hideLocalSearch: boolean
}>

export function SearchResults({
  searchTerm,
  totalResults,
  data,
  isLoading,
  hasMore,
  loadMore,
  countryCode,
  setSearchParams,
  replacedByGlobalSearch,
  userCountryCode,
  hideLocalSearch
}: SearchResultsParams) {
  const { t } = useTranslation()
  const { state } = useAuth()
  const { track } = useAnalytics()
  const partner = getPartnerFromAuthState(state)!
  const { ref } = useInfiniteScroll(loadMore)
  const { handleDonate, DonationFormWrapper } = useDonationForm('regular')
  const theme = useTheme()

  return (
    <Box sx={Styles.Container}>
      {isLoading && !hasMore ? (
        <Box display="flex" flexDirection="row" alignItems="center" gap={1} marginBottom={1}>
          <Skeleton variant="rectangular" width={350} height={24} />
        </Box>
      ) : (
        <Typography variant="body2" sx={Styles.NumberResults}>
          {!replacedByGlobalSearch &&
            totalResults > 0 &&
            t('workplace_giving.search.exactNumberResults', {
              result: t('workplace_giving.search.result', { count: totalResults }),
              numberResults: totalResults,
              in: countryCode ? 'in' : ''
            })}
          {!replacedByGlobalSearch && totalResults > 0 && countryCode === '' && (
            <>
              <WorldFlagAvatar />
              <Styles.StyledCountryBold>{t('workplace_giving.search.globally')}.</Styles.StyledCountryBold>
            </>
          )}
          {!replacedByGlobalSearch && totalResults > 0 && countryCode !== '' && (
            <>
              <FlagAvatar code={toAlpha2(countryCode as Alpha3Code) as Alpha2Code} />
              <Styles.StyledCountryBold>{countries.getName(countryCode!, 'en')}.</Styles.StyledCountryBold>
            </>
          )}
          {!replacedByGlobalSearch && countryCode !== '' && totalResults > 0 && (
            <Styles.StyledSpan>
              {t('workplace_giving.search.cantFindOrganistation')}{' '}
              <Styles.StyledCountryContainer
                onClick={() => {
                  track({
                    event: 'Donation Organisation Search Switch to Global',
                    properties: {
                      origin: 'Search',
                      query: searchTerm,
                      currentCountryCode: countryCode,
                      switchToCountryCode: '',
                      totalResults
                    }
                  })
                  setSearchParams({
                    country: '',
                    query: searchTerm
                  })
                }}
              >
                <WorldFlagAvatar />
                <Styles.StyledCountryBold>{t('workplace_giving.search.globally')}</Styles.StyledCountryBold>
              </Styles.StyledCountryContainer>
            </Styles.StyledSpan>
          )}
          {replacedByGlobalSearch && totalResults > 0 && (
            <>
              {t('workplace_giving.search.noResultsFound')}{' '}
              <FlagAvatar code={toAlpha2(countryCode as Alpha3Code) as Alpha2Code} />
              <Styles.StyledCountryBold>{countries.getName(countryCode!, 'en')}, </Styles.StyledCountryBold>
              <Styles.StyledSpan>
                {t('workplace_giving.search.foundSomeResults')}
                <WorldFlagAvatar />
                <Styles.StyledCountryBold>{t('workplace_giving.search.globally')}</Styles.StyledCountryBold>
              </Styles.StyledSpan>
            </>
          )}
          {!replacedByGlobalSearch && userCountryCode && countryCode === '' && hideLocalSearch && totalResults > 0 && (
            <Styles.StyledSpan>
              {t('workplace_giving.search.switchTo')}
              <Styles.StyledCountryContainer
                onClick={() => {
                  track({
                    event: 'Donation Organisation Search Switch to User Country',
                    properties: {
                      origin: 'Search',
                      query: searchTerm,
                      currentCountryCode: countryCode,
                      switchToCountryCode: userCountryCode,
                      totalResults
                    }
                  })
                  setSearchParams({
                    country: userCountryCode,
                    query: searchTerm
                  })
                }}
              >
                <FlagAvatar code={toAlpha2(userCountryCode as Alpha3Code) as Alpha2Code} />
                <Styles.StyledCountryBold>{countries.getName(userCountryCode, 'en')}</Styles.StyledCountryBold>
              </Styles.StyledCountryContainer>
            </Styles.StyledSpan>
          )}
        </Typography>
      )}
      <Box>
        {isLoading && !hasMore ? (
          <Box display="flex" flexDirection="column" gap={2}>
            <SearchResultCardSkeleton />
            <SearchResultCardSkeleton />
          </Box>
        ) : (
          <Box sx={Styles.ResultsSet}>
            {data?.pages?.map(page =>
              page.map(organisation => (
                <OrganisationCard
                  key={organisation.organisationId}
                  id={organisation.organisationId}
                  iconUrl={organisation.logoUrl ?? undefined}
                  title={organisation.displayName ?? organisation.name}
                  description={organisation.description}
                  countryCode={organisation.countryCode}
                  website={organisation.website}
                  onDonateClicked={() => {
                    track({
                      event: 'Donation Organisation Donation Start',
                      properties: {
                        origin: 'Search',
                        organisationCountryCode: organisation.countryCode
                      }
                    })
                    handleDonate(organisation.organisationId)
                  }}
                  buttonText={t('workplace_giving.common.donate')}
                  moreText={t('workplace_giving.common.more')}
                  lessText={t('workplace_giving.common.less')}
                  registryId={
                    organisation?.organisationIdInRegistry
                      ? t('workplace_giving.common.registryId', {
                          registryId: organisation?.organisationIdInRegistry
                        })
                      : undefined
                  }
                  cardButtonInfo={<TooltipTaxInformation orgCountryCode={organisation.countryCode as Alpha3Code} />}
                />
              ))
            )}
            {hasMore && (
              <div ref={ref}>
                <SearchResultCardSkeleton />
              </div>
            )}
          </Box>
        )}
      </Box>
      {totalResults === 0 && !isLoading ? (
        <>
          <IllustratedMessage
            illustration="no-results"
            title={t('workplace_giving.search.noResultsGlobal', { searchTerm, interpolation: { escapeValue: false } })}
            description={t('workplace_giving.search.noResultDescription', { partnerName: partner.name })}
          />
          <Alert variant="info">{t('workplace_giving.search.noResultsInfo')}</Alert>
        </>
      ) : null}
      <DonationFormWrapper
        params={{
          primaryColor: theme.palette.clientColor
        }}
      />
    </Box>
  )
}
