import { Styles } from '@percent/workplace-giving/types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const TotalDonationContainer: Styles = {
  borderRadius: lemonadeTheme.sizes[4],
  marginBottom: lemonadeTheme.sizes[4],
  boxShadow: '0px 2px 16px 0px #0D0D0D14',
  backgroundColor: lemonadeTheme.colors.white,

  '& > div': {
    boxSizing: 'border-box',
    paddingX: lemonadeTheme.sizes[6],

    '&:nth-of-type(2)': {
      paddingTop: lemonadeTheme.sizes[6]
    }
  }
}
