import { Styles } from '@percent/workplace-giving/types'
import { hexToRgba } from '@percent/workplace-giving/utils/hexToRgba'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Wrapper: (scrolled: boolean, greetingHeight: number): Styles => ({
    boxSizing: 'border-box',
    position: 'fixed',
    zIndex: 5,
    top: scrolled ? `${80 - greetingHeight - 52}px` : '80px',
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '52px',
    paddingBottom: scrolled ? '22px' : '60px',
    background: `radial-gradient(87.11% 94.04% at 83.75% 31.88%, ${hexToRgba(
      customPalette.primary,
      '0.1'
    )} 0%, ${hexToRgba(customPalette.primary, '0')} 100%), #F8F6F2`,
    transition: 'padding 0.4s linear, top 0.4s linear',

    '@media(max-width: 899px)': {
      top: scrolled ? `${80 - greetingHeight - 76}px` : '80px',
      paddingTop: '28px',
      paddingBottom: scrolled ? '22px' : '76px'
    }
  }),
  ContentWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
    paddingX: '64px',
    maxWidth: 'calc(1440px + 128px)',
    marginX: 'auto',

    '@media(max-width: 899px)': {
      paddingX: '24px'
    }
  },
  Greeting: (scrolled: boolean): Styles => ({
    whiteSpace: 'pre-wrap',
    color: customPalette.black,
    transform: scrolled ? `translateY(-100%)` : 'translateY(0)',
    opacity: scrolled ? 0 : 1,
    transition: 'transform 0.4s linear, opacity 0.4s linear'
  }),
  SearchBoxWrapper: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    width: '60%',

    '& > button': {
      marginLeft: '8px'
    },
    '@media (min-width: 900px) and (max-width: 1050px)': {
      width: '100%'
    },

    '@media(max-width: 899px)': {
      flexDirection: 'column-reverse',
      width: '100%'
    }
  },
  SearchInput: {
    width: '100%',
    '@media(max-width: 899px)': {
      marginTop: '8px'
    }
  },
  SearchSelect: (scrolled: boolean): Styles => ({
    width: 200,
    marginLeft: 1,

    '@media(max-width: 899px)': {
      width: '100%',
      marginLeft: 0,
      transform: scrolled ? `translateY(-100%)` : 'translateY(0)',
      opacity: scrolled ? 0 : 1,
      transition: 'transform 0.4s linear, opacity 0.4s linear'
    }
  })
})
