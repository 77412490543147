import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

const FilterResultsCountry = {
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: lemonadeTheme.sizes[1],
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: lemonadeTheme.sizes[6],
  span: {
    fontWeight: lemonadeTheme.fontWeights.medium
  }
}

export const CardContentWrapper = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: lemonadeTheme.sizes[2],
    marginBottom: lemonadeTheme.sizes[2]
  },

  '& p': {
    color: lemonadeTheme.colors.gray800
  }
}

export const getStyles = (customPalette: ColorPaletteType) => ({
  LoadMoreContainer: {
    display: 'flex',
    flexFlow: 'column'
  },
  TitleBox: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'no-wrap',
    gap: lemonadeTheme.sizes[4],

    '@media (min-width: 768px)': {
      flexFlow: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  Title: {
    width: '100%',
    fontSize: lemonadeTheme.sizes[6],
    fontWeight: lemonadeTheme.fontWeights.semiBold,
    lineHeight: lemonadeTheme.sizes[8]
  },
  ListWrapper: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    gap: lemonadeTheme.sizes[4],
    marginY: lemonadeTheme.sizes[8],

    '& > div': {
      width: '100%',

      '@media (min-width: 768px)': {
        width: `calc(50% - ${lemonadeTheme.sizes[2]})`
      }
    }
  },
  CardContentWrapper,
  ContentFooter: {
    '& p': {
      fontSize: lemonadeTheme.fontSizes.bodySmall,
      fontWeight: lemonadeTheme.fontWeights.medium,
      lineHeight: lemonadeTheme.sizes[6]
    }
  },
  FooterOrgInfo: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'top',
    gap: lemonadeTheme.sizes[8]
  },
  Chip: {
    color: customPalette.gray600,

    '&.Mui-disabled': {
      opacity: 1,
      fontWeight: 500,
      color: customPalette.black,
      backgroundColor: customPalette.gray70
    }
  },
  FilterResultsCountry,
  Global: {
    ...FilterResultsCountry,
    color: customPalette.gray600
  },
  GlobalBtn: {
    ...FilterResultsCountry,
    paddingX: lemonadeTheme.sizes[1],
    backgroundColor: customPalette.gray80,
    fontWeight: lemonadeTheme.fontWeights.medium
  }
})
