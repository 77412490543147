import { Box, ButtonBase, Typography } from '@mui/material'
import { InfiniteData } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params'
import { useEffect, useMemo, useState } from 'react'
import { Alpha2Code, Alpha3Code, toAlpha2 } from 'i18n-iso-countries'

import countriesList from '@percent/workplace-giving/i18n/countries'
import { OpportunityCard } from '@percent/domain/volunteering'
import { BadgeVariant, Button, FlagAvatar, IllustratedMessage, SearchInput } from '@percent/lemonade'
import {
  Opportunity,
  OpportunityType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { getStyles } from './OpportunitiesList.styles'
import { OpportunityCardContent } from './OpportunityCardContent'
import { OpportunityCardFooter } from './OpportunityCardFooter'
import { OpportunityCardSkeleton } from './OpportunityCardSkeleton'
import { LocaleKey } from '@percent/workplace-giving/i18n/config'
import { FiltersButton } from '@percent/workplace-giving/common/components/FiltersButton/FiltersButton'
import { FiltersModal } from './FiltersModal/FiltersModal'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

type StringParamDecodedType = string | null | undefined
type ArrayParamDecodedType = (string | null)[] | null | undefined

export type OpportunitiesListParams = Readonly<{
  totalResults: number
  data?: InfiniteData<Opportunity[]>
  isLoadingMore?: boolean
  hasMore?: boolean
  loadMore?: () => void
  query: DecodedValueMap<{
    type: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
    locationType: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
    city: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
    country: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
    relevantSkills: QueryParamConfig<ArrayParamDecodedType, ArrayParamDecodedType>
    query: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
  }>
  setQuery: SetQuery<{
    type: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
    locationType: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
    city: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
    country: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
    relevantSkills: QueryParamConfig<ArrayParamDecodedType, ArrayParamDecodedType>
    query: QueryParamConfig<StringParamDecodedType, StringParamDecodedType>
  }>
  countries: {
    value: string
    label: string
    prefix: JSX.Element
  }[]
}>

export function OpportunitiesList({
  totalResults,
  data,
  isLoadingMore,
  hasMore,
  loadMore,
  query,
  setQuery,
  countries
}: OpportunitiesListParams) {
  const { t } = useTranslation()
  const { track } = useAnalytics()
  const [filtersOpen, setFiltersOpen] = useState(false)
  const { query: nameQuery, ...restFilters } = query
  const activeFiltersAmount = Object.values(restFilters)?.filter(v => v !== undefined)?.length
  const [searchName, setSearchName] = useState(nameQuery || '')
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  useEffect(() => {
    document.body.setAttribute('style', `overflow-y:${filtersOpen ? 'hidden' : 'auto'}`)
  }, [filtersOpen])

  const setCountryFilterToGlobal = () => {
    setQuery({ ...query, country: undefined })
    track({
      event: 'Volunteering Discover Filters',
      properties: {
        filtersValue: restFilters,
        triggeredFrom: 'Switch to global btn'
      }
    })
  }

  const getNoResultsMessage = useMemo(() => {
    if (activeFiltersAmount) {
      return (
        <IllustratedMessage
          illustration="no-results"
          title={t('workplace_giving.volunteering.filters.noResults.title')}
          description={t('workplace_giving.volunteering.filters.noResults.description')}
          cta={{
            label: t('workplace_giving.volunteering.filters.noResults.actionBtn'),
            onPress: () => {
              setQuery({
                relevantSkills: undefined,
                type: undefined,
                locationType: undefined,
                country: undefined
              })
            }
          }}
        />
      )
    }

    if (nameQuery) {
      return (
        <IllustratedMessage
          illustration="no-results"
          title={t('workplace_giving.volunteering.search.noResults.title', { searchedPhrase: nameQuery })}
          description={t('workplace_giving.volunteering.search.noResults.description')}
        />
      )
    }

    return (
      <IllustratedMessage
        illustration="nothing-to-see"
        title={t('workplace_giving.volunteering.noResults.title')}
        description={t('workplace_giving.volunteering.noResults.description')}
      />
    )
  }, [activeFiltersAmount, nameQuery, setQuery, t])

  return (
    <Box>
      <Box sx={Styles.TitleBox}>
        <Typography sx={Styles.Title}>{t('workplace_giving.volunteering.exploreOpportunities')}</Typography>
      </Box>
      {totalResults === 0 && !isLoadingMore && !activeFiltersAmount && !nameQuery?.length ? null : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mt={2}
          data-testid="volunteeringSearchAndFiltersWrapper"
        >
          <Box width="100%">
            <SearchInput
              data-testid="volunteeringSearchField"
              placeholder={t('workplace_giving.volunteering.searchFieldPlaceholder')}
              value={searchName}
              onChange={({ target }) => {
                setSearchName(target.value)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setQuery({ query: searchName.trim() })
                  track({
                    event: 'Volunteering Discover Search',
                    properties: {
                      searchName
                    }
                  })
                }
              }}
              handleClearValue={() => {
                setQuery({ query: undefined })
                setSearchName('')
              }}
            />
          </Box>
          <FiltersButton
            activeFiltersAmount={activeFiltersAmount}
            onClick={() => setFiltersOpen(true)}
            testId="volunteeringFiltersBtn"
          />
        </Box>
      )}
      {query.country && !query.locationType ? (
        <Box mt={2}>
          <Box sx={Styles.FilterResultsCountry}>
            {t('workplace_giving.volunteering.allVirtualAndOfflineOpportunitiesInCountry')}
            <FlagAvatar code={toAlpha2(query.country as Alpha3Code) as Alpha2Code} />
            <span>{`${countriesList.getName(query.country, 'en')}.`}</span>
            <Box sx={Styles.Global}>
              {t('workplace_giving.volunteering.switchTo')}
              <ButtonBase
                sx={Styles.GlobalBtn}
                disableRipple
                onClick={setCountryFilterToGlobal}
                data-testid="switchToGlobalBtn"
              >
                <WorldFlagAvatar />
                {` ${t('workplace_giving.search.world')}`}
              </ButtonBase>
            </Box>
          </Box>
        </Box>
      ) : null}
      {totalResults === 0 && !isLoadingMore ? getNoResultsMessage : null}
      <Box sx={Styles.ListWrapper}>
        {data?.pages?.map(opportunities =>
          opportunities?.map(opportunity => {
            const skillsList = opportunity.skills
              ? opportunity.skills?.map(skill => ({
                  tag: t(`workplace_giving.volunteering.skills.${skill.id}` as LocaleKey, {
                    defaultValue: skill.name
                  }),
                  badgeVariant: 'default' as BadgeVariant
                }))
              : []

            return (
              <OpportunityCard
                to={`/volunteer/opportunities/${opportunity.id}`}
                key={opportunity.id}
                tags={[
                  {
                    tag:
                      opportunity.type === OpportunityType.PROJECT
                        ? t('workplace_giving.volunteering.project')
                        : t('workplace_giving.volunteering.event'),
                    badgeVariant: 'informative'
                  },
                  ...skillsList
                ]}
                image={opportunity.image}
                title={opportunity.name}
                content={<OpportunityCardContent {...opportunity} />}
                footer={opportunity.organisation ? <OpportunityCardFooter {...opportunity.organisation} /> : null}
              />
            )
          })
        )}
      </Box>
      {isLoadingMore && (
        <Box sx={Styles.ListWrapper} data-testid="opportunitiesLoader">
          <OpportunityCardSkeleton />
        </Box>
      )}
      {hasMore && loadMore && (
        <Box sx={Styles.LoadMoreContainer}>
          <Button
            variant="secondary"
            loading={isLoadingMore}
            disabled={!hasMore || isLoadingMore}
            onPress={() => loadMore()}
          >
            {t('workplace_giving.home.loadMore')}
          </Button>
        </Box>
      )}
      <FiltersModal
        query={query}
        setQuery={setQuery}
        open={filtersOpen}
        handleClose={() => setFiltersOpen(false)}
        clearAllActive={!!activeFiltersAmount}
        countries={countries}
      />
    </Box>
  )
}
