import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Calendar: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'nowrap',
    width: lemonadeTheme.sizes[10],
    minWidth: lemonadeTheme.sizes[10],
    height: lemonadeTheme.sizes[10],
    borderRadius: lemonadeTheme.borderRadii.medium,
    overflow: 'hidden',

    '& > span': {
      width: '100%',
      textAlign: 'center',
      fontWeight: lemonadeTheme.fontWeights.semiBold,
      textTransform: 'uppercase',

      '&:first-of-type': {
        height: lemonadeTheme.sizes[4],
        fontSize: '10px',
        lineHeight: lemonadeTheme.sizes[4],
        color: customPalette.gray600,
        backgroundColor: customPalette.gray90
      },

      '&:last-of-type': {
        height: lemonadeTheme.sizes[6],
        fontSize: '13px',
        lineHeight: lemonadeTheme.sizes[6],
        backgroundColor: customPalette.gray70
      }
    }
  },
  DetailsWrapper: {
    '& > p:not(:first-of-type), & > span, & > div': {
      marginBottom: lemonadeTheme.sizes[2]
    },

    '& > p:nth-of-type(3)': {
      color: customPalette.gray600
    }
  }
})
