import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'

import { OpportunityFormFields } from '../OpportunityForm'
import { useOpportunityValidationSchema } from '../useOpportunityFormValidation'
import { getInitialValues, UseCreateOpportunityFormResult } from '../useCreateOpportunityForm'

import {
  Opportunity,
  OpportunityLocationType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { editOpportunityDetails } from '@percent/workplace-giving/api/opportunity/edit/edit-opportunity-details'
import { editOpportunityLocation } from '@percent/workplace-giving/api/opportunity/edit/edit-opportunity-location'
import { addProtocolToWebsiteUrl } from '@percent/workplace-giving/utils/url/url'
import { useAuth, useLogger } from '@percent/workplace-giving/common/hooks'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { FILE_FORMATS, FILE_SIZE_2MB_HUMAN, FILE_SIZE_LIMIT_2MB } from '@percent/workplace-giving/constants/files'

export type UseEditOpportunityFormProps = {
  onSuccess: (opportunityId: string) => void
  onError: (err: unknown) => void
  initialOpportunityData?: Opportunity
}

export type UseEditOpportunityFormResult = Omit<UseCreateOpportunityFormResult, 'sessionsFailedToCreateError'> & {
  setInitialFormValues: Dispatch<SetStateAction<OpportunityFormFields>>
}

export const useEditOpportunityForm = ({
  onSuccess,
  onError,
  initialOpportunityData
}: UseEditOpportunityFormProps): UseEditOpportunityFormResult => {
  const { track } = useAnalytics()
  const { logError } = useLogger()
  const { state } = useAuth()
  const defaultCountryCode = getCountryCodeFromAuthState(state)!
  const { t } = useTranslation()

  const [initialFormValues, setInitialFormValues] = useState<OpportunityFormFields>(
    getInitialValues(defaultCountryCode)
  )

  const validationSchema = useOpportunityValidationSchema(initialOpportunityData)

  const coverImageChanged = useRef(false)
  const [coverImage, setCoverImage] = useState<File | undefined>(undefined)
  const [coverImageError, setCoverImageError] = useState<string | undefined>(undefined)

  const handleCoverImageChange = (file: File) => {
    setCoverImageError(undefined)

    if (!/(png|jpe?g)/i.exec(file.type))
      setCoverImageError(t('workplace_giving.validation.invalidFileType', { fileFormats: FILE_FORMATS }))

    if (file.size > FILE_SIZE_LIMIT_2MB)
      setCoverImageError(t('workplace_giving.validation.fileTooLarge', { fileSize: FILE_SIZE_2MB_HUMAN }))

    setCoverImage(file)
    coverImageChanged.current = true
  }

  const { mutateAsync: updateOpportunityLocationMutateAsync } = useMutation({
    mutationFn: editOpportunityLocation,
    onError: err => {
      logError(err)

      return onError(err)
    }
  })

  const { mutateAsync: updateOpportunityDetailsMutateAsync } = useMutation({
    mutationFn: editOpportunityDetails,
    onSuccess: res => {
      if (okResponse(res)) {
        return onSuccess(res.data.id)
      }

      return onError(res)
    },
    onError: err => {
      logError(err)

      return onError(err)
    }
  })

  const {
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    validateForm,
    validateField
  } = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async data => {
      const {
        type,
        locationType,
        locationUrl,
        startDate,
        endDate,
        name,
        description,
        activities,
        skills,
        participantSpots,
        addressLineOne,
        addressLineTwo,
        city,
        country,
        zipCode,
        lat,
        long
      } = data

      try {
        if (initialOpportunityData?.location.type !== locationType) {
          await updateOpportunityLocationMutateAsync([
            {
              id: initialOpportunityData!.id,
              location:
                locationType === OpportunityLocationType.VIRTUAL
                  ? {
                      type: OpportunityLocationType.VIRTUAL,
                      link: locationUrl.length ? addProtocolToWebsiteUrl(locationUrl) : null
                    }
                  : {
                      type: OpportunityLocationType.OFFLINE,
                      addressLineOne,
                      addressLineTwo,
                      city,
                      country,
                      zipCode,
                      long,
                      lat
                    }
            }
          ])
        }
        await updateOpportunityDetailsMutateAsync([
          {
            id: initialOpportunityData!.id,
            name,
            description,
            activities,
            skills,
            participantSpots,
            startDate: dayjs(startDate).isBefore(dayjs()) ? undefined : (startDate as unknown as Date),
            endDate: endDate as unknown as Date,
            location:
              locationType === OpportunityLocationType.VIRTUAL
                ? {
                    type: OpportunityLocationType.VIRTUAL,
                    link: locationUrl.length ? addProtocolToWebsiteUrl(locationUrl) : null
                  }
                : {
                    type: OpportunityLocationType.OFFLINE,
                    addressLineOne,
                    addressLineTwo,
                    city,
                    country,
                    zipCode,
                    long,
                    lat
                  },
            image: coverImage!
          }
        ])
        track({
          event: 'Volunteering Opportunity Edit Completed',
          properties: {
            opportunityType: type,
            opportunityLocationType: locationType
          }
        })
      } catch (e) {
        logError(e)
      }
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  const isFormDirty = (
    newValues: OpportunityFormFields,
    initialValues: OpportunityFormFields,
    coverImgChanged: boolean
  ) => {
    if (coverImgChanged) return true
    const changedFields = Object.entries(newValues).reduce<any>((acc, [key, value]) => {
      const hasChanged = initialValues[key as keyof OpportunityFormFields] !== value

      if (hasChanged && key !== 'childEvents') {
        acc[key] = value
      }

      return acc
    }, {})

    return !!Object.keys(changedFields).length
  }

  const formValid = initialFormValues?.isSeries
    ? isValid && !isSubmitting && !!values.childEvents.length
    : isValid && isFormDirty(values, initialFormValues, coverImageChanged.current) && !isSubmitting

  useEffect(() => {
    validateForm()
  }, [validateForm, values.locationType, values.organisationId, values.childEvents])

  return {
    coverImage,
    coverImageError,
    handleCoverImageChange,
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty: isFormDirty(values, initialFormValues, coverImageChanged.current),
    formValid,
    validateField,
    setInitialFormValues
  }
}
