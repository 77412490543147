import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CountUp from 'react-countup'
import { useEffect, useState } from 'react'

import { CurrencyCode, Money, sumMoney } from '@percent/utility'
import { getStyles } from './TotalDonated.styles'
import { config, environment } from '@percent/workplace-giving/config/config'
import { useAuth, useLogger } from '@percent/workplace-giving/common/hooks'
import { getPartnerFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { TotalImpactDetails } from '@percent/domain/giving'
import { PaymentProvider } from '@percent/workplace-giving/app/Fundraiser/FundraiserPage/FundraiserPage.types'
import { usePermission } from '@percent/workplace-giving/common/hooks/usePermission/usePermission'
import { scope } from '@percent/workplace-giving/common/hoc/withPermissions'
import { useMoneyFormat } from '@percent/workplace-giving/common/hooks/useMoneyFormat/useMoneyFormat'
import { DonationSummaryResponse } from '@percent/workplace-giving/api/account/donationSummary/donationSummary'
import { TotalDonatedProps } from './TotalDonated.types'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

const counterTimer = config?.counterTimeoutInSec ? parseInt(config?.counterTimeoutInSec, 10) : 1
const allowedMessageOrigin = config.allowedMessageOrigins[environment as string]

export function TotalDonated({ totals, refetchBudgetData, refetchDonationWallet }: Readonly<TotalDonatedProps>) {
  const { t } = useTranslation()
  const { logError } = useLogger()
  const { track } = useAnalytics()
  const { state } = useAuth()
  const partner = getPartnerFromAuthState(state)
  const has = usePermission()
  const { moneyFormat } = useMoneyFormat()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const [oldDonatedAmount, setOldDonatedAmount] = useState(totals)
  const [newDonatedAmount, setNewDonatedAmount] = useState(totals)
  const [newlyDonatedValue, setNewlyDonatedValue] = useState<DonationSummaryResponse | undefined>(undefined)
  const [isAnimating, setIsAnimating] = useState(false)
  const [hideDonatedTotalSummary, setHideDonatedTotalSummary] = useState(totals.totalDonated.amount === 0)

  const { currency } = totals.totalDonated

  const hasWallet = has(scope('donation_wallet'))
  const hasMatching = has(scope('donation_matching'))

  useEffect(() => {
    if (totals.totalDonated.amount > 0 && hideDonatedTotalSummary) setHideDonatedTotalSummary(false)
  }, [hideDonatedTotalSummary, totals.totalDonated.amount])

  useEffect(() => {
    if (isAnimating) return

    if (totals.totalDonated.amount > newDonatedAmount.totalDonated.amount) {
      setNewDonatedAmount(totals)
      setOldDonatedAmount(totals)
    }
  }, [totals, newDonatedAmount, isAnimating])

  useEffect(() => {
    const listener = (event: MessageEvent<any>) => {
      if (!allowedMessageOrigin?.some(origin => ['*', event.origin].includes(origin))) return
      try {
        const eventData = event.data as {
          message: string
          data: {
            amount: number
            currency: string
            paymentProvider: PaymentProvider
            matchedAmount?: Money
          }
        }

        if (eventData.message === 'donationCompleted') {
          const newDonated: Money = {
            amount: eventData.data.amount,
            currency: eventData.data.currency as CurrencyCode
          }
          const newMatched = eventData.data.matchedAmount ?? {
            amount: 0,
            currency: eventData.data.currency as CurrencyCode
          }
          const newTotal = sumMoney(newDonated, newMatched)
          setNewlyDonatedValue({
            totalDonated: newTotal,
            donated: newDonated,
            matched: newMatched
          })

          track({
            event: 'Donation Organisation Donation Completed',
            properties: {
              origin: 'Home',
              currencyCode: eventData.data.currency,
              paymentProvider: eventData.data.paymentProvider,
              amount: eventData.data.amount
            }
          })
        }

        if (eventData.message === 'donationWidgetClosed') {
          setIsAnimating(true)
        }
      } catch (e) {
        logError(e)
      }
    }

    window.addEventListener('message', listener)

    return () => {
      window.removeEventListener('message', listener)
    }
  }, [logError])

  useEffect(() => {
    if (newlyDonatedValue && isAnimating) {
      if (oldDonatedAmount.totalDonated.amount === 0) {
        setHideDonatedTotalSummary(false)
      }
      const updated = {
        totalDonated: sumMoney(oldDonatedAmount.totalDonated, newlyDonatedValue.totalDonated),
        donated: sumMoney(oldDonatedAmount.donated, newlyDonatedValue.donated),
        matched: sumMoney(oldDonatedAmount.matched, newlyDonatedValue.matched)
      }
      setNewDonatedAmount(updated)
      setNewlyDonatedValue(undefined)
      setTimeout(() => {
        setOldDonatedAmount(updated)
        setIsAnimating(false)
        refetchBudgetData()
        refetchDonationWallet()
      }, counterTimer * 1000)
    }
  }, [isAnimating, newDonatedAmount, newlyDonatedValue, oldDonatedAmount, refetchBudgetData, refetchDonationWallet])

  return (
    <Box sx={Styles.Wrapper} data-testid="totalDonatedCard">
      <TotalImpactDetails
        title={t('workplace_giving.totalDonated.title')}
        value={
          <CountUp
            duration={counterTimer}
            start={oldDonatedAmount.totalDonated.amount}
            end={newDonatedAmount.totalDonated.amount}
            formattingFn={amount => moneyFormat({ amount, currency })}
          />
        }
        showNoImpactInfo={oldDonatedAmount.totalDonated.amount === 0 && hideDonatedTotalSummary}
        noImpactTitle={t('workplace_giving.totalDonated.noDonations.title')}
        noImpactDescription={hasWallet ? undefined : t('workplace_giving.totalDonated.noDonations.motivation')}
      >
        {hasMatching && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={Styles.TextLine}>{t('workplace_giving.totalDonated.yourContribution')}</Typography>
              <Typography sx={Styles.TextLine} data-testid="total-donated-your-contribution">
                {moneyFormat(newDonatedAmount.donated, { hideDecimalsIfZero: true })}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={Styles.TextLine}>
                {t('workplace_giving.totalDonated.matchedBy', { partnerName: partner?.name })}
              </Typography>
              <Typography sx={Styles.TextLine} data-testid="total-donated-matched">
                {moneyFormat(newDonatedAmount.matched, { hideDecimalsIfZero: true })}
              </Typography>
            </Stack>
          </>
        )}
      </TotalImpactDetails>
    </Box>
  )
}
