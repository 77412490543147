import { PartnerConfig } from '../config/config.types'
import { getOrganisations } from '../organisations/getOrganisation/getOrganisation'
import { getFundraisers } from '../fundraiser/getFundraiser/getFundraiser'
import { getOpportunities } from '../opportunity/getOpportunityDetails'
import { Fundraiser } from '../fundraiser/getFundraiser/getFundraiser.types'

import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import {
  Featured,
  FeaturedFundraiserResponse,
  FeaturedOpportunityResponse,
  FeaturedOrganisationResponse
} from './featured.types'

type FeaturedResponse = FeaturedOrganisationResponse | FeaturedFundraiserResponse | FeaturedOpportunityResponse

const isFeaturedOrganisationResponse = (f: FeaturedResponse): f is FeaturedOrganisationResponse =>
  f.kind === 'featured_organisation'
const isFeaturedFundraiserResponse = (f: FeaturedResponse): f is FeaturedFundraiserResponse =>
  f.kind === 'featured_fundraiser'
const isFeaturedOpportunityResponse = (f: FeaturedResponse): f is FeaturedOpportunityResponse =>
  f.kind === 'featured_opportunity'

const assertUnreachable = (kind: never, message = 'This case is not allowed'): never => {
  throw new Error(`${message}: ${kind}`)
}

type FeaturedProps = { country?: string | null; mode?: 'donation' | 'volunteering' }

export const getFeatured: QueryFn<[string, FeaturedProps], Featured[]> = async (client, [_, { country, mode }]) => {
  const { data } = await client.get<{ data: PartnerConfig<'featured', FeaturedResponse[]> }>(
    `/partner/giving/config/featured`,
    { withCredentials: true, params: { country } }
  )

  const featured = data.data.value.filter(f => {
    if (mode === 'volunteering') {
      return f.kind === 'featured_opportunity'
    }

    if (mode === 'donation') {
      return ['featured_organisation', 'featured_fundraiser'].includes(f.kind)
    }

    return true
  })
  const fundraiserIds = featured.filter(isFeaturedFundraiserResponse).map(f => f.fundraiserId)
  const fundraisers = await getFundraisers(client, ['', fundraiserIds])
  const fundraiserMap = Object.fromEntries(fundraisers.map(f => [f.id, f]))

  const opportunityIds = featured.filter(isFeaturedOpportunityResponse).map(f => f.opportunityId)
  const opportunities = await getOpportunities(client, ['', opportunityIds])
  const opportunitiesMap = Object.fromEntries(opportunities.map(f => [f.id, f]))

  const checkIfOpportunityHasEnded = (endDate?: string) => {
    if (!endDate) return false

    return new Date(endDate) < new Date()
  }

  const isFundraiserLive = (fundraiser?: Fundraiser) => fundraiser?.status === 'published'

  const liveFeatured = featured.filter(f => {
    if (isFeaturedOpportunityResponse(f) && checkIfOpportunityHasEnded(opportunitiesMap[f.opportunityId]?.endDate)) {
      return false
    }

    if (isFeaturedFundraiserResponse(f) && !isFundraiserLive(fundraiserMap[f.fundraiserId])) {
      return false
    }

    return true
  })

  const organisationIds = [
    ...new Set([
      ...featured.filter(isFeaturedOrganisationResponse).map(f => f.organisationId),
      ...fundraisers.map(f => f.organisationId)
    ])
  ]
  const organisations = await getOrganisations(client, ['', organisationIds])
  const organisationMap = Object.fromEntries(organisations.map(o => [o.id, o]))

  const mapper = (feature: FeaturedResponse): Featured => {
    const { kind } = feature

    switch (kind) {
      case 'featured_organisation':
        return {
          ...feature,
          organisation: organisationMap[feature.organisationId]
        }
      case 'featured_fundraiser':
        return {
          ...feature,
          fundraiser: fundraiserMap[feature.fundraiserId]!,
          organisation: organisationMap[fundraiserMap[feature.fundraiserId].organisationId]
        }
      case 'featured_opportunity':
        return {
          ...feature,
          opportunity: opportunitiesMap[feature.opportunityId]
        }
      default:
        throw assertUnreachable(kind)
    }
  }

  return liveFeatured.map(mapper)
}
