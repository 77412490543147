import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ModalProps } from '../../../../../../libs/shared/ui-lemonade/src/components/modal'
import { useColorTheme } from '../../hooks/useColorTheme/useColorTheme'

import { Button, Modal } from '@percent/lemonade'
import { ErrorIcon } from '@percent/workplace-giving/common/components/ErrorIcon/ErrorIcon'

type UnexpectedErrorModalProps = {
  message: string
  description?: string
  onClose: VoidFunction
} & Pick<ModalProps, 'open'>

export function UnexpectedErrorModal({ open, onClose, message, description }: UnexpectedErrorModalProps) {
  const { t } = useTranslation()
  const { theme } = useColorTheme()

  return (
    <Modal onClose={onClose} open={open}>
      <Box padding={6} display="flex" flexDirection="column" alignItems="center">
        <ErrorIcon />
        <Typography my={2} textAlign="center" fontSize={24} fontWeight={600}>
          {message}
        </Typography>
        <Typography mb={4} textAlign="center" fontSize={14} color={theme.neutral300}>
          {description || t('workplace_giving.errors.tryAgainLater')}
        </Typography>
        <Button variant="primary" onPress={onClose}>
          {t('workplace_giving.common.done')}
        </Button>
      </Box>
    </Modal>
  )
}
