import { useTranslation } from 'react-i18next'

import { Money, formatMoney } from '@percent/utility'
import { BudgetDetails } from '@percent/domain/giving'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'

interface MatchingBudgetProps {
  usedAmount: Money
  remainingAmount: Money
  pendingAmount?: Money
  resetDate: Date
  totalAmount: Money
}

export function MatchingBudget({
  remainingAmount,
  usedAmount,
  pendingAmount,
  totalAmount,
  resetDate
}: MatchingBudgetProps) {
  const { t } = useTranslation()

  const { formatDateWODay } = useDateTimeFmt()
  const date = new Date(resetDate)
  const formattedDate = formatDateWODay(date)
  const formattedUsedAmount = formatMoney(usedAmount, { hideDecimalsIfZero: true })
  const formattedAnnualBudgetTarget = formatMoney(totalAmount, { hideDecimalsIfZero: true })
  const formattedRemainingAmount = formatMoney(remainingAmount, { hideDecimalsIfZero: true })
  const formattedPendingAmount = pendingAmount ? formatMoney(pendingAmount, { hideDecimalsIfZero: true }) : null

  const usedPercentage = Math.min((usedAmount.amount / totalAmount.amount) * 100, 100)
  const pendingPercentage = Math.min(((pendingAmount?.amount || 0) / totalAmount.amount) * 100, 100)

  return (
    <BudgetDetails
      noBudgetLeft={remainingAmount.amount <= 0}
      noBudgetTitle={t('workplace_giving.matchingBudget.bar.matching.info')}
      noBudgetDescription={t('workplace_giving.matchingBudget.bar.matching.date', { resetDate: formattedDate })}
      totalBudget={{
        name: t('workplace_giving.matchingBudget.bar.budget'),
        value: formattedAnnualBudgetTarget
      }}
      usedBudget={{
        name: t('workplace_giving.matchingBudget.bar.usedAmount'),
        value: formattedUsedAmount
      }}
      pendingBudget={{
        name: t('workplace_giving.matchingBudget.bar.pendingAmount'),
        value: formattedPendingAmount || '0'
      }}
      remainingBudget={{
        name: t('workplace_giving.matchingBudget.bar.remainingAmount'),
        value: formattedRemainingAmount
      }}
      resetInfo={t('workplace_giving.matchingBudget.bar.resetDate', { date: formattedDate })}
      isBudgetTouched={!!usedPercentage || !!pendingPercentage}
      usedPercentage={usedPercentage}
      pendingPercentage={pendingPercentage}
    />
  )
}
