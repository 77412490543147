import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Table: {
    border: `1px solid ${customPalette.gray80}`,
    borderRadius: '4px'
  },
  TableRow: {
    th: {
      borderBottom: `1px solid ${customPalette.gray80}`
    }
  },
  TableRowHeader: {
    th: {
      borderBottom: `1px solid ${customPalette.gray80}`
    }
  },
  TableFooter: {
    borderTop: `1px solid ${customPalette.gray80}`
  },
  Chip: {
    color: customPalette.gray600,

    '&.Mui-disabled': {
      opacity: 1,
      fontWeight: 500,
      color: customPalette.black,
      backgroundColor: customPalette.gray70
    }
  },
  LocationLabelWrapper: {
    maxHeight: '24px !important',
    width: 'fit-content',
    padding: '4px 10px',
    backgroundColor: customPalette.gray90,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',

    svg: {
      height: '20px',
      width: '20px'
    }
  }
})
