import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  AddToCalendarContainer: {
    '& > p': {
      fontSize: lemonadeTheme.fontSizes.bodySmall,
      lineHeight: lemonadeTheme.sizes[6],
      color: customPalette.gray600
    }
  }
})
