import styled from 'styled-components'

import { theme as lemonadeTheme } from '@percent/lemonade'
import { customBreakpoints } from '@percent/workplace-giving/utils/customBreakpoints'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  BannerFallback: styled.div`
    background-color: ${customPalette.gray20};
    border-radius: 3px;
    @media (min-width: ${customBreakpoints.xs}) {
      height: 288px;
    }
  `,
  Banner: styled.img`
    width: 100%;
    border-radius: ${lemonadeTheme.borderRadii.large};
    height: auto;
    object-fit: cover;

    @media (min-width: ${customBreakpoints.xs}) {
      height: 288px;
    }
  `
})
