import { useState } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import { OpportunityFormCancellationModal } from '@percent/workplace-giving/app/Volunteer/OpportunityForm/OpportunityFormCancellationModal'
import { OpportunityFormSuccessModal } from '@percent/workplace-giving/app/Volunteer/OpportunityForm/OpportunityFormSuccessModal'
import { FullPageFormLayout } from '@percent/lemonade'
import { WizardHeader } from '@percent/workplace-giving/common/components/WizardHeader/WizardHeader'
import { getStyles } from './CreateOpportunity.styles'
import { OpportunityForm } from './OpportunityForm'
import { ChildSessionEvent } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { UnexpectedErrorModal } from '@percent/workplace-giving/common/components/UnexpectedErrorModal/UnexpectedErrorModal'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useCreateOpportunityForm } from './useCreateOpportunityForm'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function CreateOpportunity() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const [openCancellationModal, setOpenCancellationModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [createdOpportunityId, setCreatedOpportunityId] = useState<string | undefined>(undefined)
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const handleCloseCancellationModal = () => setOpenCancellationModal(prevState => !prevState)

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateField,
    handleSubmit,
    dirty,
    isSubmitting,
    formValid,
    coverImage,
    coverImageError,
    handleCoverImageChange,
    sessionsFailedToCreateError
  } = useCreateOpportunityForm({
    onSuccess: opportunityId => {
      setCreatedOpportunityId(opportunityId)

      return setOpenSuccessModal(true)
    },
    onError: () => setOpenErrorModal(true)
  })

  const handleCreateSessionEvent = (newEvent: ChildSessionEvent) => {
    setFieldValue('childEvents', [...values.childEvents, newEvent])
  }

  const handleEditSessionEvent = (updatedEvent: ChildSessionEvent) => {
    const updatedEvents = [...values.childEvents].map(item => {
      if (item.id === updatedEvent.id) {
        return updatedEvent
      }

      return item
    })
    setFieldValue('childEvents', updatedEvents)
  }

  const handleRemoveSessionEvent = (sessionEventId?: string) => {
    setFieldValue(
      'childEvents',
      values.childEvents.filter(item => item.id !== sessionEventId)
    )
  }

  return (
    <FullPageFormLayout
      actionsHeader={
        <WizardHeader
          title={t('workplace_giving.volunteering.createOpportunity')}
          onCancel={() => {
            if (dirty || !!coverImage) {
              setOpenCancellationModal(true)
            } else {
              navigate(-1)
            }
          }}
          onPublish={handleSubmit}
          isPublishDisabled={!formValid}
          isLoading={isSubmitting}
        />
      }
    >
      <Typography sx={Styles.Title}>{t('workplace_giving.volunteering.buildOpportunityPage')}</Typography>
      <OpportunityForm
        handleCoverImageChange={handleCoverImageChange}
        coverImageError={coverImageError}
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        validateField={validateField}
        handleCreateSessionEvent={handleCreateSessionEvent}
        handleEditSessionEvent={handleEditSessionEvent}
        handleRemoveSessionEvent={handleRemoveSessionEvent}
      />
      <OpportunityFormCancellationModal
        handleCloseCancellationModal={handleCloseCancellationModal}
        openCancellationModal={openCancellationModal}
      />
      <OpportunityFormSuccessModal
        open={openSuccessModal}
        onClose={() => {
          setOpenSuccessModal(false)
          navigate(AppRoute.OPPORTUNITY_DETAILS.replace(':opportunityId', createdOpportunityId!))
        }}
        sessionsFailedToCreateError={sessionsFailedToCreateError}
        opportunityId={createdOpportunityId!}
      />
      <UnexpectedErrorModal
        message={t('workplace_giving.errors.api.modal.opportunityWizard')}
        description={t('workplace_giving.errors.api.modal.submitOpportunity')}
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
      />
    </FullPageFormLayout>
  )
}
