import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import { SessionEventForm, SessionEventFormFields } from '../SessionEventForm'

import {
  OpportunityLocationType,
  ChildSessionEvent
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { useAuth, useLogger } from '@percent/workplace-giving/common/hooks'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { config } from '@percent/workplace-giving/config/config'
import { addProtocolToWebsiteUrl } from '@percent/workplace-giving/utils/url/url'
import {
  checkIfEndDateIsAfterStart,
  useSharedValidationRules
} from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'

export type SessionEventModalProps = {
  sessionEvent?: ChildSessionEvent
  isOpen: boolean
  onClose: VoidFunction
  onSubmit: (newEvent: ChildSessionEvent) => void
  isEditFlow: boolean
}

export function EditSessionEvent({
  sessionEvent,
  isOpen,
  onClose,
  onSubmit,
  isEditFlow
}: Readonly<SessionEventModalProps>) {
  const { t } = useTranslation()
  const { state } = useAuth()
  const defaultCountry = getCountryCodeFromAuthState(state)!
  const { logError } = useLogger()
  const { validateString, validateDate, validateUrl, validateNumber } = useSharedValidationRules()

  const [initialValues, setInitialValues] = useState<SessionEventFormFields>({
    name: '',
    startDate: undefined,
    endDate: undefined,
    locationType: '',
    locationUrl: '',
    participantSpots: undefined,
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    country: defaultCountry,
    zipCode: '',
    long: 0,
    lat: 0
  })

  useEffect(() => {
    setInitialValues({
      name: sessionEvent?.name || '',
      startDate: sessionEvent?.startDate,
      endDate: sessionEvent?.endDate,
      locationType: sessionEvent?.location.type || '',
      locationUrl:
        sessionEvent?.location.type === OpportunityLocationType.VIRTUAL ? sessionEvent?.location?.link || '' : '',
      participantSpots: sessionEvent?.participantSpots,
      addressLineOne:
        sessionEvent?.location.type === OpportunityLocationType.OFFLINE
          ? sessionEvent?.location.addressLineOne || ''
          : '',
      addressLineTwo:
        sessionEvent?.location.type === OpportunityLocationType.OFFLINE
          ? sessionEvent?.location.addressLineTwo || ''
          : '',
      city: sessionEvent?.location.type === OpportunityLocationType.OFFLINE ? sessionEvent?.location.city || '' : '',
      country:
        sessionEvent?.location.type === OpportunityLocationType.OFFLINE
          ? sessionEvent?.location.country || defaultCountry
          : '',
      zipCode:
        sessionEvent?.location.type === OpportunityLocationType.OFFLINE ? sessionEvent?.location.zipCode || '' : '',
      long: sessionEvent?.location.type === OpportunityLocationType.OFFLINE ? sessionEvent?.location.long : 0,
      lat: sessionEvent?.location.type === OpportunityLocationType.OFFLINE ? sessionEvent?.location.lat : 0
    })
  }, [defaultCountry, sessionEvent])

  const handleClose = () => {
    resetForm()
    onClose()
  }

  const {
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    validateField,
    resetForm,
    validateForm,
    dirty
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () => {
      const addressFieldsOptional = values.locationType !== OpportunityLocationType.OFFLINE

      return Yup.object().shape({
        name: validateString(),
        startDate: validateDate({ shouldBeInTheFuture: true }),
        endDate: Yup.date()
          .nullable()
          .test('endDateAfterStartDate', t('workplace_giving.validation.endDateAfterStartDate'), value => {
            if (!values.startDate || !value) {
              return true
            }

            return checkIfEndDateIsAfterStart(values.startDate, value)
          })
          .required(t('workplace_giving.validation.requiredField')),
        locationType: validateString(),
        locationUrl: validateUrl(true),
        addressLineOne: validateString({ optional: addressFieldsOptional }),
        addressLineTwo: validateString({ optional: true }),
        city: validateString({ optional: addressFieldsOptional }),
        zipCode: validateString({ optional: addressFieldsOptional }),
        country: validateString({ optional: addressFieldsOptional }),
        participantSpots: validateNumber({
          max: config.maxParticipantSpots,
          optional: true
        })
      })
    },
    onSubmit: async data => {
      const {
        name,
        locationType,
        locationUrl,
        startDate,
        endDate,
        participantSpots,
        addressLineOne,
        addressLineTwo,
        city,
        country,
        zipCode,
        long,
        lat
      } = data

      try {
        onSubmit({
          id: sessionEvent?.id as string,
          name,
          startDate: startDate as unknown as Date,
          endDate: endDate as unknown as Date,
          participantSpots,
          location:
            locationType === OpportunityLocationType.VIRTUAL
              ? {
                  type: OpportunityLocationType.VIRTUAL,
                  link: locationUrl.length ? addProtocolToWebsiteUrl(locationUrl) : null
                }
              : {
                  type: OpportunityLocationType.OFFLINE,
                  addressLineOne,
                  addressLineTwo,
                  city,
                  country,
                  zipCode,
                  long,
                  lat
                }
        })
        handleClose()
      } catch (e) {
        logError(e)
      }
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  useEffect(() => {
    validateForm()
  }, [validateForm, values.locationType])

  const isFormValid = isValid && dirty

  return (
    <SessionEventForm
      isEditMode
      isEditFlow={isEditFlow}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      isLoading={isSubmitting}
      isValid={isFormValid}
      values={values}
      touched={touched}
      errors={errors}
      handleChange={handleChange}
      handleBlur={handleBlur}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      validateField={validateField}
    />
  )
}
