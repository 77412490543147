import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { OpportunityFormFields } from './OpportunityForm'
import {
  ChildSessionEvent,
  Opportunity,
  OpportunityLocationType,
  OpportunityType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { config } from '@percent/workplace-giving/config/config'
import {
  checkIfEndDateIsAfterStart,
  useSharedValidationRules
} from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'
import { isValidWebsiteURL } from '@percent/utility'

type YupSchema<T> = {
  [K in keyof T]: T[K] extends string
    ? Yup.StringSchema
    : T[K] extends number
    ? Yup.NumberSchema
    : T[K] extends Date
    ? Yup.DateSchema
    : T[K] extends boolean
    ? Yup.BooleanSchema
    : T[K] extends string[]
    ? Yup.ArraySchema<Yup.StringSchema>
    : T[K] extends object
    ? Yup.ObjectSchema<any>
    : Yup.SchemaOf<any>
}

type OpportunityFormSchema = YupSchema<OpportunityFormFields>

export const useOpportunityValidationSchema = (initialData?: Opportunity) => {
  const { t } = useTranslation()
  const { validateString, validateDate, validateNumber } = useSharedValidationRules()

  const validationSchema = Yup.object<OpportunityFormSchema>().shape({
    type: Yup.string().oneOf(Object.values(OpportunityType)),
    isSeries: Yup.boolean().default(false),
    name: validateString(),
    description: validateString({ maxLength: 5000 }),
    startDate: Yup.date().when(['type', 'isSeries'], {
      is: (type: string, isSeries: boolean) => type === OpportunityType.PROJECT || isSeries,
      then: () => validateDate({ optional: true, shouldBeInTheFuture: true }),
      otherwise: () => validateDate({ shouldBeInTheFuture: true })
    }),
    endDate: Yup.date()
      .nullable()
      .test(
        'endDateAfterStartDate',
        t('workplace_giving.validation.endDateAfterStartDate'),
        (value: Date | null | undefined, context: Yup.TestContext) => {
          const { startDate } = context.parent as { startDate?: Date }

          if (!startDate || !value) {
            return true
          }

          return checkIfEndDateIsAfterStart(startDate, value)
        }
      )
      .when(['type', 'isSeries'], {
        is: (type: string, isSeries: boolean) => type === OpportunityType.PROJECT || isSeries,
        then: schema => schema,
        otherwise: schema => schema.required(t('workplace_giving.validation.requiredField'))
      }),
    locationType: Yup.string().when('isSeries', {
      is: false,
      then: () => validateString()
    }),
    locationUrl: Yup.string().when(['isSeries', 'locationType'], {
      is: (isSeries: boolean, locationType: string) => !isSeries && locationType === OpportunityLocationType.VIRTUAL,
      then: () =>
        isValidWebsiteURL(t('workplace_giving.validation.url'))
          .trim()
          .min(1, t('workplace_giving.validation.requiredField'))
          .max(2048, t('workplace_giving.validation.maxCharacters', { max: 2048 }))
    }),
    addressLineOne: Yup.string().when('locationType', {
      is: OpportunityLocationType.OFFLINE,
      then: () => validateString()
    }),
    addressLineTwo: Yup.string().when('locationType', {
      is: OpportunityLocationType.OFFLINE,
      then: () => validateString({ optional: true })
    }),
    city: Yup.string().when('locationType', {
      is: OpportunityLocationType.OFFLINE,
      then: () => validateString()
    }),
    zipCode: Yup.string().when('locationType', {
      is: OpportunityLocationType.OFFLINE,
      then: () => validateString()
    }),
    country: Yup.string().when('locationType', {
      is: OpportunityLocationType.OFFLINE,
      then: () => validateString()
    }),
    childEvents: Yup.array().when('isSeries', {
      is: true,
      then: () => Yup.array<ChildSessionEvent>().min(1, t('workplace_giving.validation.sessionRequired'))
    }),
    participantSpots: validateNumber({ max: config.maxParticipantSpots, optional: true }).test(
      'is_above_participant_spots',
      t('workplace_giving.volunteering.opportunityEdit.spotsGreaterThanParticipants', {
        count: initialData?.participants?.count ?? 0
      }),
      value => {
        if (value && initialData?.participantsCount) {
          return value >= initialData.participantsCount
        }

        return true
      }
    ),
    organisationCountry: validateString({ optional: true }),
    organisationId: validateString({ optional: true }),
    activities: Yup.array().of(Yup.string()),
    skills: Yup.array().of(Yup.string()),
    manualTimeTracking: Yup.boolean().default(false),
    long: Yup.number(),
    lat: Yup.number()
  })

  return validationSchema
}
