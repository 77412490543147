import { FocusEvent, FormEvent, useCallback, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormikErrors, FormikTouched } from 'formik'
import { MultiSelect, NumberInput } from '@mantine/core'
import { Alpha2Code } from 'i18n-iso-countries'
import { useDebounce } from 'usehooks-ts'
import { identity } from 'lodash'

import countries from '@percent/workplace-giving/i18n/countries'
import { getStyles } from './LogVolunteeringActivity.styles'
import { AsyncSelect, FlagAvatar, FormField, Icon, Loader, Select } from '@percent/lemonade'
import { DateTimePicker } from '@percent/workplace-giving/common/components'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'
import { useQuery } from '@percent/workplace-giving/common/hooks'
import { searchOrganisations } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations'
import { Organisation } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations.types'
import { createShortLink } from '@percent/utility'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { getVolunteeringActivities } from '@percent/workplace-giving/api/volunteering/getVolunteeringActivities/getVolunteeringActivities'
import { IconProps } from 'libs/shared/ui-lemonade/src/components/icon/icon.types'
import { getAccountOpportunities } from '@percent/workplace-giving/api/opportunity/getAccountOpportunities/getAccountOpportunities'
import { SelectOptionCard } from '@percent/workplace-giving/common/components/SelectOptionCard/SelectOptionCard'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

interface LogVolunteeringActivityFormFields {
  date?: Date
  hours?: number
  minutes?: number
  organisationCountry?: string
  organisationId?: string
  activities?: string[]
  platform: 'on-platform' | 'off-platform'
  opportunityId?: string
}

interface LogVolunteeringActivityFormProps {
  values: LogVolunteeringActivityFormFields
  touched: FormikTouched<LogVolunteeringActivityFormFields>
  errors: FormikErrors<LogVolunteeringActivityFormFields>
  handleChange: (e: FormEvent<HTMLInputElement>) => void
  handleBlur: <A = HTMLInputElement>(e: FocusEvent<A>) => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<LogVolunteeringActivityFormFields>>
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<LogVolunteeringActivityFormFields>>
}

export function LogVolunteeringActivityForm({
  values,
  errors,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched
}: Readonly<LogVolunteeringActivityFormProps>) {
  const { t, i18n } = useTranslation()
  const [selectIcon, setSelectIcon] = useState<IconProps['name']>('dropdown-arrow-down')
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const alpha3CountryCodes = useMemo(
    () => [
      {
        value: '',
        label: t('workplace_giving.search.world'),
        prefix: <WorldFlagAvatar />
      },
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, 'en'),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    [t]
  )

  const defaultCountryValue = alpha3CountryCodes.filter(a => a.value === values.organisationCountry)[0]

  const { data: activitiesData, isLoading } = useQuery(['getVolunteeringActivities'], getVolunteeringActivities)

  const activitiesOptions = useMemo(() => {
    if (activitiesData) {
      return activitiesData
        .map(activity => ({
          value: activity.id,
          label: activity.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }

    return []
  }, [activitiesData])

  const resetOrgIdFieldAfterCountryChange = useCallback(async () => {
    await setFieldValue('organisationId', '')
    await setFieldTouched('organisationId', false, true)
  }, [setFieldTouched, setFieldValue])

  const [organisationQuery, setOrganisationQuery] = useState<string>('')
  const [searchCountryCode, setSearchCountryCode] = useState<string>(defaultCountryValue.value)

  const debouncedOrganisationQuery = useDebounce(organisationQuery, 200)
  const { data: searchData, isFetching } = useQuery(
    ['searchNonProfits', { query: debouncedOrganisationQuery, pageSize: 10, countryCode: searchCountryCode }],
    searchOrganisations,
    { enabled: !!debouncedOrganisationQuery }
  )
  const shortLanguage = i18n.language.split('-')[0]

  const getOrganisationDescription = useCallback(
    (organisation: Organisation): string => {
      return [
        organisation.website ? createShortLink(organisation.website) : undefined,
        `${t('workplace_giving.common.id')}: ${organisation.registryId}`,
        countries.getName(organisation.countryCode, shortLanguage)
      ]
        .filter(identity)
        .join(' | ')
    },
    [shortLanguage, t]
  )

  const searchResults: SelectOption[] = useMemo(() => {
    return searchData
      ? searchData.data.map(organisation => ({
          value: organisation.id,
          label: organisation.name,
          description: getOrganisationDescription(organisation)
        }))
      : []
  }, [searchData, getOrganisationDescription])

  const [registeredOpportunitiesQuery, setRegisteredOpportunitiesQuery] = useState<string>('')

  const debouncedRegisteredOpportunitiesQuery = useDebounce(registeredOpportunitiesQuery, 200)
  const { data: registeredOpportunitiesData, isFetching: isFetchingRegisteredOpportunities } = useQuery(
    [
      'getAccountOpportunities',
      { query: debouncedRegisteredOpportunitiesQuery, pageSize: 10, participationStatus: ['registered', 'confirmed'] }
    ],
    getAccountOpportunities,
    { enabled: !!debouncedRegisteredOpportunitiesQuery }
  )
  const registeredOpportunitiesSearchResults: SelectOption[] = useMemo(() => {
    return registeredOpportunitiesData
      ? registeredOpportunitiesData.data.map(registeredOpportunity => ({
          value: registeredOpportunity.opportunity.id,
          label: registeredOpportunity.opportunity.name
        }))
      : []
  }, [registeredOpportunitiesData])

  return (
    <Box component="form" sx={Styles.Form} onSubmit={event => event.preventDefault()}>
      <Box sx={Styles.OptionsCardsBox}>
        <SelectOptionCard
          testId="offPlatformVariant"
          icon="volunteering"
          title={t('workplace_giving.volunteering.activityForm.offPlatformLabel')}
          description={t('workplace_giving.volunteering.activityForm.offPlatformDescription')}
          active={values.platform === 'off-platform'}
          onClick={() => {
            setFieldValue('platform', 'off-platform')
          }}
        />
        <SelectOptionCard
          testId="onPlatformVariant"
          icon="volunteering-calendar"
          title={t('workplace_giving.volunteering.activityForm.onPlatformLabel')}
          description={t('workplace_giving.volunteering.activityForm.onPlatformDescription')}
          active={values.platform === 'on-platform'}
          onClick={() => {
            setFieldValue('platform', 'on-platform')
          }}
        />
      </Box>

      {values.platform === 'on-platform' ? (
        <Box>
          <Typography sx={Styles.SectionTitle}>
            {t('workplace_giving.volunteering.activityForm.opportunityId.title')}
          </Typography>
          <FormField
            necessity="required"
            label={t('workplace_giving.volunteering.activityForm.opportunityId.label')}
            status={touched.opportunityId && errors.opportunityId ? 'danger' : 'default'}
            statusMessage={errors.opportunityId}
            data-testid="opportunitySearchField"
          >
            <AsyncSelect
              name="opportunityId"
              placeholder={t('workplace_giving.volunteering.activityForm.opportunityId.placeholder')}
              onChange={e => {
                setFieldValue('opportunityId', e?.value ?? '')
                setFieldTouched('opportunityId')
              }}
              options={registeredOpportunitiesSearchResults}
              setQuery={e => {
                setRegisteredOpportunitiesQuery(e)
                setFieldTouched('opportunityId')
                setFieldValue('opportunityId', e)
              }}
              query={registeredOpportunitiesQuery}
              loading={isFetchingRegisteredOpportunities}
              loadingText={t('workplace_giving.common.searching')}
              noResultsFoundText={
                registeredOpportunitiesQuery
                  ? t('workplace_giving.volunteering.activityForm.opportunitySearch.noResults')
                  : t('workplace_giving.volunteering.activityForm.opportunitySearch.emptyQuery')
              }
              data-testid="opportunitySearch"
            />
          </FormField>
        </Box>
      ) : null}

      <Box>
        <Typography sx={Styles.SectionTitle}>{t('workplace_giving.volunteering.activityForm.dateAndTime')}</Typography>
        <FormField
          label={t('workplace_giving.volunteering.activityForm.date.label')}
          necessity="required"
          status={touched.date && errors.date ? 'danger' : 'default'}
          statusMessage={errors.date}
          data-testid="dateField"
        >
          <DateTimePicker
            fieldName="date"
            value={values.date}
            handleBlur={handleBlur}
            handleChange={date => setFieldValue('date', date)}
            error={!!errors.date && !!touched.date}
            noTimeField
          />
        </FormField>

        <Box sx={Styles.FlexWrapper}>
          <FormField
            label={t('workplace_giving.volunteering.activityForm.hours.label')}
            necessity="required"
            status={touched.hours && errors.hours ? 'danger' : 'default'}
            statusMessage={errors.hours}
            data-testid="hoursField"
          >
            <NumberInput
              style={{ width: '100%' }}
              name="hours"
              min={0}
              max={24}
              step={1}
              hideControls
              allowNegative={false}
              allowDecimal={false}
              placeholder={t('workplace_giving.volunteering.activityForm.hours.placeholder')}
              value={values.hours}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('hours', value)
              }}
              error={!!errors.hours && !!touched.hours}
            />
          </FormField>

          <FormField
            label={t('workplace_giving.volunteering.activityForm.minutes.label')}
            necessity="required"
            status={touched.minutes && errors.minutes ? 'danger' : 'default'}
            statusMessage={errors.minutes}
            data-testid="minutesField"
          >
            <NumberInput
              style={{ width: '100%' }}
              name="minutes"
              min={0}
              max={59}
              step={1}
              hideControls
              allowNegative={false}
              allowDecimal={false}
              placeholder={t('workplace_giving.volunteering.activityForm.minutes.placeholder')}
              defaultValue={0}
              value={values.minutes}
              onBlur={e => {
                handleBlur(e)
                setFieldTouched('hours')
              }}
              onChange={value => {
                setFieldValue('minutes', value)
              }}
              error={!!errors.minutes && !!touched.minutes}
            />
          </FormField>
        </Box>
      </Box>

      {values.platform === 'off-platform' && (
        <>
          <Box>
            <Typography sx={Styles.SectionTitle}>
              {t('workplace_giving.volunteering.activityForm.supportedNonprofit')}
            </Typography>

            <FormField
              necessity="optional"
              label={t('workplace_giving.volunteering.activityForm.nonprofitCountry.label')}
              description={t('workplace_giving.wizard.nonprofitCountry.description')}
              status={touched.organisationCountry && errors.organisationCountry ? 'danger' : 'default'}
              statusMessage={errors.organisationCountry}
              data-testid="organisationCountryField"
            >
              <Select
                status={errors.organisationCountry ? 'danger' : 'default'}
                placeholder={t('workplace_giving.wizard.nonprofitCountry.placeholder')}
                options={alpha3CountryCodes}
                defaultValue={defaultCountryValue}
                onChange={event => {
                  setFieldValue('organisationCountry', event.value)
                  setSearchCountryCode(event.value)
                  resetOrgIdFieldAfterCountryChange()
                }}
                searchable
                showPrefixForSelectedOption
              />
            </FormField>

            <FormField
              necessity="optional"
              label={t('workplace_giving.volunteering.activityForm.findNonprofit.label')}
              status={touched.organisationId && errors.organisationId && !organisationQuery ? 'danger' : 'default'}
              statusMessage={errors.organisationId}
              data-testid="organisationSearchField"
            >
              <AsyncSelect
                status={errors.organisationId && !organisationQuery ? 'danger' : 'default'}
                name="organisationId"
                key={searchCountryCode}
                placeholder={t('workplace_giving.wizard.selectNonprofit.placeholder')}
                onChange={e => {
                  setFieldValue('organisationId', e?.value ?? '')
                  setFieldTouched('organisationId')
                }}
                options={searchResults}
                setQuery={e => {
                  setOrganisationQuery(e)
                  setFieldTouched('organisationId')
                }}
                query={organisationQuery}
                loading={isFetching}
                loadingText={t('workplace_giving.donationMatchRequest.organisationSearch.loading')}
                noResultsFoundText={
                  organisationQuery
                    ? t('workplace_giving.donationMatchRequest.organisationSearch.noResults')
                    : t('workplace_giving.donationMatchRequest.organisationSearch.emptyQuery')
                }
                data-testid="organisationSearch"
              />
            </FormField>
          </Box>
          <Box>
            <Typography sx={Styles.SectionTitle}>
              {t('workplace_giving.volunteering.activityForm.volunteerActivity')}
            </Typography>
            {isLoading ? (
              <Loader />
            ) : (
              <FormField
                label={t('workplace_giving.volunteering.activityForm.activityTags.label')}
                necessity="optional"
                data-testid="activitiesField"
              >
                <MultiSelect
                  style={{ width: '100%' }}
                  placeholder={
                    !values.activities?.length
                      ? t('workplace_giving.volunteering.activityForm.activityTags.placeholder')
                      : ''
                  }
                  searchable
                  data={activitiesOptions}
                  value={values.activities}
                  onChange={selectedValues => {
                    setFieldValue('activities', selectedValues)
                  }}
                  rightSection={<Icon name={selectIcon} size={8} color="gray600" />}
                  onDropdownOpen={() => {
                    setSelectIcon('dropdown-arrow-up')
                  }}
                  onDropdownClose={() => {
                    setSelectIcon('dropdown-arrow-down')
                  }}
                />
              </FormField>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}
