import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom-v6'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params'
import dayjs from 'dayjs'

import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { getAccountFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { useLogger } from '@percent/workplace-giving/common/hooks/useLogger/useLogger'
import { getAccountOpportunities } from '@percent/workplace-giving/api/opportunity/getAccountOpportunities/getAccountOpportunities'
import { Button, Icon, IllustratedMessage, useToast } from '@percent/lemonade'
import { getStyles } from './MyOpportunities.styles'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import {
  OpportunityLocationType,
  OpportunityStatus,
  OpportunityType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { MyOpportunitiesSkeleton } from '@percent/workplace-giving/app/MyOpportunities/MyOpportunitiesSkeleton'
import { MyOpportunitiesActionsMenu } from './MyOpportunitiesActionsMenu/MyOpportunitiesActionsMenu'
import { CursorPagination } from '@percent/workplace-giving/common/components/CursorPagination/CursorPagination'
import { useQueryList } from '@percent/workplace-giving/common/hooks/useQueryList/useQueryList'
import { CancelOpportunityModal } from './CancelOpportunityModal'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { cancelOpportunity } from '@percent/workplace-giving/api/opportunity/cancel/cancel-opportunity'
import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { UnexpectedErrorModal } from '@percent/workplace-giving/common/components/UnexpectedErrorModal/UnexpectedErrorModal'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { GetAccountOpportunity } from '@percent/workplace-giving/api/opportunity/getAccountOpportunities/getAccountOpportunities.types'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

type ErrorCode =
  | 'opportunity/cannot_be_cancelled'
  | 'opportunity/cancel_invalid_state'
  | 'opportunity/cannot_have_published_children'
  | 'opportunity/external'

export function MyOpportunities() {
  const { track } = useAnalytics()
  const { t } = useTranslation()
  const { logError } = useLogger()
  const { addToast } = useToast()
  const navigation = useNavigate()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const authState = useAuth()
  const account = getAccountFromAuthState(authState.state)!
  const [query, setQuery] = useQueryParams({
    createdByMe: BooleanParam,
    locationType: StringParam,
    type: StringParam,
    participationStatus: ArrayParam,
    status: StringParam,
    startDateGt: StringParam,
    startDateLt: StringParam,
    endDateGt: StringParam,
    endDateLt: StringParam,
    page: NumberParam,
    direction: StringParam,
    cursor: StringParam,
    pageSize: NumberParam
  })
  const { formatDate } = useDateTimeFmt()
  const emptySearchParams = Object.values(query).every(el => el === undefined)
  const activeTab = query.endDateLt !== undefined ? 'past' : 'upcoming'

  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [selectedOpportunityId, setSelectedOpportunityId] = useState<string | undefined>(undefined)

  const errorCodesMap: Record<ErrorCode, string> = {
    'opportunity/cannot_be_cancelled': t('workplace_giving.errors.cancelOpportunity.cannotBeCancelled'),
    'opportunity/cancel_invalid_state': t('workplace_giving.errors.cancelOpportunity.cancelInvalidState'),
    'opportunity/cannot_have_published_children': t(
      'workplace_giving.errors.cancelOpportunity.cannotHavePublishedChildren'
    ),
    'opportunity/external': t('workplace_giving.errors.cancelOpportunity.externalOpportunity')
  }

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    refetch,
    isRefetching,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage
  } = useQueryList('getAccountOpportunities', { ...query, pageSize: 10 }, getAccountOpportunities, setQuery)

  const handleErrorStateChanges = (error: any) => {
    setCancelModalOpen(false)
    setSelectedOpportunityId(undefined)

    if (error.code in errorCodesMap) {
      setErrorMessage(errorCodesMap[error.code as ErrorCode])
    } else {
      setErrorMessage(t('workplace_giving.errors.api.modal.cancelOpportunity'))
    }
    setOpenErrorModal(true)
  }

  const { mutateAsync, isLoading: isLoadingMutation } = useMutation({
    mutationFn: cancelOpportunity,
    onSuccess: res => {
      if (okResponse(res)) {
        setCancelModalOpen(false)
        refetch()
        addToast(t('workplace_giving.cancelOpportunity.successToast'), 'success')
      } else {
        logError(res.error)
        setCancelModalOpen(false)
        setSelectedOpportunityId(undefined)

        if (res.error.code in errorCodesMap) {
          setErrorMessage(errorCodesMap[res.error.code as ErrorCode])
        } else {
          setErrorMessage(t('workplace_giving.errors.api.modal.cancelOpportunity'))
        }
        setOpenErrorModal(true)
      }
    },
    onError: e => {
      logError(e)
      handleErrorStateChanges(e)
    }
  })

  const handleCancelEvent = async () => {
    if (selectedOpportunityId) {
      try {
        await mutateAsync({ id: selectedOpportunityId })
      } catch (e) {
        logError(e)
        handleErrorStateChanges(e)
      }
    }
  }

  useEffect(() => {
    if (emptySearchParams) {
      setQuery({ endDateGt: new Date().toISOString(), direction: 'ASC' })
    }
  }, [emptySearchParams, navigation, setQuery])

  const multipleSessionsInfo = useMemo(
    () => <Typography fontSize={14}>{t('workplace_giving.volunteering.multipleSessions')}</Typography>,
    [t]
  )

  const getStartDateInfo = (opportunity: GetAccountOpportunity['opportunity']) => {
    if (opportunity.isSeries) {
      return multipleSessionsInfo
    }

    if (opportunity.timeTracking === 'manual') {
      return ''
    }

    return formatDate(new Date(opportunity.startDate))
  }

  const getLocationInfo = (opportunity: GetAccountOpportunity['opportunity']) => {
    if (opportunity.isSeries) {
      return multipleSessionsInfo
    }

    if (opportunity.location.type === OpportunityLocationType.VIRTUAL) {
      return (
        <Box display="flex" alignItems="center" sx={Styles.LocationLabelWrapper}>
          <Icon size="m" name="virtual-meeting" />
          <Typography fontSize={14} fontWeight={500}>
            {t('workplace_giving.volunteering.virtualOpportunity')}
          </Typography>
        </Box>
      )
    }

    return (
      <Box display="flex" alignItems="center" sx={Styles.LocationLabelWrapper}>
        <Icon size="m" name="pin" />
        <Typography fontSize={14} fontWeight={500}>
          {`${opportunity.location.city}, ${opportunity.location.country}`}
        </Typography>
      </Box>
    )
  }

  if (isError) {
    return (
      <Page>
        <Box width="100%">
          <Typography
            mt={5}
            textAlign="center"
            variant="body2"
            sx={{
              color: theme.alert400
            }}
          >
            {t('workplace_giving.errors.unexpected')}
          </Typography>
        </Box>
      </Page>
    )
  }

  if (isLoading || isRefetching) {
    return <MyOpportunitiesSkeleton />
  }

  return (
    <>
      <Box width="100%">
        <Box my={3} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Chip
              label={t('workplace_giving.myOpportunity.upcoming')}
              variant="outlined"
              sx={Styles.Chip}
              disabled={activeTab === 'upcoming'}
              onClick={() => {
                setQuery({ endDateGt: new Date().toISOString(), direction: 'ASC' }, 'push')
              }}
            />
            <Chip
              label={t('workplace_giving.myOpportunity.past')}
              variant="outlined"
              sx={Styles.Chip}
              disabled={activeTab === 'past'}
              onClick={() => setQuery({ endDateLt: new Date().toISOString(), direction: 'DESC' }, 'push')}
            />
          </Box>
          <Button
            size="small"
            onPress={() => {
              track({
                event: 'Volunteering Opportunity Create Start',
                properties: {
                  origin: 'My Opportunities'
                }
              })
              navigation(AppRoute.CREATE_OPPORTUNITY)
              window.scrollTo(0, 0)
            }}
          >
            {t('workplace_giving.volunteer.createOpportunity')}
          </Button>
        </Box>
        {data?.data.length !== 0 ? (
          <TableContainer sx={Styles.Table}>
            <Table>
              <TableHead>
                <TableRow sx={Styles.TableRowHeader}>
                  <TableCell align="left" width="24%">
                    {t('workplace_giving.myOpportunity.name')}
                  </TableCell>
                  <TableCell align="left" width="18.5%">
                    {t('workplace_giving.myOpportunity.startDate')}
                  </TableCell>
                  <TableCell align="left" width="16.5%">
                    {t('workplace_giving.myOpportunity.location')}
                  </TableCell>
                  <TableCell align="left" width="16.5%">
                    {t('workplace_giving.myOpportunity.organiser')}
                  </TableCell>
                  <TableCell align="left" width="16.5%">
                    {t('workplace_giving.myOpportunity.attendees')}
                  </TableCell>
                  <TableCell align="left" width="8%">
                    {t('workplace_giving.myOpportunity.actions')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data.map(row => (
                  <TableRow sx={Styles.TableRow} key={row.opportunity.id}>
                    <TableCell width="24%" component="th" scope="row">
                      <NavLink style={{ color: theme.primary }} to={`/volunteer/opportunities/${row.opportunity.id}`}>
                        {row.opportunity?.parent
                          ? `${row.opportunity.parent.name}, ${row.opportunity.name}`
                          : row.opportunity.name}
                      </NavLink>
                    </TableCell>
                    <TableCell width="18.5%" component="th" scope="row" align="left">
                      <Typography fontSize={14} color={theme.gray600}>
                        {getStartDateInfo(row.opportunity)}
                      </Typography>
                    </TableCell>
                    <TableCell width="16.5%" component="th" scope="row" align="left">
                      {getLocationInfo(row.opportunity)}
                    </TableCell>
                    <TableCell width="16.5%" component="th" scope="row" align="left">
                      <Typography fontSize={14} color={theme.gray600}>
                        {row.opportunity.isCreatedByMe
                          ? account.fullName || account.preferredName
                          : row.opportunity.organiser?.fullName}
                      </Typography>
                    </TableCell>
                    <TableCell width="16.5%" component="th" scope="row" align="left">
                      <Typography fontSize={14} color={theme.gray600}>
                        {row.opportunity.isSeries ? multipleSessionsInfo : row.opportunity.participantsCount}
                      </Typography>
                    </TableCell>
                    <TableCell width="8%" component="th" scope="row" align="left">
                      {row.opportunity.isCreatedByMe &&
                      row.opportunity.status === OpportunityStatus.ACTIVE &&
                      activeTab === 'upcoming' ? (
                        <MyOpportunitiesActionsMenu
                          handleSelect={(actionKey: 'view' | 'edit' | 'cancel') => {
                            setSelectedOpportunityId(row.opportunity.id)
                            const actions = {
                              view: () => navigation(`/volunteer/opportunities/${row.opportunity.id}`),
                              edit: () => {
                                if (!row.opportunity.external) {
                                  track({
                                    event: 'Volunteering Opportunity Edit Start',
                                    properties: {
                                      origin: 'My Opportunities',
                                      opportunityType: row.opportunity.type,
                                      opportunityLocationType: row.opportunity.location.type
                                    }
                                  })
                                  navigation(`/volunteer/opportunities/${row.opportunity.id}/edit`)
                                }
                              },
                              cancel: () => {
                                if (!row.opportunity.external) {
                                  track({
                                    event: 'Volunteering Opportunity Cancel',
                                    properties: {
                                      opportunityType: row.opportunity.type,
                                      opportunityLocationType: row.opportunity.location.type
                                    }
                                  })
                                  setCancelModalOpen(true)
                                }
                              }
                            }

                            return actions[actionKey]()
                          }}
                          isOngoingEvent={
                            !row.opportunity.isSeries &&
                            row.opportunity.type === OpportunityType.EVENT &&
                            dayjs(row.opportunity.startDate).isBefore(dayjs())
                          }
                          isExternalEvent={!!row.opportunity.external}
                        />
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box padding={2}>
              <CursorPagination
                nextPage={fetchNextPage}
                previousPage={fetchPreviousPage}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                totalRecords={data.totalResults}
              />
            </Box>
          </TableContainer>
        ) : (
          <IllustratedMessage
            illustration="opportunities-frame"
            title={t(
              `workplace_giving.myOpportunity.illustratedMessage.${activeTab === 'upcoming' ? 'titleOne' : 'titleTwo'}`
            )}
            description={t('workplace_giving.myOpportunity.illustratedMessage.description')}
          />
        )}
      </Box>
      <CancelOpportunityModal
        open={cancelModalOpen}
        handleCancelEvent={handleCancelEvent}
        handleClose={() => {
          setCancelModalOpen(false)
          setSelectedOpportunityId(undefined)
        }}
        isLoading={isLoadingMutation}
      />
      <UnexpectedErrorModal
        message={t('workplace_giving.cancelOpportunity.error')}
        description={errorMessage || t('workplace_giving.errors.api.modal.cancelOpportunity')}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false)
        }}
      />
    </>
  )
}
