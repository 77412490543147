import { Box, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom-v6'
import { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { usePermission } from '@percent/workplace-giving/common/hooks/usePermission/usePermission'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import {
  getAccountConfigFromAuthState,
  getAccountFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { Tabs, Avatar } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { getInitials } from '@percent/workplace-giving/utils/getInitials/getInitials'
import { getStyles } from './MyProfile.styles'
import { feature, scope } from '@percent/workplace-giving/common/hoc/withPermissions'
import { GamificationBadges } from '@percent/workplace-giving/common/components/GamificationBadges/GamificationBadges'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function MyProfile({ children }: Readonly<{ children?: ReactNode }>) {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const authState = useAuth()

  const { theme } = useColorTheme()
  const Styles = getStyles(theme)
  const location = useLocation()
  const has = usePermission()

  const account = getAccountFromAuthState(authState.state)!
  const profile = getAccountConfigFromAuthState(authState.state)

  const volunteeringEnabled = has(scope('volunteering'))
  const canCreateFundraiser = has(scope('donation_link'))
  const gamificationEnabled = has(scope('gamification'), feature('gamification'))

  const onChangeTab = useCallback(
    (tab: string) => {
      if (tab === 'my-fundraiser') {
        navigation(AppRoute.MY_FUNDRAISER)
      }

      if (tab === 'my-opportunity') {
        navigation(AppRoute.MY_OPPORTUNITY)
      }
    },
    [navigation]
  )

  return (
    <Page>
      <Box width="100%">
        <Box mb={4} display="flex" alignItems="center">
          <Avatar
            size="xlarge"
            image=""
            customInitials={getInitials(account.fullName || account.preferredName)}
            alt={account.fullName || account.preferredName || ''}
          />
          <Box ml={2} display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" gap={2}>
              <Typography sx={Styles.Name}>{account.fullName || account.preferredName}</Typography>
              {gamificationEnabled && <GamificationBadges />}
            </Box>
            {profile?.role ? (
              <Typography sx={Styles.Role} data-testid="roleDetails">
                {profile.role}
              </Typography>
            ) : null}
            {profile?.city || profile?.country ? (
              <Typography sx={Styles.DetailsText} data-testid="addressDetails">
                {profile.city ? `${profile.city}${profile.country ? ', ' : ''}` : ''}
                {profile.country ? profile.country : ''}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Tabs
          items={[
            ...(canCreateFundraiser
              ? [
                  {
                    key: 'my-fundraiser',
                    label: t('workplace_giving.myFundraiser.myFundraisers')
                  }
                ]
              : []),
            ...(volunteeringEnabled
              ? [
                  {
                    key: 'my-opportunity',
                    label: t('workplace_giving.myOpportunity.myOpportunities')
                  }
                ]
              : [])
          ]}
          activeTab={location.pathname.split('/')[1]}
          onChangeTab={onChangeTab}
        />
        {children}
      </Box>
    </Page>
  )
}
