import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getFormBasicStyles = (customPalette: ColorPaletteType) => ({
  '*': {
    fontFamily: 'Inter, sans-serif !important'
  },

  '.mantine-MultiSelect-inputField': {
    '&::placeholder': {
      color: `${customPalette.gray300} !important`
    },

    '&[disabled]': {
      cursor: 'not-allowed'
    }
  },

  '.mantine-MultiSelect-section': {
    right: lemonadeTheme.sizes[2],
    width: 'auto !important'
  },

  '.mantine-MultiSelect-pill': {
    height: 'auto',
    margin: '-2px 0',
    backgroundColor: `${customPalette.gray80} !important`
  },

  '.mantine-Pill-label': {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    padding: `2px 0`
  },

  '.mantine-Input-input': {
    transition: 'none',
    borderColor: lemonadeTheme.colors.gray80,
    color: `${lemonadeTheme.colors.black} !important`,
    paddingY: '8px',
    minHeight: '40px',

    '&::placeholder': {
      color: `${customPalette.gray300} !important`
    },

    '&:hover': {
      outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.gray80}`,
      borderColor: lemonadeTheme.colors.black
    },

    '&:focus': {
      borderColor: `${lemonadeTheme.colors.black} !important`
    },

    '&[data-error="true"]': {
      borderColor: lemonadeTheme.colors.alert400
    },

    '&[data-error="true"]:hover': {
      outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.alert100}`,
      borderColor: lemonadeTheme.colors.alert400
    },

    '&:active': {
      borderColor: lemonadeTheme.colors.black
    },

    '&[data-disabled="true"]': {
      outline: 'none',
      borderColor: customPalette.gray80,
      opacity: 1,
      backgroundColor: customPalette.gray80
    }
  }
})

export const getStyles = (customPalette: ColorPaletteType) => ({
  ModalContentWrapper: {
    outline: 'none !important'
  },
  Title: {
    marginBottom: lemonadeTheme.sizes[6],
    fontSize: lemonadeTheme.sizes[6],
    lineHeight: lemonadeTheme.sizes[8],
    fontWeight: lemonadeTheme.fontWeights.semiBold
  },
  SectionTitle: {
    marginBottom: lemonadeTheme.sizes[4],
    fontSize: lemonadeTheme.sizes[5],
    lineHeight: lemonadeTheme.sizes[6],
    fontWeight: lemonadeTheme.fontWeights.semiBold,

    '&:not(&:first-of-type)': {
      marginTop: lemonadeTheme.sizes[8]
    }
  },

  FlexWrapper: {
    display: 'flex',
    flexFlow: 'column',
    gap: lemonadeTheme.sizes[4],

    '@media(min-width: 1024px)': {
      flexFlow: 'row'
    }
  },
  FormBasicStyles: getFormBasicStyles(customPalette),
  Form: {
    ...getFormBasicStyles(customPalette),
    '& > div': {
      marginBottom: lemonadeTheme.sizes[4]
    }
  },
  AddressLinesWrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    gap: lemonadeTheme.sizes[4],

    '@media(min-width: 768px)': {
      flexFlow: 'row'
    }
  },
  TimeTrackingLabel: {
    fontSize: lemonadeTheme.fontSizes.bodySmall
  },
  TimeTrackingDescription: {
    color: lemonadeTheme.colors.gray600,
    fontSize: lemonadeTheme.fontSizes.bodySmall
  },
  OptionsCardsBox: {
    display: 'flex',
    flexFlow: 'column',
    gap: lemonadeTheme.sizes[4],
    justifyContent: 'space-between',

    '@media(min-width: 1024px)': {
      flexFlow: 'row'
    },

    '& > div': {
      margin: '0 !important',
      flex: 1
    }
  }
})
