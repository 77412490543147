import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Wrapper: {
    '& > div': {
      marginY: lemonadeTheme.sizes[4]
    },

    '& > p:last-of-type': {
      fontSize: lemonadeTheme.fontSizes.bodySmall,
      lineHeight: lemonadeTheme.sizes[6],
      color: customPalette.gray600
    }
  },
  Title: {
    marginBottom: lemonadeTheme.sizes[4],
    fontSize: lemonadeTheme.sizes[6],
    lineHeight: lemonadeTheme.sizes[8],
    fontWeight: lemonadeTheme.fontWeights.semiBold
  }
})
