import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useLocation } from 'react-router-dom-v6'

import { VolunteeringSidePanel } from '../Volunteer/VolunteeringSidePanel/VolunteeringSidePanel'

import * as Styles from './MyImpact.styles'
import { Tabs } from '@percent/lemonade'
import { MyImpactTab } from '@percent/workplace-giving/app/MyImpact/MyImpact.types'
import { SidePanel } from '@percent/workplace-giving/app/SidePanel/SidePanel'
import { PastDonationsList } from '@percent/workplace-giving/app/MyImpact/PastDonationsList/PastDonationsList'
import { Tab } from '@percent/workplace-giving/common/components/Tab/Tab'
import { OffPlatformMatchRequestsList } from '@percent/workplace-giving/app/MyImpact/OffPlatformMatchRequestsList/OffPlatformMatchRequestsList'
import { useQueryList } from '@percent/workplace-giving/common/hooks/useQueryList/useQueryList'
import { retrieveRequestList } from '@percent/workplace-giving/api/donation-match-request/get-list/retrieve-request-list'
import { useAuth, useMatchRequestForm } from '@percent/workplace-giving/common/hooks'
import {
  getAccountConfigFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { PageContent } from '@percent/workplace-giving/common/components/Layout/PageContent'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { VolunteeringTimeLogs } from './VolunteeringTimeLogs/VolunteeringTimeLogs'
import { getVolunteerList } from '@percent/workplace-giving/api/volunteering/getVolunteerList/getVolunteerList'
import { usePermission } from '@percent/workplace-giving/common/hooks/usePermission/usePermission'
import { scope } from '@percent/workplace-giving/common/hoc/withPermissions'

type MyImpactLocationState = {
  activeTab?: MyImpactTab
} | null

export function MyImpact() {
  const { state: matchingFormState } = useMatchRequestForm()
  const { t } = useTranslation()
  const { state: authState } = useAuth()
  const accountConfig = getAccountConfigFromAuthState(authState)
  const partner = getPartnerFromAuthState(authState)!
  const currencyCode = accountConfig?.currency ?? partner.currencyCode
  const location = useLocation()
  const state = location.state as MyImpactLocationState
  const has = usePermission()
  const volunteeringEnabled = has(scope('volunteering'))

  const [activeTab, setActiveTab] = useState<MyImpactTab>(state?.activeTab || 'my-donations')

  useEffect(() => {
    if (state?.activeTab) {
      window.history.replaceState({}, '')
    }
  }, [activeTab, state?.activeTab])

  const [params, setParams] = useState({ pageSize: 10, currencyCode })
  const [volunteerParams, setVolunteerParams] = useState({ pageSize: 10 })

  const { data, hasNextPage, hasPreviousPage, fetchNextPage, fetchPreviousPage, isLoading, error, refetch } =
    useQueryList('retrieveRequests', params, retrieveRequestList, setParams, {
      enabled: activeTab === 'off-platform'
    })

  useEffect(() => {
    if (matchingFormState.isSuccess) {
      refetch()
    }
  }, [matchingFormState.isSuccess, refetch])

  const {
    data: volunteerData,
    hasNextPage: hasNextVolunteerPage,
    hasPreviousPage: hasPreviousVolunteerPage,
    fetchNextPage: fetchNextVolunteerPage,
    fetchPreviousPage: fetchPreviousVolunteerPage,
    isLoading: isVolunteerLoading,
    error: volunteerError
  } = useQueryList('retrieveRequests', volunteerParams, getVolunteerList, setVolunteerParams, {
    enabled: activeTab === 'volunteer' && volunteeringEnabled
  })

  const items = useMemo(() => {
    return volunteeringEnabled
      ? [
          {
            key: 'my-donations',
            label: t('workplace_giving.myImpact.tabs.myDonations')
          },
          {
            key: 'off-platform',
            label: t('workplace_giving.myImpact.tabs.offPlatform')
          },
          {
            key: 'volunteer',
            label: t('workplace_giving.myImpact.tabs.volunteer')
          }
        ]
      : [
          {
            key: 'my-donations',
            label: t('workplace_giving.myImpact.tabs.myDonations')
          },
          {
            key: 'off-platform',
            label: t('workplace_giving.myImpact.tabs.offPlatform')
          }
        ]
  }, [t, volunteeringEnabled])

  const onChangeTab = useCallback((tab: string) => setActiveTab(tab as MyImpactTab), [])

  return (
    <Page>
      <PageContent>
        <Typography variant="h2" component="h2" sx={Styles.PageTitle}>
          {t('workplace_giving.myImpact.pageTitle')}
        </Typography>
        <Tabs items={items} activeTab={activeTab} onChangeTab={onChangeTab} />
        <Tab index="my-donations" value={activeTab}>
          <PastDonationsList />
        </Tab>
        <Tab index="off-platform" value={activeTab}>
          <OffPlatformMatchRequestsList
            data={data}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            isLoading={isLoading}
            error={error}
          />
        </Tab>
        {volunteeringEnabled ? (
          <Tab index="volunteer" value={activeTab}>
            <VolunteeringTimeLogs
              data={volunteerData}
              hasNextPage={hasNextVolunteerPage}
              hasPreviousPage={hasPreviousVolunteerPage}
              fetchNextPage={fetchNextVolunteerPage}
              fetchPreviousPage={fetchPreviousVolunteerPage}
              isLoading={isVolunteerLoading}
              error={volunteerError}
            />
          </Tab>
        ) : null}
      </PageContent>
      {activeTab === 'volunteer' ? <VolunteeringSidePanel /> : <SidePanel />}
    </Page>
  )
}
