import { useTranslation } from 'react-i18next'

import { Menu } from '@percent/lemonade'

type MyOpportunitiesActionsMenuProps = {
  handleSelect: (key: 'view' | 'edit' | 'cancel') => void
  isOngoingEvent: boolean
  isExternalEvent: boolean
}

export function MyOpportunitiesActionsMenu({
  handleSelect,
  isOngoingEvent,
  isExternalEvent
}: MyOpportunitiesActionsMenuProps) {
  const { t } = useTranslation()

  return (
    <Menu
      title={t('workplace_giving.myOpportunity.actionsMenu.more')}
      onSelect={key => handleSelect(key as 'view' | 'edit' | 'cancel')}
      sections={[
        {
          items: [
            { key: 'view', label: t('workplace_giving.myOpportunity.actionsMenu.view') },
            ...(isExternalEvent
              ? []
              : [
                  { key: 'edit', label: t('workplace_giving.common.edit') },
                  {
                    key: 'cancel',
                    label: t('workplace_giving.myOpportunity.actionsMenu.cancelOpportunity'),
                    shouldBeDisabled: isOngoingEvent,
                    disabledTooltipInfo: t(
                      'workplace_giving.myOpportunity.actionsMenu.cancelEventUnavailableForOngoing'
                    )
                  }
                ])
          ]
        }
      ]}
    />
  )
}
