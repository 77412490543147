import {
  GetAccountOpportunitiesResponse,
  GetAccountOpportunityQuery
} from '@percent/workplace-giving/api/opportunity/getAccountOpportunities/getAccountOpportunities.types'
import { QueryListFunction } from '@percent/workplace-giving/api/goodstack'

export const getAccountOpportunities: QueryListFunction<
  string,
  GetAccountOpportunityQuery,
  GetAccountOpportunitiesResponse
> = async (client, [_, query]) => {
  const res = await client.get<GetAccountOpportunitiesResponse>(`/account/opportunities`, {
    params: {
      query: query?.query,
      createdByMe: query?.createdByMe,
      locationType: query?.locationType,
      type: query?.type,
      participationStatus: query?.participationStatus,
      status: query?.status,
      startDateGt: query?.startDateGt,
      startDateLt: query?.startDateLt,
      endDateGt: query?.endDateGt,
      endDateLt: query?.endDateLt,
      direction: query?.direction,
      pageSize: query?.pageSize,
      ...(query?.cursor && { cursor: query.cursor }),
      timeTracking: query?.timeTracking,
      page: query?.page
    },
    withCredentials: true
  })

  return res.data
}
