import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getStyles } from './OpportunitiesList.styles'
import { Avatar } from '@percent/lemonade'
import { Organisation } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations.types'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function OpportunityCardFooter({ name, logo }: Readonly<Organisation>) {
  const { t } = useTranslation()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <Box sx={Styles.ContentFooter} data-testid="orgInfoFooter">
      <Typography>{t('workplace_giving.volunteering.youWillBeHelping')}</Typography>
      <Box sx={Styles.FooterOrgInfo}>
        <Typography>{name}</Typography>
        {logo && <Avatar image={logo} size="small" alt="" />}
      </Box>
    </Box>
  )
}
