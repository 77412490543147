import { Box, Skeleton } from '@mui/material'

import { getStyles } from '../../../app/Fundraiser/FundraiserSidePanel/FundraiserSidePanel.styles'
import { useColorTheme } from '../../hooks/useColorTheme/useColorTheme'

export function SidePanelSkeleton({ isMobile = false }: { isMobile?: boolean }) {
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Skeleton variant="rectangular" width="100%" height={35} />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={120} height={25} />
        <Skeleton variant="rectangular" width={70} height={25} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={15} />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={120} height={15} />
        <Skeleton variant="rectangular" width={70} height={15} />
      </Box>
      <Box sx={isMobile ? Styles.DonateShareBtn : {}}>
        <Skeleton variant="rectangular" width="100%" height={27} />
        <Skeleton variant="rectangular" width="100%" height={27} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={1} />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={50} height={40} />
        <Skeleton variant="rectangular" width={250} height={40} />
      </Box>
    </Box>
  )
}
