import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Wrapper: {
    padding: `${lemonadeTheme.sizes[4]} ${lemonadeTheme.sizes[6]}`
  },
  SectionName: {
    marginBottom: lemonadeTheme.sizes[1],
    fontSize: lemonadeTheme.sizes[3],
    lineHeight: lemonadeTheme.sizes[6],
    color: customPalette.gray600
  },
  ActionsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: lemonadeTheme.sizes[6]
  },
  Divider: {
    borderColor: customPalette.gray80,
    margin: `${lemonadeTheme.sizes[4]} 0`,
    borderBottomWidth: '2px'
  },
  AchievementsTitle: {
    marginBottom: lemonadeTheme.sizes[4],
    fontSize: lemonadeTheme.sizes[5],
    lineHeight: lemonadeTheme.sizes[6],
    fontWeight: lemonadeTheme.fontWeights.semiBold
  }
})
