import { Box, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import { TFunction } from 'i18next'

import { SidePanelSkeleton } from '../../SidePanel/SidePanelSkeleton'

import { SidePanelContainer } from '@percent/workplace-giving/common/components/Layout/SidePanelContainer/SidePanelContainer'
import { BudgetDetails, TotalImpactDetails } from '@percent/domain/giving'
import * as SidebarStyles from '@percent/workplace-giving/app/SidePanel/SidePanel.styles'
import { getStyles } from './VolunteeringSidePanel.styles'
import { useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { getAccountBudget } from '@percent/workplace-giving/api/opportunity/getAccountBudget/getAccountBudget'
import { OpportunitiesBudget } from '@percent/workplace-giving/api/opportunity/getAccountBudget/getAccountBudget.types'
import { formatMinutesToHours } from '@percent/workplace-giving/utils/dayjs/dayjs'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'
import { SidePanelFooter } from '@percent/workplace-giving/common/components/SidePanelFooter/SidePanelFooter'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

const getFormattedTimeBudget = (t: TFunction, budgetData?: OpportunitiesBudget) => {
  if (!budgetData?.budget) {
    return null
  }

  return {
    allowance: formatMinutesToHours(budgetData.budget.allowanceMinutes, t),
    used: formatMinutesToHours(budgetData.budget.usedMinutes, t),
    pending: formatMinutesToHours(budgetData.budget.pendingMinutes, t),
    remaining: formatMinutesToHours(
      budgetData.budget.allowanceMinutes - (budgetData.budget.usedMinutes + budgetData.budget.pendingMinutes),
      t
    ),
    budgetUsed:
      !!budgetData.budget.allowanceMinutes && budgetData.budget.usedMinutes === budgetData.budget.allowanceMinutes,
    resetDate: new Date(budgetData.budget.renewsAt),
    usedPercentage: Math.round((budgetData.budget.usedMinutes / budgetData.budget.allowanceMinutes) * 100),
    pendingPercentage: Math.round((budgetData.budget.pendingMinutes / budgetData.budget.allowanceMinutes) * 100)
  }
}

export function VolunteeringSidePanel() {
  const { t } = useTranslation()
  const { logError } = useLogger()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const { data, isLoading, error } = useQuery(['getAccountBudget'], getAccountBudget, {
    staleTime: 1000 * 20
  })
  const formattedBudget = useMemo(() => getFormattedTimeBudget(t, data), [data, t])
  const { formatDateWODay } = useDateTimeFmt()
  useEffect(() => {
    if (error) {
      logError(error)
    }
  }, [error, logError])

  if (!data && isLoading) {
    return <SidePanelSkeleton />
  }

  return (
    <SidePanelContainer>
      {error || (!formattedBudget && data?.totalMinutes === undefined) ? (
        <Skeleton data-testid="budget-error-skeleton" variant="rectangular" height={306} style={{ borderRadius: 16 }} />
      ) : null}

      {data ? (
        <Box sx={SidebarStyles.TotalDonationContainer}>
          {!isLoading ? (
            <Box sx={Styles.Wrapper(!!formattedBudget)}>
              <TotalImpactDetails
                title={t('workplace_giving.volunteering.totalVolunteered.title')}
                value={`${formatMinutesToHours(data.totalMinutes, t) || '0h'}`}
                showNoImpactInfo={!data.totalMinutes}
                noImpactTitle={t('workplace_giving.volunteering.totalVolunteered.noImpact.title')}
                noImpactDescription={t('workplace_giving.volunteering.totalVolunteered.noImpact.description')}
              />
            </Box>
          ) : null}
          {!isLoading && formattedBudget ? (
            <BudgetDetails
              noBudgetLeft={formattedBudget.budgetUsed}
              noBudgetTitle={t('workplace_giving.volunteering.budget.noBudget.title')}
              noBudgetDescription={t('workplace_giving.volunteering.budget.noBudget.description', {
                resetDate: formatDateWODay(formattedBudget.resetDate)
              })}
              totalBudget={{
                name: t('workplace_giving.volunteering.budget.totalBudget'),
                value: formattedBudget.allowance
              }}
              usedBudget={{
                name: t('workplace_giving.volunteering.budget.usedBudget'),
                value: formattedBudget.used
              }}
              pendingBudget={{
                name: t('workplace_giving.volunteering.budget.pendingBudget'),
                value: formattedBudget.pending
              }}
              remainingBudget={{
                name: t('workplace_giving.volunteering.budget.remainingBudget'),
                value: formattedBudget.remaining
              }}
              resetInfo={t('workplace_giving.volunteering.budget.resetDate', {
                resetDate: formatDateWODay(formattedBudget.resetDate)
              })}
              isBudgetTouched={!!formattedBudget.usedPercentage || !!formattedBudget.pendingPercentage}
              usedPercentage={formattedBudget.usedPercentage}
              pendingPercentage={formattedBudget.pendingPercentage}
            />
          ) : null}
        </Box>
      ) : null}
      <SidePanelFooter />
    </SidePanelContainer>
  )
}
