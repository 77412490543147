import { google } from 'calendar-link'
import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { formatOfflineOpportunityAddress } from '../formatOfflineOpportunityAddress'

import {
  Opportunity,
  OpportunityLocationType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { ButtonText } from '@percent/lemonade'
import { getStyles } from './AddToCalendar.styles'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { config } from '@percent/workplace-giving/config/config'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export const makeOpportunityPageLink = (opportunityId: string) => {
  return `${config.urls.appUrl}/volunteer/opportunities/${opportunityId}`
}

export const formatOpportunityDescription = (opportunity: Pick<Opportunity, 'location' | 'description' | 'id'>) => {
  const locationText = (() => {
    if (opportunity.location.type === OpportunityLocationType.OFFLINE) {
      return `Location: ${formatOfflineOpportunityAddress(opportunity)}`
    }

    if (opportunity.location.type === OpportunityLocationType.VIRTUAL && opportunity.location.link) {
      return `Video conference link: <a href="${opportunity.location.link}">${opportunity.location.link}</a>`
    }

    return undefined
  })()

  const calendarDescription = `
${locationText ?? ''}${
    locationText
      ? `

`
      : ''
  }Event page: <a href="${makeOpportunityPageLink(opportunity.id)}">${makeOpportunityPageLink(opportunity.id)}</a>

${opportunity.description}`

  return calendarDescription
}

export const opportunityToEvent = (opportunity: Opportunity) => {
  const location = formatOfflineOpportunityAddress(opportunity)
  const event = {
    title: opportunity.name,
    description: formatOpportunityDescription(opportunity),
    start: opportunity.startDate,
    end: opportunity.endDate,
    ...(location ? { location } : {})
  }

  return event
}

export function AddToCalendar({ opportunity }: { opportunity: Opportunity }) {
  const { t } = useTranslation()
  const { track } = useAnalytics()
  const { theme } = useColorTheme()
  const { AddToCalendarContainer } = getStyles(theme)

  const links = useMemo(() => {
    const googleUrl = google(opportunityToEvent(opportunity))

    return {
      google: googleUrl
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.id])

  return (
    <Box sx={AddToCalendarContainer} display="flex" alignItems="center">
      <Typography mr={0.5}>{t('workplace_giving.volunteering.calendar.addTo')}</Typography>
      <ButtonText
        onPress={() => {
          track({
            event: 'Volunteering Opportunity Add to Calendar',
            properties: {
              opportunityType: opportunity.type,
              opportunityLocationType: opportunity.location.type
            }
          })
          window.open(links.google, '_blank', 'noopener noreferrer')
        }}
      >
        {t('workplace_giving.volunteering.calendar.google')}
      </ButtonText>
    </Box>
  )
}
