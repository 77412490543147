import { useCallback, useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { InfiniteData } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import * as Styles from '../Fundraiser.styles'
import { FundraiserSidePanel } from '../FundraiserSidePanel/FundraiserSidePanel'

import {
  useAuth,
  useMatchingBudget,
  useClient,
  useLogger,
  useDonationSummary,
  useDonationWallet
} from '@percent/workplace-giving/common/hooks'
import { Description } from '@percent/workplace-giving/common/components'
import { PageContent } from '@percent/workplace-giving/common/components/Layout/PageContent'
import { RecentDonation, RecentDonationsList } from '@percent/domain/fundraiser'
import { Donation } from 'libs/domain/fundraiser/src/components/recent-donations-list/recent-donations-list.types'
import { HeroImage } from '@percent/workplace-giving/common/components/Description/HeroImage/HeroImage'
import { TitleAndStory } from '@percent/workplace-giving/common/components/Description/TitleAndStory/TitleAndStory'
import { useDonationForm } from '@percent/workplace-giving/common/hooks/useDonationForm/useDonationForm'
import { config, environment } from '@percent/workplace-giving/config/config'
import { DonationCompletedMessage, FundraiserPageProps, Totals } from './FundraiserPage.types'
import {
  getAccountFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { Money } from '@percent/utility'
import { convertCurrency } from '@percent/workplace-giving/api/currency/convert/convert'
import { useMoneyFormat } from '@percent/workplace-giving/common/hooks/useMoneyFormat/useMoneyFormat'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'

type ProcessedDonation = Donation & {
  fundraiserAmount: Money
  fundraiserMatchedAmount?: Money
}

// [ tech debt] refactor fundraiser page
export function FundraiserPage({
  fundraiserDetailsData,
  fundraiserTotalsData,
  mappedData,
  hasNextPage,
  fetchNextPage,
  dataError,
  dataLoading,
  isMobile,
  refetchFundraiserTotalsData,
  refetchRecentDonationPageData
}: Readonly<FundraiserPageProps>) {
  const { logError } = useLogger()
  const { track } = useAnalytics()
  const { handleDonate, DonationFormWrapper } = useDonationForm('fundraiser')
  const [listenerExists, setListenerExists] = useState(false)
  const [animationActive, setAnimationActive] = useState(false)
  const [newDonationInfo, setNewDonationInfo] = useState<Readonly<Donation> | undefined>(undefined)
  const [processedDonationInfo, setProcessedDonationInfo] = useState<Readonly<ProcessedDonation> | undefined>(undefined)
  const [recentDonations, setRecentDonations] = useState<InfiniteData<Donation[]>>(mappedData)
  const [totals, setTotals] = useState<Totals>(fundraiserTotalsData)
  const { state } = useAuth()
  const { gsClient } = useClient()
  const { data: matching } = useMatchingBudget()
  const { t } = useTranslation()
  const { moneyFormat } = useMoneyFormat()
  const { refetch: refetchDonationSummary } = useDonationSummary()
  const { refetch: refetchBudget } = useMatchingBudget()
  const { refetch: refetchWallet } = useDonationWallet()
  const theme = useTheme()

  const user = getAccountFromAuthState(state)
  const partner = getPartnerFromAuthState(state)

  const allowedMessageOrigin = config.allowedMessageOrigins[environment as string]

  const addDonation = useCallback((donation: DonationCompletedMessage) => {
    const newDonationPseudoId = `donation_${new Date().getTime()}`

    const newDonation = {
      id: newDonationPseudoId,
      amount: {
        amount: donation.amount,
        currency: donation.currency
      },
      anonymous: donation.anonymous ? 'yes' : 'no',
      firstName: donation.firstName || user?.fullName?.split(' ').slice(0, -1).join(' ') || '',
      createdAt: donation.createdAt,
      matchedDonation: donation.matchedAmount
    } as const

    setNewDonationInfo(newDonation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mappedData) return
    setRecentDonations(donations => ({
      ...donations,
      ...mappedData
    }))
  }, [mappedData])

  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      if (!allowedMessageOrigin?.some(origin => ['*', event.origin].includes(origin))) return
      try {
        const eventData = event.data as {
          message: string
          data: DonationCompletedMessage
        }

        if (eventData.message === 'donationCompleted') {
          addDonation(eventData.data)

          track({
            event: 'Donation Organisation Donation Completed',
            properties: {
              origin: 'Fundraiser Details',
              currencyCode: eventData.data.currency,
              paymentProvider: eventData.data.paymentProvider,
              amount: eventData.data.amount
            }
          })
        }

        if (eventData.message === 'donationWidgetClosed') {
          setAnimationActive(true)
        }
      } catch (e) {
        logError(e)
      }
    }

    if (!listenerExists) {
      setListenerExists(true)

      window.addEventListener('message', handleMessageEvent)
    }

    return () => window.removeEventListener('message', handleMessageEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const convertAmount = async (amount: Money): Promise<Money | undefined> =>
      convertCurrency(gsClient, [
        '',
        {
          sourceCurrency: amount.currency,
          targetCurrency: fundraiserDetailsData.campaign.goal.currency,
          sourceMinorAmount: amount.amount
        }
      ]).then(v => v?.target)

    const process = async () => {
      if (newDonationInfo) {
        if (newDonationInfo.amount.currency === fundraiserDetailsData.campaign.goal.currency) {
          setProcessedDonationInfo({
            ...newDonationInfo,
            fundraiserAmount: newDonationInfo.amount,
            fundraiserMatchedAmount: newDonationInfo.matchedDonation
          })
          setNewDonationInfo(undefined)

          return
        }

        const fundraiserAmount = await convertAmount(newDonationInfo.amount)

        if (!fundraiserAmount) return

        setProcessedDonationInfo({
          ...newDonationInfo,
          fundraiserAmount,
          fundraiserMatchedAmount: newDonationInfo.matchedDonation
            ? newDonationInfo.matchedDonation.amount === newDonationInfo.amount.amount
              ? fundraiserAmount
              : (await convertAmount(newDonationInfo.matchedDonation)) ?? fundraiserAmount
            : undefined
        })
        setNewDonationInfo(undefined)
      }
    }
    process()
  }, [
    fundraiserDetailsData.campaign.goal.currency,
    gsClient,
    newDonationInfo,
    setNewDonationInfo,
    setProcessedDonationInfo
  ])

  useEffect(() => {
    if (animationActive && processedDonationInfo) {
      const newDonationAmount =
        processedDonationInfo.fundraiserAmount.amount + (processedDonationInfo.fundraiserMatchedAmount?.amount ?? 0)

      setTotals((totalData: Totals) => ({
        ...totalData,
        donationCount: totalData.donationCount + 1,
        totalAmount: {
          ...totalData.totalAmount,
          amount: totalData.totalAmount.amount + newDonationAmount
        }
      }))
      setRecentDonations({
        ...mappedData,
        pages: [[processedDonationInfo, ...(mappedData?.pages?.[0] ?? [])], ...(mappedData?.pages?.slice(1) ?? [])]
      })

      setTimeout(() => {
        refetchFundraiserTotalsData()
        refetchRecentDonationPageData()
        setProcessedDonationInfo(undefined)
        setAnimationActive(false)
        refetchDonationSummary()
        refetchBudget()
        refetchWallet()
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationActive, processedDonationInfo])

  const RecentDonationListComponent = (
    <RecentDonationsList
      loadMore={fetchNextPage}
      loading={dataLoading}
      showLoadMore={hasNextPage!}
      totalDonationText={
        fundraiserTotalsData.donationCount
          ? t('workplace_giving.fundraiser.recentDonations.titleTotal', {
              total: fundraiserTotalsData.donationCount
            })
          : t('workplace_giving.fundraiser.recentDonations.title')
      }
      seeMoreText={t('workplace_giving.common.seeMore')}
      errorText={dataError ? t('workplace_giving.fundraiser.recentDonations.error') : ''}
      noDonationText={
        fundraiserTotalsData.donationCount === 0 ? t('workplace_giving.fundraiser.recentDonations.noDonations') : ''
      }
    >
      {recentDonations.pages.flat(1).map(donation => (
        <RecentDonation
          donation={donation}
          key={donation.id}
          name={
            donation.anonymous === 'yes' || !donation.firstName
              ? t('workplace_giving.fundraiser.recentDonations.anonymous')
              : donation.firstName
          }
          matchedOriginal={
            donation.matchedDonation &&
            (t('workplace_giving.fundraiser.recentDonations.donationAmount', {
              amount: moneyFormat(donation.amount, { hideDecimalsIfZero: true })
            }) as string)
          }
          matchedBy={
            donation.matchedDonation &&
            (t('workplace_giving.fundraiser.recentDonations.matchedAmount', {
              amount: moneyFormat(donation.matchedDonation, { hideDecimalsIfZero: true }),
              partnerName: partner?.name
            }) as string)
          }
          totalAmount={moneyFormat(
            {
              amount: donation.amount.amount + (donation.matchedDonation?.amount || 0),
              currency: donation.amount.currency
            },
            { hideDecimalsIfZero: true }
          )}
        />
      ))}
    </RecentDonationsList>
  )

  return (
    <>
      {!isMobile && (
        <>
          <PageContent>
            <Description
              image={fundraiserDetailsData.imageUrl}
              name={fundraiserDetailsData.title}
              story={fundraiserDetailsData.story}
            />
            <Box sx={Styles.Divider} />
            {RecentDonationListComponent}
          </PageContent>
          <FundraiserSidePanel
            fundraiserDetailsData={fundraiserDetailsData}
            fundraiserTotalsData={totals}
            handleDonate={handleDonate}
            remainingAmount={matching?.remainingAmount}
          />
        </>
      )}
      {isMobile && (
        <Box sx={Styles.FundraiserMobilePage}>
          <HeroImage image={fundraiserDetailsData.imageUrl} />
          <FundraiserSidePanel
            fundraiserDetailsData={fundraiserDetailsData}
            fundraiserTotalsData={totals}
            handleDonate={handleDonate}
            remainingAmount={matching?.remainingAmount}
            isMobile
          />
          <TitleAndStory name={fundraiserDetailsData.title} story={fundraiserDetailsData.story} />
          {RecentDonationListComponent}
        </Box>
      )}
      <DonationFormWrapper
        params={{
          primaryColor: theme.palette.clientColor
        }}
      />
    </>
  )
}
