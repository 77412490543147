import { FocusEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormikErrors, FormikTouched } from 'formik'
import { NumberInput, MultiSelect } from '@mantine/core'
import { Alpha2Code } from 'i18n-iso-countries'
import { useDebounceCallback } from 'usehooks-ts'
import { identity } from 'lodash'
import { useLocation } from 'react-router-dom-v6'
import dayjs from 'dayjs'

import { Address, AddressLookup } from '@percent/workplace-giving/common/components/AddressLookup/AddressLookup'
import countries from '@percent/workplace-giving/i18n/countries'
import { getStyles } from './CreateOpportunity.styles'
import {
  Alert,
  AsyncSelect,
  Button,
  Card,
  Checkbox,
  Feedback,
  FileInput,
  FlagAvatar,
  FormField,
  Icon,
  Loader,
  Select,
  TextInput
} from '@percent/lemonade'
import { DateTimePicker, RichTextEditor } from '@percent/workplace-giving/common/components'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'
import { useQuery } from '@percent/workplace-giving/common/hooks'
import { searchOrganisations } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations'
import { Organisation } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations.types'
import { createShortLink } from '@percent/utility'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { config } from '@percent/workplace-giving/config/config'
import { OrganisationHeaderWithImage } from '@percent/domain/giving'
import { IconProps } from 'libs/shared/ui-lemonade/src/components/icon'
import {
  Opportunity,
  OpportunityLocationType,
  OpportunityType,
  ChildSessionEvent
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { LocaleKey } from '@percent/workplace-giving/i18n/config'
import { getVolunteeringActivities } from '@percent/workplace-giving/api/volunteering/getVolunteeringActivities/getVolunteeringActivities'
import { SelectOptionCard } from '@percent/workplace-giving/common/components/SelectOptionCard/SelectOptionCard'
import { CreateSessionEvent } from './SessionEvent/CreateSessionEvent'
import { SessionEventItem } from './SessionEvent/Item/SessionEventItem'
import { EditSessionEvent } from './SessionEvent/Edit/EditSessionEvent'
import { RemoveSessionEventModal } from './SessionEvent/ActionModals/RemoveSessionEventModal'
import { SessionEventItemLoader } from './SessionEvent/Item/SessionEventItemLoader'
import { FILE_SIZE_2MB_HUMAN } from '@percent/workplace-giving/constants/files'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export interface OpportunityFormFields {
  name: string
  description: string
  startDate?: Date
  endDate?: Date
  locationType: string
  locationUrl: string
  participantSpots?: number
  organisationCountry?: string
  organisationId?: string
  activities: string[]
  skills: string[]
  image?: string
  addressLineOne: string
  addressLineTwo: string
  city: string
  zipCode: string
  country: string
  long: number
  lat: number
  manualTimeTracking: boolean
  type: OpportunityType
  isSeries?: boolean
  childEvents: ChildSessionEvent[]
}

interface OpportunityFormProps {
  handleCoverImageChange: (file: File) => void
  coverImageError?: string
  values: OpportunityFormFields
  touched: FormikTouched<OpportunityFormFields>
  errors: FormikErrors<OpportunityFormFields>
  handleChange: (e: FormEvent<HTMLInputElement>) => void
  handleBlur: <A = HTMLInputElement>(e: FocusEvent<A>) => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<OpportunityFormFields>>
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<OpportunityFormFields>>
  opportunityData?: Opportunity
  validateField: (name: string) => Promise<void> | Promise<string | undefined>
  handleCreateSessionEvent: (newEvent: ChildSessionEvent) => void
  handleEditSessionEvent: (newEvent: ChildSessionEvent) => void
  handleRemoveSessionEvent: (id?: string) => void
  isLoadingSessions?: boolean
}

export function OpportunityForm({
  handleCoverImageChange,
  coverImageError,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  opportunityData,
  validateField,
  handleCreateSessionEvent,
  handleEditSessionEvent,
  handleRemoveSessionEvent,
  isLoadingSessions
}: Readonly<OpportunityFormProps>) {
  const routerLocation = useLocation()
  const { t, i18n } = useTranslation()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const [selectedOrgDetails, setSelectedOrgDetails] = useState<Organisation | undefined>(opportunityData?.organisation)
  const [selectIcon, setSelectIcon] = useState<{
    activitiesDropdownIcon: IconProps['name']
    skillsDropdownIcon: IconProps['name']
  }>({
    activitiesDropdownIcon: 'dropdown-arrow-down',
    skillsDropdownIcon: 'dropdown-arrow-down'
  })
  const [sessionEventFormOpen, setSessionEventFormOpen] = useState(false)
  const [selectedSeriesEvent, setSelectedSeriesEvent] = useState<ChildSessionEvent | undefined>(undefined)
  const [editSessionModalOpen, setEditSessionModalOpen] = useState(false)
  const [removeSessionModalOpen, setRemoveSessionModalOpen] = useState(false)

  const hideUneditableFields = routerLocation.pathname.includes('edit')

  useEffect(() => {
    if (opportunityData) {
      setSelectedOrgDetails(opportunityData.organisation)
    }
  }, [opportunityData])

  const eventLocationTypeOptions = useMemo(
    () => [
      {
        value: OpportunityLocationType.VIRTUAL,
        label: t('workplace_giving.wizard.opportunityType.virtual')
      },
      {
        value: OpportunityLocationType.OFFLINE,
        label: t('workplace_giving.wizard.opportunityType.inPerson')
      }
    ],
    [t]
  )

  const alpha3OrganisationCountryCodes = useMemo(
    () => [
      {
        value: '',
        label: t('workplace_giving.search.world'),
        prefix: <WorldFlagAvatar />
      },
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, 'en'),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    [t]
  )

  const alpha3CountryCodes = useMemo(
    () => [
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, 'en'),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    []
  )

  const defaultCountryValue = alpha3CountryCodes.filter(a => a.value === values.country)[0]
  const defaultOrgCountryValue = alpha3OrganisationCountryCodes.filter(a => a.value === values.country)[0]

  const resetOrgIdFieldAfterCountryChange = useCallback(async () => {
    await setFieldValue('organisationId', '')
    await setFieldTouched('organisationId', false, true)
    setSelectedOrgDetails(undefined)
  }, [setFieldTouched, setFieldValue])

  const [searchCountryCode, setSearchCountryCode] = useState<string>(defaultOrgCountryValue.value)
  const [query, setQuery] = useState<string>('')
  const debouncedSetQuery = useDebounceCallback(setQuery, 200)

  const { data: searchData, isFetching } = useQuery(
    ['searchNonProfits', { query, pageSize: 10, countryCode: searchCountryCode }],
    searchOrganisations,
    { enabled: !!query }
  )
  const shortLanguage = i18n.language.split('-')[0]

  const getOrganisationDescription = useCallback(
    (organisation: Organisation): string => {
      return [
        organisation.website ? createShortLink(organisation.website) : undefined,
        `${t('workplace_giving.common.id')}: ${organisation.registryId}`,
        countries.getName(organisation.countryCode, shortLanguage)
      ]
        .filter(identity)
        .join(' | ')
    },
    [shortLanguage, t]
  )

  const searchResults: SelectOption[] = useMemo(() => {
    return searchData
      ? searchData.data.map(organisation => ({
          value: organisation.id,
          label: organisation.name,
          description: getOrganisationDescription(organisation)
        }))
      : []
  }, [searchData, getOrganisationDescription])

  const skillsOptions = useMemo(() => {
    return config.skills
      .map(skill => ({
        value: skill.id,
        label: t(`workplace_giving.volunteering.skills.${skill.id}` as LocaleKey, {
          defaultValue: skill.name
        })
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [t])

  const { data: activitiesData, isLoading } = useQuery(['getVolunteeringActivities'], getVolunteeringActivities)

  const activityTagsOptions = useMemo(() => {
    if (activitiesData) {
      return activitiesData
        .map(activity => ({
          value: activity.id,
          label: activity.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }

    return []
  }, [activitiesData])

  useEffect(() => {
    if (searchData?.data && values.organisationId) {
      const selectedOrg = searchData.data.find(org => org.id === values.organisationId)
      setSelectedOrgDetails(selectedOrg)
    }
  }, [searchData?.data, values.organisationId])

  const [addressState, setAddressState] = useState<Address>({
    addressLineOne: undefined,
    addressLineTwo: undefined,
    city: undefined,
    zipCode: undefined,
    country: undefined
  })
  const addressFound = Object.values(addressState).filter(a => a !== undefined).length > 0

  useEffect(() => {
    if (hideUneditableFields) {
      setAddressState({
        addressLineOne: values.addressLineOne,
        addressLineTwo: values.addressLineTwo,
        city: values.city,
        zipCode: values.zipCode,
        country: values.country
      })
    }
  }, [])

  useEffect(() => {
    document.body.setAttribute('style', `overflow-y:${sessionEventFormOpen ? 'hidden' : 'auto'}`)
  })

  return (
    <Box sx={Styles.Form} component="form" onSubmit={event => event.preventDefault()}>
      <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.opportunityDetails')}</Typography>
      {hideUneditableFields ? null : (
        <Box sx={Styles.OptionsCardsBox}>
          <SelectOptionCard
            testId="eventTypeOpportunity"
            icon="volunteering-calendar"
            title={t('workplace_giving.volunteering.event')}
            description={t('workplace_giving.volunteering.eventDescription')}
            active={values.type === OpportunityType.EVENT}
            onClick={() => {
              setFieldValue('type', OpportunityType.EVENT)
            }}
          />
          <SelectOptionCard
            testId="projectTypeOpportunity"
            icon="calendar-refresh"
            title={t('workplace_giving.volunteering.project')}
            description={t('workplace_giving.volunteering.projectDescription')}
            active={values.type === OpportunityType.PROJECT}
            onClick={() => {
              setFieldValue('type', OpportunityType.PROJECT)
            }}
          />
        </Box>
      )}
      <FormField
        label={t('workplace_giving.wizard.coverImage.label')}
        necessity="required"
        status={coverImageError ? 'danger' : 'default'}
        statusMessage={coverImageError}
        data-testid="coverImageField"
      >
        <FileInput
          placeholder={t('workplace_giving.wizard.coverImage.placeholder', {
            acceptedFileFormats: config.acceptedBannerImgFormatsHuman,
            maxFileSize: FILE_SIZE_2MB_HUMAN
          })}
          accept={config.acceptedBannerImgFormats}
          dataTestId="coverImageInput"
          onChange={file => handleCoverImageChange(file)}
          showPreview
          initialImage={values?.image}
        />
      </FormField>

      <Alert variant="info">{t('workplace_giving.wizard.coverImage.description')}</Alert>

      <FormField
        label={t('workplace_giving.wizard.name.label')}
        necessity="required"
        status={touched.name && errors.name ? 'danger' : 'default'}
        statusMessage={errors.name}
        data-testid="nameField"
      >
        <TextInput
          name="name"
          value={values.name}
          placeholder={t('workplace_giving.wizard.name.placeholder')}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FormField>

      <FormField
        label={t('workplace_giving.wizard.description.label')}
        necessity="required"
        status={touched.description && errors.description ? 'danger' : 'default'}
        statusMessage={errors.description}
        data-testid="descriptionField"
      >
        <RichTextEditor
          fieldName="description"
          error={!!errors.description && !!touched.description}
          placeholder={t('workplace_giving.wizard.description.placeholder')}
          content={values.description}
          handleBlur={e => {
            handleBlur(e)
            setFieldTouched('description')
          }}
          handleUpdate={(newValue: string) => setFieldValue('description', newValue)}
        />
      </FormField>

      {!values.isSeries && hideUneditableFields ? (
        <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.dateAndTime')}</Typography>
      ) : null}

      {!hideUneditableFields ? (
        <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.details')}</Typography>
      ) : null}

      {hideUneditableFields || values.type === OpportunityType.PROJECT ? null : (
        <Box sx={Styles.OptionsCardsBox}>
          <SelectOptionCard
            testId="singleEvent"
            title={t('workplace_giving.wizard.singleEvent.title')}
            description={t('workplace_giving.wizard.singleEvent.description')}
            active={!values.isSeries}
            onClick={() => {
              setFieldValue('isSeries', false)
            }}
          />
          <SelectOptionCard
            testId="eventSeries"
            title={t('workplace_giving.wizard.eventSeries.title')}
            description={t('workplace_giving.wizard.eventSeries.description')}
            active={!!values.isSeries}
            onClick={() => {
              setFieldValue('isSeries', true)
            }}
          />
        </Box>
      )}
      {values.type === OpportunityType.EVENT && values.isSeries ? (
        <>
          <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.sessions')}</Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {values.childEvents
              .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
              .map(event => (
                <SessionEventItem
                  testId={event.id}
                  sessionEvent={event}
                  key={event.id}
                  isEditFlow={hideUneditableFields}
                  onEdit={() => {
                    setSelectedSeriesEvent(event)
                    setEditSessionModalOpen(true)
                  }}
                  onRemove={() => {
                    setSelectedSeriesEvent(event)
                    setRemoveSessionModalOpen(true)
                  }}
                />
              ))}
          </Box>

          <CreateSessionEvent
            isOpen={sessionEventFormOpen}
            onClose={() => setSessionEventFormOpen(false)}
            onSubmit={handleCreateSessionEvent}
            isEditFlow={hideUneditableFields}
          />
          <EditSessionEvent
            sessionEvent={selectedSeriesEvent}
            isOpen={editSessionModalOpen}
            onClose={() => {
              setEditSessionModalOpen(false)
              setSelectedSeriesEvent(undefined)
            }}
            onSubmit={handleEditSessionEvent}
            isEditFlow={hideUneditableFields}
          />
          <RemoveSessionEventModal
            isOpen={removeSessionModalOpen}
            handleClose={() => {
              setSelectedSeriesEvent(undefined)
              setRemoveSessionModalOpen(false)
            }}
            handleSubmit={() => {
              handleRemoveSessionEvent(selectedSeriesEvent?.id)
            }}
            isEditFlow={hideUneditableFields}
          />

          {isLoadingSessions ? <SessionEventItemLoader /> : null}

          <Button
            data-testid="addNewSession"
            variant="secondary"
            onPress={() => {
              setSessionEventFormOpen(true)
              setFieldTouched('childEvents')
            }}
          >
            {t('workplace_giving.wizard.addNewSession')}
          </Button>

          {errors.childEvents && touched.childEvents ? (
            <Box mt={1}>
              <Feedback variant="critical">{errors.childEvents as string}</Feedback>
            </Box>
          ) : null}
        </>
      ) : (
        <>
          <Box sx={Styles.FlexWrapper}>
            <FormField
              label={t('workplace_giving.wizard.startDate')}
              necessity={values.type === OpportunityType.EVENT ? 'required' : 'optional'}
              status={touched.startDate && errors.startDate ? 'danger' : 'default'}
              statusMessage={errors.startDate}
              data-testid="startDateField"
              disabled={!!hideUneditableFields && !!dayjs(values.startDate).isBefore(dayjs())}
            >
              <DateTimePicker
                fieldName="startDate"
                value={values.startDate}
                minDate={dayjs().add(1, 'minutes').toDate()}
                handleBlur={handleBlur}
                handleChange={async date => {
                  setFieldValue('startDate', date)
                  setFieldTouched('startDate')
                }}
                error={!!errors.startDate && !!touched.startDate}
                disabled={!!hideUneditableFields && !!dayjs(values.startDate).isBefore(dayjs())}
                handleFieldClear={
                  values.type === OpportunityType.EVENT
                    ? undefined
                    : () => {
                        setFieldValue('startDate', '')
                      }
                }
              />
            </FormField>
            <FormField
              label={t('workplace_giving.wizard.endDate')}
              necessity={values.type === OpportunityType.EVENT ? 'required' : 'optional'}
              status={touched.endDate && errors.endDate ? 'danger' : 'default'}
              statusMessage={errors.endDate}
              data-testid="endDateField"
            >
              <DateTimePicker
                fieldName="endDate"
                value={values.endDate}
                minDate={dayjs(values.startDate || dayjs())
                  .add(5, 'minute')
                  .toDate()}
                handleBlur={handleBlur}
                handleChange={async date => {
                  setFieldValue('endDate', date)
                  setFieldTouched('endDate')
                }}
                error={!!errors.endDate && !!touched.endDate}
                handleFieldClear={
                  values.type === OpportunityType.EVENT
                    ? undefined
                    : () => {
                        setFieldValue('endDate', '')
                      }
                }
              />
            </FormField>
          </Box>
          {values.type === OpportunityType.PROJECT ? (
            <Alert variant="info" title={t('workplace_giving.opportunity_wizard.manualTimeTrackingForProjectInfo')} />
          ) : null}
          {hideUneditableFields || values.type === OpportunityType.PROJECT ? null : (
            <Box mt={3}>
              <Checkbox
                name="manualTimeTracking"
                value={values.manualTimeTracking ? 'true' : undefined}
                active={values.manualTimeTracking}
                onChange={e => setFieldValue('manualTimeTracking', e.currentTarget.checked)}
                label={
                  <Box>
                    <Typography sx={Styles.TimeTrackingLabel}>
                      {t('workplace_giving.opportunity_wizard.manualTimeTracking.label')}
                    </Typography>
                    <Typography sx={Styles.TimeTrackingDescription}>
                      {t('workplace_giving.opportunity_wizard.manualTimeTracking.description')}
                    </Typography>
                  </Box>
                }
                variant="default"
              />
            </Box>
          )}
          <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.location')}</Typography>
          <FormField
            necessity="required"
            label={t('workplace_giving.wizard.opportunityType.label')}
            status={touched.locationType && errors.locationType ? 'danger' : 'default'}
            statusMessage={errors.locationType}
            data-testid="eventTypeField"
          >
            <Select
              defaultValue={eventLocationTypeOptions.find(x => x.value === values.locationType)}
              status={errors.locationType ? 'danger' : 'default'}
              placeholder={t('workplace_giving.wizard.opportunityType.placeholder')}
              options={eventLocationTypeOptions}
              onChange={event => setFieldValue('locationType', event.value)}
            />
          </FormField>

          {values.locationType === OpportunityLocationType.VIRTUAL && (
            <FormField
              label={t('workplace_giving.wizard.addVideoConferencing.label')}
              necessity="optional"
              status={touched.locationUrl && errors.locationUrl ? 'danger' : 'default'}
              statusMessage={errors.locationUrl}
              data-testid="locationUrlField"
            >
              <TextInput
                name="locationUrl"
                value={values.locationUrl}
                placeholder={t('workplace_giving.wizard.addVideoConferencing.placeholder')}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
          )}
          {values.locationType === OpportunityLocationType.OFFLINE && (
            <>
              <FormField label="Lookup location">
                <AddressLookup
                  onSelect={address => {
                    const requiredFields = ['addressLineOne', 'city', 'country', 'zipCode']
                    setAddressState(address)
                    setFieldValue('addressLineOne', address.addressLineOne ?? '')
                    setFieldValue('addressLineTwo', address.addressLineTwo ?? '')
                    setFieldValue('city', address.city ?? '')

                    if (address.country) {
                      const countryMatch = alpha3CountryCodes.find(a => a.label === address.country)

                      if (countryMatch) {
                        setFieldValue('country', countryMatch.value)
                        setSearchCountryCode(countryMatch.value)
                        resetOrgIdFieldAfterCountryChange()
                      }
                    }

                    setFieldValue('zipCode', address.zipCode ?? '')

                    if (address.lat) {
                      setFieldValue('lat', address.lat)
                    }

                    if (address.long) {
                      setFieldValue('long', address.long)
                    }

                    setTimeout(async () => {
                      requiredFields.forEach(field => setFieldTouched(field, true))
                    }, 1)
                    setTimeout(async () => {
                      await Promise.all(['addressLineOne', 'city', 'country', 'zipCode'].map(a => validateField(a)))
                    }, 1)
                  }}
                />
              </FormField>
              {addressFound && (
                <>
                  <Box sx={Styles.AddressLinesWrapper}>
                    <FormField
                      label={t('workplace_giving.wizard.streetAddress.label')}
                      necessity="required"
                      status={touched.addressLineOne && errors.addressLineOne ? 'danger' : 'default'}
                      statusMessage={errors.addressLineOne}
                      data-testid="addressLineOneField"
                    >
                      <TextInput
                        name="addressLineOne"
                        value={values.addressLineOne}
                        placeholder={t('workplace_giving.wizard.streetAddress.placeholder')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormField>
                    <FormField
                      label={t('workplace_giving.wizard.apartment.label')}
                      necessity="optional"
                      status={touched.addressLineTwo && errors.addressLineTwo ? 'danger' : 'default'}
                      statusMessage={errors.addressLineTwo}
                      data-testid="addressLineTwoField"
                    >
                      <TextInput
                        name="addressLineTwo"
                        value={values.addressLineTwo}
                        placeholder={t('workplace_giving.wizard.apartment.placeholder')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormField>
                  </Box>
                  <Box sx={Styles.AddressLinesWrapper}>
                    <FormField
                      label={t('workplace_giving.wizard.city.label')}
                      necessity="required"
                      status={touched.city && errors.city ? 'danger' : 'default'}
                      statusMessage={errors.city}
                      data-testid="cityField"
                      disabled={!!addressState.city}
                    >
                      <TextInput
                        name="city"
                        value={values.city}
                        placeholder={t('workplace_giving.wizard.city.placeholder')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormField>
                    <FormField
                      label={t('workplace_giving.wizard.country.label')}
                      necessity="required"
                      status={touched.country && errors.country ? 'danger' : 'default'}
                      statusMessage={errors.country}
                      data-testid="countryField"
                      disabled={!!addressState.country}
                    >
                      <Select
                        placeholder={t('workplace_giving.wizard.country.placeholder')}
                        status={errors.country ? 'danger' : 'default'}
                        options={alpha3CountryCodes}
                        defaultValue={defaultCountryValue}
                        onChange={event => {
                          setFieldValue('country', event.value)
                        }}
                        searchable
                      />
                    </FormField>
                    <FormField
                      label={t('workplace_giving.wizard.zipOrPostalCode.label')}
                      necessity="required"
                      status={touched.zipCode && errors.zipCode ? 'danger' : 'default'}
                      statusMessage={errors.zipCode}
                      data-testid="zipCodeField"
                      disabled={!!addressState.zipCode}
                    >
                      <TextInput
                        name="zipCode"
                        value={values.zipCode}
                        placeholder={t('workplace_giving.wizard.zipOrPostalCode.placeholder')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormField>
                  </Box>
                </>
              )}
            </>
          )}

          <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.attendees')}</Typography>
          <FormField
            label={t('workplace_giving.wizard.maxAttendees.label')}
            necessity="optional"
            status={touched.participantSpots && errors.participantSpots ? 'danger' : 'default'}
            statusMessage={errors.participantSpots}
            data-testid="participantSpotsField"
          >
            <NumberInput
              style={{ width: '100%' }}
              name="participantSpots"
              min={1}
              step={1}
              hideControls
              placeholder={t('workplace_giving.wizard.maxAttendees.placeholder')}
              value={values.participantSpots}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('participantSpots', value)
              }}
              error={!!errors.participantSpots && !!touched.participantSpots}
            />
          </FormField>
        </>
      )}

      {!hideUneditableFields || (values.organisationId && selectedOrgDetails) ? (
        <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.supportedNonprofit')}</Typography>
      ) : null}
      {!hideUneditableFields ? (
        <>
          <FormField
            necessity="optional"
            label={t('workplace_giving.wizard.nonprofitCountry.label')}
            description={t('workplace_giving.wizard.nonprofitCountry.description')}
            status={touched.organisationCountry && errors.organisationCountry ? 'danger' : 'default'}
            statusMessage={errors.organisationCountry}
            data-testid="organisationCountryField"
          >
            <Select
              status={errors.organisationCountry ? 'danger' : 'default'}
              placeholder={t('workplace_giving.wizard.nonprofitCountry.placeholder')}
              options={alpha3OrganisationCountryCodes}
              defaultValue={defaultOrgCountryValue}
              onChange={event => {
                setFieldValue('organisationCountry', event.value)
                setSearchCountryCode(event.value)
                resetOrgIdFieldAfterCountryChange()
              }}
              searchable
              showPrefixForSelectedOption
            />
          </FormField>

          <FormField
            necessity="optional"
            label={t('workplace_giving.wizard.selectNonprofit.label')}
            status={touched.organisationId && errors.organisationId && !query ? 'danger' : 'default'}
            statusMessage={errors.organisationId}
            data-testid="organisationSearchField"
          >
            <AsyncSelect
              status={errors.organisationId && !query ? 'danger' : 'default'}
              name="organisationId"
              key={searchCountryCode}
              placeholder={t('workplace_giving.wizard.selectNonprofit.placeholder')}
              onChange={e => {
                setFieldValue('organisationId', e?.value ?? '')
                setFieldTouched('organisationId')
              }}
              options={searchResults}
              setQuery={e => {
                debouncedSetQuery(e)
                setFieldTouched('organisationId')
              }}
              query={query}
              loading={isFetching}
              loadingText={t('workplace_giving.donationMatchRequest.organisationSearch.loading')}
              noResultsFoundText={
                query
                  ? t('workplace_giving.donationMatchRequest.organisationSearch.noResults')
                  : t('workplace_giving.donationMatchRequest.organisationSearch.emptyQuery')
              }
              data-testid="organisationSearch"
            />
          </FormField>
        </>
      ) : null}

      {selectedOrgDetails && values.organisationId ? (
        <Card>
          <Box paddingX={3} paddingTop={3} width="100%" data-testid="organisationDetailsPreview">
            <OrganisationHeaderWithImage
              title={selectedOrgDetails.displayName || selectedOrgDetails.name}
              countryCode={selectedOrgDetails.countryCode}
              registryId={selectedOrgDetails.registryId}
              iconUrl={selectedOrgDetails.logo}
              website={selectedOrgDetails.website}
            />
          </Box>
        </Card>
      ) : null}

      <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.activityAndSkillsTags')}</Typography>

      {isLoading ? (
        <Loader />
      ) : (
        <FormField
          label={t('workplace_giving.wizard.activityTags.label')}
          necessity="optional"
          data-testid="activitiesField"
        >
          <MultiSelect
            style={{ width: '100%' }}
            placeholder={!values.activities.length ? t('workplace_giving.wizard.activityTags.placeholder') : ''}
            searchable
            data={activityTagsOptions}
            value={values.activities}
            onChange={selectedValues => {
              setFieldValue('activities', selectedValues)
            }}
            rightSection={<Icon name={selectIcon.activitiesDropdownIcon} size={8} color="gray600" />}
            onDropdownOpen={() => {
              setSelectIcon({ ...selectIcon, activitiesDropdownIcon: 'dropdown-arrow-up' })
            }}
            onDropdownClose={() => {
              setSelectIcon({ ...selectIcon, activitiesDropdownIcon: 'dropdown-arrow-down' })
            }}
          />
        </FormField>
      )}

      <FormField label={t('workplace_giving.wizard.skills.label')} necessity="optional" data-testid="skillsField">
        <MultiSelect
          style={{ width: '100%' }}
          placeholder={!values.skills.length ? t('workplace_giving.wizard.skills.placeholder') : ''}
          searchable
          data={skillsOptions}
          value={values.skills}
          onChange={selectedValues => {
            setFieldValue('skills', selectedValues)
          }}
          rightSection={<Icon name={selectIcon.skillsDropdownIcon} size={8} color="gray600" />}
          onDropdownOpen={() => {
            setSelectIcon({ ...selectIcon, skillsDropdownIcon: 'dropdown-arrow-up' })
          }}
          onDropdownClose={() => {
            setSelectIcon({ ...selectIcon, skillsDropdownIcon: 'dropdown-arrow-down' })
          }}
        />
      </FormField>
    </Box>
  )
}
