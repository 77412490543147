import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: `${lemonadeTheme.sizes[4]} 0`,
    borderBottom: `1px solid ${customPalette.gray90}`
  },
  IconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: lemonadeTheme.sizes[10],
    minWidth: lemonadeTheme.sizes[10],
    height: lemonadeTheme.sizes[10],
    marginRight: lemonadeTheme.sizes[4],
    backgroundColor: customPalette.gray70,
    borderRadius: lemonadeTheme.borderRadii.small
  },
  Title: {
    fontSize: lemonadeTheme.sizes[4],
    lineHeight: lemonadeTheme.sizes[6],
    fontWeight: lemonadeTheme.fontWeights.medium
  },
  Content: {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes[6],
    color: customPalette.gray600
  }
})
