import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { DonationForm, DonationFormProps } from '@percent/workplace-giving/common/components'
import {
  getAccountConfigFromAuthState,
  getPartnerFromAuthState,
  shouldCheckLoggedIn
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { createDonationSession } from '@percent/workplace-giving/api/donation/create-donation-session/create-donation-session'
import { createFundraiserDonationSession } from '@percent/workplace-giving/api/donation/create-fundraiser-donation-session/create-fundraiser-donation-session'

type DonationType = 'regular' | 'fundraiser'

export const useDonationForm = (type: DonationType) => {
  const { state, dispatch } = useAuth()
  const partner = getPartnerFromAuthState(state)!
  const accountConfig = getAccountConfigFromAuthState(state)
  const {
    i18n: { language: locale }
  } = useTranslation()

  const [open, setIsOpen] = useState(false)
  const [url, setUrl] = useState('')

  const regularMutation = useMutation({
    mutationFn: createDonationSession
  })

  const fundraiserMutation = useMutation({
    mutationFn: createFundraiserDonationSession
  })

  // Select the appropriate mutation based on type
  const { isLoading } = type === 'fundraiser' ? fundraiserMutation : regularMutation

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setUrl('')
  }, [])

  const handleDonate = useCallback(
    async (id: string) => {
      if (!shouldCheckLoggedIn()) {
        dispatch({ type: 'LOGOUT' })

        return
      }

      if (accountConfig?.currency || partner.currencyCode) {
        setIsOpen(true)

        const response =
          type === 'fundraiser'
            ? await fundraiserMutation.mutateAsync({
                donationLinkReference: id,
                currency: accountConfig?.currency ?? partner.currencyCode,
                language: locale
              })
            : await regularMutation.mutateAsync({
                organisationId: id,
                currency: accountConfig?.currency ?? partner.currencyCode
              })

        setUrl(response.data.url)
      }
    },
    [accountConfig?.currency, partner.currencyCode, regularMutation, fundraiserMutation, type, dispatch, locale]
  )
  const DonationFormWrapper = useCallback(
    (props: Omit<DonationFormProps, 'loading' | 'open' | 'url' | 'onClose'>) => (
      <DonationForm {...props} loading={isLoading} open={open} url={url} onClose={handleClose} />
    ),
    [isLoading, open, url, handleClose]
  )

  return useMemo(
    () => ({
      DonationFormWrapper,
      handleDonate
    }),
    [DonationFormWrapper, handleDonate]
  )
}
