import { useTranslation } from 'react-i18next'

import { Menu } from '@percent/lemonade'

type MyFundraiserActionsMenuProps = {
  handleSelect: (key: string) => void
  status: string
}

export function MyFundraiserActionsMenu({ handleSelect, status }: MyFundraiserActionsMenuProps) {
  const { t } = useTranslation()
  const items = [{ key: 'view', label: t('workplace_giving.myFundraiser.actionsMenu.view') }]

  if (status === 'published') {
    items.push({ key: 'edit', label: t('workplace_giving.myFundraiser.actionsMenu.edit') })
    items.push({ key: 'close', label: t('workplace_giving.myFundraiser.actionsMenu.close') })
  }

  return (
    <Menu
      title={t('workplace_giving.myFundraiser.actionsMenu.more')}
      onSelect={key => handleSelect(key)}
      sections={[{ items }]}
    />
  )
}
