import { Styles } from '@percent/workplace-giving/types'
import { theme } from '@percent/lemonade'

export const BadgeWrapper: Styles = {
  position: 'relative',
  overflow: 'hidden',
  display: 'block',
  boxSizing: 'border-box',
  width: theme.sizes[12],
  height: theme.sizes[12],
  borderRadius: '100%',

  img: {
    display: 'block',
    width: theme.sizes[12],
    height: theme.sizes[12],
    minWidth: theme.sizes[12],
    minHeight: theme.sizes[12],
    borderRadius: '100%'
  }
}

export const LockedBadgeCover: Styles = {
  zIndex: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  background: 'transparent',
  opacity: 1,
  border: `1px solid ${theme.colors.primary100}`,

  '& > svg': {
    opacity: 0.5
  },

  '&::before': {
    zIndex: -1,
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.colors.white,
    borderRadius: '100%',
    opacity: 0.8
  }
}
