import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

const DetailsText = (customPalette: ColorPaletteType) => ({
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: lemonadeTheme.sizes[6],
  color: customPalette.gray600
})

export const getStyles = (customPalette: ColorPaletteType) => ({
  LocationLabelWrapper: {
    maxHeight: '24px !important',
    width: 'fit-content',
    padding: '4px 10px',
    backgroundColor: customPalette.gray90,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',

    svg: {
      height: '20px',
      width: '20px'
    }
  },
  Name: {
    fontWeight: lemonadeTheme.fontWeights.semiBold,
    fontSize: lemonadeTheme.sizes[10],
    lineHeight: lemonadeTheme.sizes[12]
  },
  DetailsText: DetailsText(customPalette),
  Role: {
    ...DetailsText(customPalette),
    fontWeight: lemonadeTheme.fontWeights.medium
  }
})
