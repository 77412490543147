import { Styles } from '@percent/workplace-giving/types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const Wrapper: Styles = {
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: lemonadeTheme.sizes[6]
}

export const Title: Styles = {
  fontSize: lemonadeTheme.sizes[5],
  lineHeight: lemonadeTheme.sizes[6],
  fontWeight: lemonadeTheme.fontWeights.semiBold
}

export const ButtonsWrapper: Styles = {
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  gap: lemonadeTheme.sizes[6]
}
