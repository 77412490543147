import { Typography, Box, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AchievementBadge } from '../AchievementBadge/AchievementBadge'
import { useColorTheme } from '../../hooks/useColorTheme/useColorTheme'

import { getStyles } from './GamificationBadges.styles'
import { theme, Tooltip } from '@percent/lemonade'
import { useQuery } from '@percent/workplace-giving/common/hooks'
import { getAccountAchievements } from '@percent/workplace-giving/api/account/achievements/achievements'

export function GamificationBadges() {
  const { t } = useTranslation()
  const { data, status } = useQuery(['get'], getAccountAchievements)
  const { theme: colorTheme } = useColorTheme()
  const Styles = getStyles(colorTheme)

  if (status === 'loading' || status === 'error' || !data)
    return (
      <div data-testid="gamification-badges-container-loader">
        <Skeleton variant="circular" height={theme.sizes[12]} width={theme.sizes[12]} />
      </div>
    )

  return (
    <Box sx={Styles.AchievementsBox} data-testid="gamification-badges-container">
      {data.rulesAndAchievements.map(({ accountAchievement, achievement: { name, image }, rule }) => {
        const ruleConditionsPassed = accountAchievement || rule.conditions.pass

        return (
          <Tooltip
            key={`${name}-tooltip`}
            content={
              <Box paddingY={1} paddingX={0.5}>
                {ruleConditionsPassed ? (
                  <Typography sx={Styles.AchievementName}>
                    {t('workplace_giving.gamification.youEarnedBadge', { badgeName: name })}
                  </Typography>
                ) : (
                  <Typography sx={Styles.AchievementName}>{name}</Typography>
                )}
                <Typography sx={Styles.AchievementStepsDescription}>{rule.conditions.description}</Typography>
                <Box component="ul" sx={Styles.StepsList}>
                  {rule.conditions.conditions.map(({ pass, factKind, operator, description }) => (
                    <Typography component="li" sx={Styles.StepsListItem(pass)} key={`${operator}-${factKind}`}>
                      {description}
                    </Typography>
                  ))}
                </Box>
              </Box>
            }
            placement="bottom-start"
            lightBg
          >
            <div data-testid="achievement-tooltip-trigger">
              <AchievementBadge image={image} name={name} isBlocked={!ruleConditionsPassed} />
            </div>
          </Tooltip>
        )
      })}
    </Box>
  )
}
