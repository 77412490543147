import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'
import { Alpha3Code } from 'i18n-iso-countries'

import defaultOrgLogo from '../../../assets/icons/placeholder-logo.svg'

import { FundraiserSidePanelProps } from './FundraiserSidePanel.types'
import { Alert, Button, ButtonText } from '@percent/lemonade'
import { SidePanelContainer } from '@percent/workplace-giving/common/components/Layout/SidePanelContainer/SidePanelContainer'
import { FundraiserDetails, OrganizerInfo } from '@percent/domain/fundraiser'
import { SidePanelWrapper } from '@percent/workplace-giving/common/components/Layout/SidePanelWrapper/SidePanelWrapper'
import { getStyles } from './FundraiserSidePanel.styles'
import { CopyContentButton } from '@percent/domain/giving'
import {
  getAccountFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { TooltipTaxInformation } from '@percent/workplace-giving/common/components/TooltipTaxInformation/TooltipTaxInformation'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function FundraiserSidePanel({
  fundraiserDetailsData,
  fundraiserTotalsData,
  isMobile,
  handleDonate,
  remainingAmount
}: Readonly<FundraiserSidePanelProps>) {
  const { t } = useTranslation()
  const { state } = useAuth()
  const partner = getPartnerFromAuthState(state)!
  const {
    organisation: { name, logo, website },
    campaign: {
      goal: { amount: goalAmount },
      endDate
    },
    organiser
  } = fundraiserDetailsData
  const {
    donationCount,
    totalAmount: { amount, currency }
  } = fundraiserTotalsData

  const { state: authState } = useAuth()
  const account = getAccountFromAuthState(authState)
  const navigate = useNavigate()
  const { track } = useAnalytics()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <SidePanelContainer>
      <SidePanelWrapper>
        <Box sx={Styles.FundraiserDetailsWrapper}>
          {remainingAmount?.amount !== 0 && fundraiserDetailsData.status !== 'closed' && (
            <Box sx={Styles.MatchingInfoWrapper}>
              <Box sx={Styles.Multiplier}>
                <span>{t('workplace_giving.fundraiser.multiplier')}</span>
              </Box>
              <Typography>
                {t('workplace_giving.fundraiser.sidepanel.donationMatch.title', {
                  organiserName: partner?.name
                })}
              </Typography>
            </Box>
          )}
          {fundraiserDetailsData.status === 'closed' && (
            <Alert variant="info" title={t('workplace_giving.fundraiser.closed')} />
          )}
          <FundraiserDetails
            currentAmount={amount}
            goalAmount={goalAmount}
            currency={currency}
            donations={donationCount}
            endDate={endDate}
            showIcons={false}
          />
        </Box>
        {fundraiserDetailsData.status !== 'closed' && (
          <>
            <Box sx={Styles.DonateShareBtn}>
              <Button
                onPress={() => {
                  track({
                    event: 'Donation Organisation Donation Start',
                    properties: {
                      origin: 'Fundraiser Details',
                      currencyCode: currency,
                      organiserType: organiser.type,
                      countryCode: fundraiserDetailsData.organisation.countryCode
                    }
                  })
                  handleDonate(fundraiserDetailsData.reference)
                }}
                stretch
              >
                {t('workplace_giving.common.donate')}
              </Button>
              <CopyContentButton
                stretch
                content={window.location.href}
                tooltipLabel="Fundraiser url copied"
                onPress={() => {
                  track({
                    event: 'Donation Fundraiser Link Sharing',
                    properties: {
                      currencyCode: currency,
                      organiserType: organiser.type,
                      organisationCountryCode: fundraiserDetailsData.organisation.countryCode
                    }
                  })
                }}
              >
                {isMobile ? t('workplace_giving.common.copy') : t('workplace_giving.common.copyShareLink')}
              </CopyContentButton>
            </Box>
            <OrganizerInfo logo={logo || defaultOrgLogo} name={name} website={website} topBorder />
          </>
        )}
      </SidePanelWrapper>
      <Box sx={Styles.CardContentWrapper}>
        <Box display="flex" mr="1rem" justifyContent="space-between">
          <Typography sx={Styles.OrganiserInfo}>
            {organiser?.id === account?.id
              ? t('workplace_giving.sidepanel.organisedByYou')
              : `${t('workplace_giving.sidepanel.organisedBy')} ${organiser?.name || partner.name}`}
          </Typography>
          {organiser?.id === account?.id && (
            <ButtonText
              onPress={() => {
                track({
                  event: 'Donation Fundraiser Edit Start',
                  properties: {
                    origin: 'Fundraiser Details'
                  }
                })
                navigate(AppRoute.EDIT_FUNDRAISER.replace(':fundraiserId', fundraiserDetailsData.id))
              }}
              data-testid="fundraiser-edit-button"
            >
              {t('workplace_giving.common.edit')}
            </ButtonText>
          )}
        </Box>
        <TooltipTaxInformation
          orgCountryCode={fundraiserDetailsData.organisation.countryCode as Alpha3Code}
          placement="bottom-start"
        />
      </Box>
    </SidePanelContainer>
  )
}
