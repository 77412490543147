import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Title: {
    marginBottom: lemonadeTheme.sizes.s,
    color: customPalette.gray200,
    fontSize: lemonadeTheme.fontSizes.h5,
    fontWeight: lemonadeTheme.fontWeights.semiBold,
    lineHeight: lemonadeTheme.sizes[8]
  },
  SupportedByColleaguesInfo: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    marginTop: lemonadeTheme.sizes.m,
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes.m,
    color: customPalette.gray600,

    span: {
      marginX: lemonadeTheme.sizes.xxs,
      fontWeight: lemonadeTheme.fontWeights.semiBold
    }
  },
  IconWrapper: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px !important',
    height: '24px !important',
    borderRadius: '100%',
    background: `linear-gradient(to bottom right,${customPalette.primary400}, ${customPalette.info400})`,
    marginRight: lemonadeTheme.sizes.xs,

    span: {
      marginX: 0,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20px',
      height: '20px',
      fontSize: '10px',
      fontWeight: 800,
      backgroundColor: 'rgb(255, 255, 255)',
      borderRadius: '100%'
    }
  }
})
