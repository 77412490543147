import { Box, Skeleton } from '@mui/material'

import { getStyles } from './OpportunityDetailsSidebar.styles'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

type OpportunityDetailsSidebarSkeleton = {
  alertSkeleton: boolean
}

export function OpportunityDetailsSidebarSkeleton({ alertSkeleton }: OpportunityDetailsSidebarSkeleton) {
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <Box
      sx={Styles.SignUpContainer}
      style={{ height: 'fit-content' }}
      data-testid="opportunity-details-sidebar-skeleton"
    >
      <Box display="flex" flexDirection="column" gap={2}>
        {alertSkeleton && (
          <Box display="flex" flexDirection="column" gap={2} mb={3}>
            <Skeleton variant="rectangular" height={64} />
            <Skeleton variant="rectangular" height={24} />
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" gap={2}>
            <Skeleton variant="rectangular" height={16} width={16} style={{ borderRadius: 2 }} />
            <Skeleton variant="rectangular" height={24} width={280} />
          </Box>
          <Box display="flex" gap={2}>
            <Skeleton variant="rectangular" height={16} width={16} style={{ borderRadius: 2 }} />
            <Skeleton variant="rectangular" height={24} width={280} />
          </Box>
          <Box display="flex" gap={2}>
            <Skeleton variant="rectangular" height={16} width={16} style={{ borderRadius: 2 }} />
            <Skeleton variant="rectangular" height={24} width={280} />
          </Box>
        </Box>
        <Skeleton variant="rectangular" height={48} />
        <Skeleton variant="rectangular" height={48} />
      </Box>
    </Box>
  )
}
