import { useState, useEffect, useCallback } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom-v6'
import { InfiniteData } from '@tanstack/react-query'

import { getStyles } from '../CreateOpportunity.styles'
import { OpportunityForm } from '../OpportunityForm'

import { FullPageFormLayout, Loader, useToast } from '@percent/lemonade'
import { WizardHeader } from '@percent/workplace-giving/common/components/WizardHeader/WizardHeader'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import {
  ChildSessionEvent,
  Opportunity,
  OpportunityLocationType,
  OpportunityType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { UnexpectedErrorModal } from '@percent/workplace-giving/common/components/UnexpectedErrorModal/UnexpectedErrorModal'
import { OpportunityFormCancellationModal } from '@percent/workplace-giving/app/Volunteer/OpportunityForm/OpportunityFormCancellationModal'
import { OpportunityFormSuccessModal } from '@percent/workplace-giving/app/Volunteer/OpportunityForm/OpportunityFormSuccessModal'
import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { useAuth, useInfiniteQuery, useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { getOpportunityDetails } from '@percent/workplace-giving/api/opportunity/getOpportunityDetails'
import {
  getAccountFromAuthState,
  getCountryCodeFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useEditOpportunityForm } from './useEditOpportunityForm'
import { getOpportunities } from '@percent/workplace-giving/api/opportunity/getOpportunities/getOpportunities'
import { cancelOpportunity } from '@percent/workplace-giving/api/opportunity/cancel/cancel-opportunity'
import { createOpportunity } from '@percent/workplace-giving/api/opportunity/create/create-opportunity'
import { editOpportunityDetails } from '@percent/workplace-giving/api/opportunity/edit/edit-opportunity-details'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function EditOpportunity() {
  const { logError } = useLogger()
  const navigate = useNavigate()
  const { state } = useAuth()
  const userAcc = getAccountFromAuthState(state)!
  const defaultCountry = getCountryCodeFromAuthState(state)!
  const { opportunityId } = useParams()
  const { t } = useTranslation()
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const [openDetailsFetchingError, setOpenDetailsFetchingError] = useState<boolean>(false)
  const [openCancellationModal, setOpenCancellationModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const { addToast } = useToast()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const handleCloseCancellationModal = () => setOpenCancellationModal(prevState => !prevState)

  const {
    data: opportunityData,
    isSuccess,
    isLoading,
    isError,
    error
  } = useQuery([`getOpportunityDetails`, opportunityId as string], getOpportunityDetails, {
    enabled: !!opportunityId
  })

  useEffect(() => {
    if (!isLoading && opportunityData && (opportunityData.external || opportunityData.organiser?.id !== userAcc.id)) {
      navigate(-1)
    }
  }, [navigate, opportunityData, userAcc, isLoading])

  const {
    data: sessionsData,
    isFetching,
    isError: sessionsDataFetchingError,
    error: sessionsDataError,
    refetch,
    isRefetching
  } = useInfiniteQuery(['getOpportunities', { parentId: opportunityId, pageSize: 100 }], getOpportunities, {
    enabled: !!opportunityId && !!opportunityData?.isSeries
  })

  const mappedData = useCallback(
    () =>
      sessionsData && {
        ...sessionsData,
        pages: sessionsData.pages?.map(page => page.data)
      },
    [sessionsData]
  )

  const mapSessionsToChildEvents = (sessions: InfiniteData<Opportunity[]> | undefined) => {
    const childEvents: ChildSessionEvent[] = []
    sessions?.pages.flat(1).map(session =>
      childEvents.push({
        ...session,
        startDate: new Date(session.startDate!),
        endDate: new Date(session.endDate!),
        participantSpots: session?.participants?.spots
      })
    )

    return childEvents
  }

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateField,
    handleSubmit,
    dirty,
    isSubmitting,
    formValid,
    coverImage,
    coverImageError,
    handleCoverImageChange,
    setInitialFormValues
  } = useEditOpportunityForm({
    onSuccess: () => {
      return setOpenSuccessModal(true)
    },
    onError: () => setOpenErrorModal(true),
    initialOpportunityData: opportunityData
  })

  useEffect(() => {
    if ((isError && error) || (sessionsDataFetchingError && sessionsDataError)) {
      logError(error || sessionsDataError)
      setOpenDetailsFetchingError(true)
    }
  }, [error, isError, logError, sessionsDataError, sessionsDataFetchingError])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isSuccess) {
      return setInitialFormValues({
        type: opportunityData.type === OpportunityType.PROJECT ? OpportunityType.PROJECT : OpportunityType.EVENT,
        image: opportunityData.image,
        name: opportunityData.name,
        description: opportunityData?.description || '',
        startDate: opportunityData?.startDate ? new Date(opportunityData.startDate) : undefined,
        endDate: opportunityData?.endDate ? new Date(opportunityData.endDate) : undefined,
        locationType: opportunityData.location.type || '',
        locationUrl:
          opportunityData.location.type === OpportunityLocationType.VIRTUAL
            ? opportunityData?.location?.link || ''
            : '',
        participantSpots: opportunityData.spots || undefined,
        organisationCountry: opportunityData?.organisation?.countryCode,
        organisationId: opportunityData?.organisation?.id || '',
        activities: opportunityData?.activities?.map(activity => activity.id) || [],
        skills: opportunityData?.skills?.map(skill => skill.id) || [],
        addressLineOne:
          opportunityData.location.type === OpportunityLocationType.OFFLINE
            ? opportunityData.location.addressLineOne || ''
            : '',
        addressLineTwo:
          opportunityData.location.type === OpportunityLocationType.OFFLINE
            ? opportunityData.location.addressLineTwo || ''
            : '',
        city:
          opportunityData.location.type === OpportunityLocationType.OFFLINE ? opportunityData.location.city || '' : '',
        country:
          opportunityData.location.type === OpportunityLocationType.OFFLINE
            ? opportunityData.location.country || defaultCountry
            : '',
        zipCode:
          opportunityData.location.type === OpportunityLocationType.OFFLINE
            ? opportunityData.location.zipCode || ''
            : '',
        manualTimeTracking: false,
        lat: opportunityData.location.type === OpportunityLocationType.OFFLINE ? opportunityData.location.lat : 0,
        long: opportunityData.location.type === OpportunityLocationType.OFFLINE ? opportunityData.location.long : 0,
        isSeries: !!opportunityData.isSeries,
        childEvents: mapSessionsToChildEvents(mappedData())
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, opportunityData, opportunityId, defaultCountry])

  useEffect(() => {
    setFieldValue('childEvents', mapSessionsToChildEvents(mappedData()))
    setFieldTouched('childEvents')
  }, [mappedData, setFieldTouched, setFieldValue])

  useEffect(() => {
    validateField('childEvents')
  }, [validateField, values.childEvents])

  const { mutateAsync: cancelOpportunityMutation } = useMutation({
    mutationFn: cancelOpportunity,
    onSuccess: res => {
      if (okResponse(res)) {
        refetch()
        addToast(t('workplace_giving.cancelSession.success'), 'success')
      } else {
        logError(res.error)
        addToast(t('workplace_giving.cancelSession.error'), 'error')
      }
    }
  })

  const handleRemoveSessionEvent = async (sessionEventId?: string) => {
    if (sessionEventId) {
      try {
        await cancelOpportunityMutation({ id: sessionEventId })
      } catch (e) {
        logError(e)
        addToast(t('workplace_giving.cancelSession.error'), 'error')
      }
    }
  }

  const { mutateAsync: createOpportunityMutation } = useMutation({
    mutationFn: createOpportunity,
    onSuccess: res => {
      if (okResponse(res)) {
        refetch()
        addToast(t('workplace_giving.createSession.success'), 'success')
      } else {
        logError(res.error)
        addToast(t('workplace_giving.createSession.error'), 'error')
      }
    }
  })

  const handleCreateSessionEvent = async (newEvent: ChildSessionEvent) => {
    if (newEvent && opportunityData) {
      try {
        await createOpportunityMutation([
          {
            ...newEvent,
            type: OpportunityType.EVENT_SERIES_SESSION,
            parentId: opportunityData.id
          }
        ])
      } catch (e) {
        logError(e)
        addToast(t('workplace_giving.editSession.error'), 'error')
      }
    }
  }

  const { mutateAsync: updateOpportunityDetailsMutation } = useMutation({
    mutationFn: editOpportunityDetails,
    onSuccess: res => {
      if (okResponse(res)) {
        refetch()
        addToast(t('workplace_giving.editSession.success'), 'success')
      } else {
        addToast(t('workplace_giving.editSession.error'), 'error')
        logError(res.error)
      }
    }
  })

  const handleEditSessionEvent = async (updatedEvent: ChildSessionEvent) => {
    if (updatedEvent && opportunityData) {
      try {
        await updateOpportunityDetailsMutation([updatedEvent])
      } catch (e) {
        logError(e)
        addToast(t('workplace_giving.createSession.error'), 'error')
      }
    }
  }

  if (((!!opportunityId && isLoading) || (!!opportunityId && !values.name)) && !isError) {
    return <Loader />
  }

  return (
    <FullPageFormLayout
      actionsHeader={
        <WizardHeader
          title={t('workplace_giving.volunteering.editEvent')}
          onCancel={() => {
            if (dirty || !!coverImage) {
              setOpenCancellationModal(true)
            } else {
              navigate(-1)
            }
          }}
          onPublish={() => {
            if (!dirty && opportunityData?.type === OpportunityType.EVENT_SERIES) {
              setOpenSuccessModal(true)
            } else {
              handleSubmit()
            }
          }}
          isPublishDisabled={!formValid}
          isLoading={isSubmitting || isFetching || isRefetching}
          toolTipCopy={t('workplace_giving.volunteering.opportunityEdit.requiresUpdates')}
        />
      }
    >
      <Typography sx={Styles.Title}>{t('workplace_giving.volunteering.editEventPage')}</Typography>
      <OpportunityForm
        opportunityData={opportunityData}
        handleCoverImageChange={handleCoverImageChange}
        coverImageError={coverImageError}
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        validateField={validateField}
        handleCreateSessionEvent={handleCreateSessionEvent}
        handleEditSessionEvent={handleEditSessionEvent}
        handleRemoveSessionEvent={handleRemoveSessionEvent}
        isLoadingSessions={isFetching || isRefetching}
      />
      <OpportunityFormCancellationModal
        handleCloseCancellationModal={handleCloseCancellationModal}
        openCancellationModal={openCancellationModal}
      />
      <OpportunityFormSuccessModal
        isEditMode
        open={openSuccessModal}
        onClose={() => {
          setOpenSuccessModal(false)
          navigate(AppRoute.OPPORTUNITY_DETAILS.replace(':opportunityId', opportunityId!))
        }}
        opportunityId={opportunityId!}
      />
      <UnexpectedErrorModal
        message={t('workplace_giving.errors.api.modal.opportunityWizard')}
        description={t('workplace_giving.errors.api.modal.submitOpportunity')}
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
      />
      <UnexpectedErrorModal
        message={t('workplace_giving.errors.somethingWentWrong')}
        open={openDetailsFetchingError}
        onClose={() => setOpenDetailsFetchingError(false)}
      />
    </FullPageFormLayout>
  )
}
