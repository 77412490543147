import { CardContentWrapper } from '../../OpportunitiesList/OpportunitiesList.styles'

import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  SignUpContainer: {
    borderRadius: lemonadeTheme.borderRadii.large,
    padding: lemonadeTheme.sizes[5],
    marginBottom: lemonadeTheme.sizes[4],
    boxShadow: '0px 2px 16px 0px #0D0D0D14',
    backgroundColor: lemonadeTheme.colors.white,

    '& p': {
      fontSize: lemonadeTheme.fontSizes.bodySmall
    }
  },
  DetailsContainer: {
    ...CardContentWrapper,

    svg: {
      minWidth: lemonadeTheme.sizes[6]
    }
  },
  DateTime: (opportunityCancelled: boolean) => ({
    p: {
      textDecoration: opportunityCancelled ? 'line-through' : 'none',
      textDecorationColor: customPalette.gray300
    }
  }),
  OrganisationInfo: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: lemonadeTheme.sizes[6],

    '& > div ': {
      'p:first-of-type': {
        color: customPalette.gray300,
        fontSize: lemonadeTheme.fontSizes.bodySmall
      }
    }
  },
  OrganiserInfo: {
    paddingX: lemonadeTheme.sizes[4],
    color: customPalette.gray600,
    fontSize: lemonadeTheme.fontSizes.bodySmall
  },
  CancelAttendance: {
    display: 'flex',
    flexWrap: 'wrap !important',

    '& > p': {
      fontSize: lemonadeTheme.fontSizes.bodySmall,
      lineHeight: lemonadeTheme.sizes[6],
      color: customPalette.gray600
    }
  },
  Address: {
    button: {
      whiteSpace: 'normal',
      textAlign: 'left'
    }
  },
  Description: {
    fontWeight: lemonadeTheme.fontWeights.medium,
    lineHeight: lemonadeTheme.sizes[6],
    marginBottom: lemonadeTheme.sizes[6]
  },
  AlertWrapper: {
    flexFlow: 'column !important',
    width: '100%',
    flexWrap: 'nowrap',
    marginBottom: `${lemonadeTheme.sizes[6]} !important`
  },
  TextLight: {
    color: `${customPalette.gray300} !important`
  }
})
