import { Styles } from '@percent/workplace-giving/types'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  AchievementsBox: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: lemonadeTheme.sizes[3]
  },
  AchievementName: {
    marginBottom: lemonadeTheme.sizes[1],
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes[6],
    fontWeight: lemonadeTheme.fontWeights.medium
  },
  AchievementStepsDescription: {
    fontSize: lemonadeTheme.sizes[3],
    lineHeight: lemonadeTheme.sizes[5],
    color: customPalette.gray300
  },
  StepsList: {
    margin: 0,
    padding: 0,
    display: 'grid',
    listStyleType: 'none',

    '& > li': {
      position: 'relative',
      marginBottom: lemonadeTheme.sizes[1],
      paddingLeft: lemonadeTheme.sizes[4],
      fontSize: lemonadeTheme.fontSizes.bodySmall,
      lineHeight: lemonadeTheme.sizes[6],

      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: lemonadeTheme.sizes[3],
        transform: 'translateY(-50%)',
        display: 'block',
        width: lemonadeTheme.sizes[2],
        height: lemonadeTheme.sizes[2],
        borderRadius: lemonadeTheme.borderRadii.small
      }
    }
  },
  StepsListItem: (completed?: boolean): Styles => ({
    color: completed ? customPalette.gray300 : lemonadeTheme.colors.black,
    textDecoration: completed ? 'line-through' : 'none',
    textDecorationColor: customPalette.gray300,

    '&::before': {
      backgroundColor: completed ? customPalette.primary : customPalette.gray90
    }
  })
})
