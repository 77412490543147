/* eslint-disable no-underscore-dangle */
import { InfiniteData } from '@tanstack/react-query'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'
import { useEffect, useMemo } from 'react'
import { Alpha2Code } from 'i18n-iso-countries'
import { useTranslation } from 'react-i18next'

import { FeaturedCards } from '../Featured/FeaturedCards'

import { useAuth, useInfiniteQuery, useLogger } from '@percent/workplace-giving/common/hooks'
import { OpportunitiesList } from './OpportunitiesList/OpportunitiesList'
import { searchOpportunities } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities'
import { Opportunity } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { PageContent } from '@percent/workplace-giving/common/components/Layout/PageContent'
import { VolunteeringSidePanel } from './VolunteeringSidePanel/VolunteeringSidePanel'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'
import countries from '@percent/workplace-giving/i18n/countries'
import { FlagAvatar } from '@percent/lemonade'

export function Volunteer() {
  const authState = useAuth()
  const codeFromAuthState = getCountryCodeFromAuthState(authState.state)
  const { t } = useTranslation()

  const alpha3CountryCodes = useMemo(
    () => [
      {
        value: '',
        label: t('workplace_giving.search.world'),
        prefix: <WorldFlagAvatar />
      },
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, 'en'),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    [t]
  )

  const defaultCountryValue = alpha3CountryCodes.filter(a => a.value === codeFromAuthState)[0]

  const { logError } = useLogger()
  const [query, setQuery] = useQueryParams({
    type: StringParam,
    locationType: StringParam,
    city: StringParam,
    country: StringParam,
    relevantSkills: ArrayParam,
    query: StringParam
  })

  useEffect(() => {
    setQuery({ ...query, country: defaultCountryValue.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, isFetching, fetchNextPage, isFetchingNextPage, hasNextPage, isError, error } = useInfiniteQuery(
    ['searchOpportunities', { ...query, pageSize: 10 }],
    searchOpportunities,
    { staleTime: 1000 * 20 }
  )

  if (isError && error) {
    logError(error)
  }

  const mappedData: InfiniteData<Opportunity[]> | undefined = data && {
    ...data,
    pages: data.pages.map(page => page.data)
  }
  const totalResults = data && data.pages.length > 0 ? data.pages[0].totalResults : 0

  return (
    <Page testId="volunteering-page-wrapper">
      <PageContent>
        <FeaturedCards mode="volunteering" />
        {!isError && (
          <OpportunitiesList
            totalResults={totalResults}
            isLoadingMore={isFetching || isFetchingNextPage}
            data={mappedData}
            hasMore={hasNextPage}
            loadMore={() => fetchNextPage()}
            query={query}
            setQuery={setQuery}
            countries={alpha3CountryCodes}
          />
        )}
      </PageContent>
      <VolunteeringSidePanel />
    </Page>
  )
}
