import { Box, Card, Skeleton } from '@mui/material'

import { getStyles } from '../SessionEvent.styles'

import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function SessionEventItemLoader() {
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <Card sx={Styles.ItemWrapper(false)} data-testid="sessionItemLoader">
      <Skeleton sx={Styles.CalendarWrapper} variant="rectangular" />
      <Box>
        <Skeleton variant="text" width={80} />
        <Skeleton variant="text" width={120} />
        <Skeleton variant="text" width={100} />
      </Box>
    </Card>
  )
}
