import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'

import { useColorTheme } from '../../hooks/useColorTheme/useColorTheme'

import { getStyles } from './SessionEventDetails.styles'
import {
  ChildSessionEvent,
  OpportunityLocationType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks'
import { dayjs } from '@percent/workplace-giving/utils/dayjs/dayjs'
import { Text } from '@percent/lemonade'

type SessionEventDetailsProps = ChildSessionEvent & {
  children?: ReactNode
}

export function SessionEventDetails({ name, startDate, location, children }: Readonly<SessionEventDetailsProps>) {
  const { t } = useTranslation()
  const { formatTime } = useDateTimeFmt()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <>
      <Box sx={Styles.Calendar}>
        <Typography component="span">{dayjs(startDate).format('MMM')}</Typography>
        <Typography component="span">{dayjs(startDate).format('D')}</Typography>
      </Box>
      <Box sx={Styles.DetailsWrapper}>
        <Text as="p" fontWeight="medium">
          {name}
        </Text>
        {startDate ? (
          <Text as="p" size="small">
            {formatTime(new Date(startDate))}
          </Text>
        ) : null}
        {location.type === OpportunityLocationType.VIRTUAL ? (
          <Text as="p" size="small">
            {t('workplace_giving.volunteering.filter.virtual')}
          </Text>
        ) : (
          <Text as="p" size="small">
            {`${location.locationDisplayName}, ${location.zipCode} ${location.country}`}
          </Text>
        )}
        {children}
      </Box>
    </>
  )
}
