import { FormikErrors, FormikTouched, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import {
  ChildSessionEvent,
  OpportunityLocationDetails,
  OpportunityLocationType,
  OpportunityType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { OpportunityFormFields } from './OpportunityForm'
import {
  CreateEventParams,
  CreateEventSeriesParams,
  CreateEventSeriesSessionParams,
  CreateProjectParams
} from '@percent/workplace-giving/api/opportunity/create/create-opportunity.types'
import { addProtocolToWebsiteUrl } from '@percent/workplace-giving/utils/url/url'
import { useAuth, useLogger } from '@percent/workplace-giving/common/hooks'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useOpportunityValidationSchema } from './useOpportunityFormValidation'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { FILE_FORMATS, FILE_SIZE_2MB_HUMAN, FILE_SIZE_LIMIT_2MB } from '@percent/workplace-giving/constants/files'
import { useCreateOpportunityMutation } from './useCreateOpportunityMutation'

export const getInitialValues = (defaultCountryCode: string): OpportunityFormFields => ({
  type: OpportunityType.EVENT,
  image: '',
  name: '',
  description: '',
  startDate: undefined,
  endDate: undefined,
  locationType: '',
  locationUrl: '',
  participantSpots: undefined,
  organisationCountry: defaultCountryCode,
  organisationId: '',
  activities: [],
  skills: [],
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  country: defaultCountryCode,
  zipCode: '',
  long: 0,
  lat: 0,
  manualTimeTracking: false,
  isSeries: false,
  childEvents: []
})

export const mapFormValuesToOpportunityLocationDetails = (
  formValues: Omit<OpportunityFormFields, 'type'>
): OpportunityLocationDetails => {
  const { locationType, locationUrl, addressLineOne, addressLineTwo, city, country, zipCode, lat, long } = formValues

  return locationType === OpportunityLocationType.VIRTUAL
    ? {
        type: OpportunityLocationType.VIRTUAL,
        link: locationUrl.length ? addProtocolToWebsiteUrl(locationUrl) : null
      }
    : {
        type: OpportunityLocationType.OFFLINE,
        addressLineOne,
        addressLineTwo,
        city,
        country,
        zipCode,
        long,
        lat
      }
}

const mapFormValuesToCreateProjectParams = (
  formValues: Omit<OpportunityFormFields, 'type'>,
  coverImage: File
): CreateProjectParams => {
  const { name, description, activities, skills, organisationId, startDate, endDate, participantSpots } = formValues

  return {
    type: OpportunityType.PROJECT,
    name,
    description,
    organisationId,
    location: mapFormValuesToOpportunityLocationDetails(formValues),
    startDate,
    endDate,
    participantSpots,
    skills,
    activities,
    image: coverImage
  }
}

const mapFormValuesToCreateEventParams = (
  formValues: Omit<OpportunityFormFields, 'type'>,
  coverImage: File
): CreateEventParams => {
  const {
    startDate,
    endDate,
    name,
    description,
    activities,
    skills,
    organisationId,
    participantSpots,
    manualTimeTracking
  } = formValues

  return {
    type: OpportunityType.EVENT,
    name,
    description,
    organisationId,
    location: mapFormValuesToOpportunityLocationDetails(formValues),
    startDate: startDate ?? new Date(),
    endDate: endDate ?? new Date(),
    participantSpots,
    itinerary: [],
    timeTracking: manualTimeTracking ? 'manual' : 'automatic',
    skills,
    activities,
    image: coverImage
  }
}

const mapFormValuesToCreateEventSeriesParams = (
  formValues: Omit<OpportunityFormFields, 'type'>,
  coverImage: File
): CreateEventSeriesParams => {
  const { name, description, activities, skills, organisationId, manualTimeTracking } = formValues

  return {
    type: OpportunityType.EVENT_SERIES,
    name,
    description,
    organisationId,
    timeTracking: manualTimeTracking ? 'manual' : 'automatic',
    skills,
    activities,
    image: coverImage
  }
}

const mapChildSessionEventToCreateEventSeriesSessionParams = (
  childEvent: ChildSessionEvent
): Omit<CreateEventSeriesSessionParams, 'parentId'> => {
  const { name, startDate, endDate, participantSpots, location } = childEvent

  return {
    type: OpportunityType.EVENT_SERIES_SESSION,
    name,
    location,
    startDate: startDate ?? new Date(),
    endDate: endDate ?? new Date(),
    participantSpots,
    itinerary: undefined
  }
}

export type UseCreateOpportunityFormProps = {
  onSuccess: (opportunityId: string) => void
  onError: (err: unknown) => void
}

export type UseCreateOpportunityFormResult = {
  coverImage: File | undefined
  coverImageError: string | undefined
  handleCoverImageChange: (file: File) => void
  isValid: boolean
  errors: FormikErrors<OpportunityFormFields>
  values: OpportunityFormFields
  handleChange: (e: React.ChangeEvent<any>) => void
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  handleBlur: (e: React.FocusEvent<any>) => void
  touched: FormikTouched<OpportunityFormFields>
  isSubmitting: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<OpportunityFormFields>>
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<OpportunityFormFields>>
  dirty: boolean
  formValid: boolean
  validateField: (name: string) => Promise<void> | Promise<string | undefined>
  sessionsFailedToCreateError: boolean
}

export const useCreateOpportunityForm = ({
  onSuccess,
  onError
}: UseCreateOpportunityFormProps): UseCreateOpportunityFormResult => {
  const { track } = useAnalytics()
  const { logError } = useLogger()
  const { state } = useAuth()
  const defaultCountryCode = getCountryCodeFromAuthState(state)!
  const { t } = useTranslation()
  const validationSchema = useOpportunityValidationSchema()

  const [coverImage, setCoverImage] = useState<File | undefined>(undefined)
  const [coverImageError, setCoverImageError] = useState<string | undefined>(undefined)
  const [sessionsFailedToCreateError, setSessionsFailedToCreateError] = useState(false)

  const handleCoverImageChange = (file: File) => {
    setCoverImageError(undefined)

    if (!/(png|jpe?g)/i.exec(file.type))
      setCoverImageError(t('workplace_giving.validation.invalidFileType', { fileFormats: FILE_FORMATS }))

    if (file.size > FILE_SIZE_LIMIT_2MB)
      setCoverImageError(t('workplace_giving.validation.fileTooLarge', { fileSize: FILE_SIZE_2MB_HUMAN }))

    setCoverImage(file)
  }

  const createOpportunityMutateAsync = useCreateOpportunityMutation({
    onSuccess,
    onCreateOpportunityError: onError,
    onCreateSessionsErrors: (errors: unknown[]) => {
      setSessionsFailedToCreateError(true)
      logError(errors)
    }
  })

  const {
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty,
    validateForm,
    validateField
  } = useFormik({
    initialValues: getInitialValues(defaultCountryCode),
    validationSchema,
    onSubmit: async data => {
      const createData = (() => {
        if (data.isSeries) return mapFormValuesToCreateEventSeriesParams(data, coverImage!)

        if (data.type === OpportunityType.EVENT) return mapFormValuesToCreateEventParams(data, coverImage!)

        if (data.type === OpportunityType.PROJECT) return mapFormValuesToCreateProjectParams(data, coverImage!)

        return mapFormValuesToCreateEventParams(data, coverImage!)
      })()

      const createChildrenData = data.childEvents.map(childEvent =>
        mapChildSessionEventToCreateEventSeriesSessionParams(childEvent)
      )

      try {
        await createOpportunityMutateAsync({
          opportunity: createData,
          sessions: data.isSeries ? createChildrenData : undefined
        })
      } catch (e) {
        logError(e)
      }

      track({
        event: 'Volunteering Opportunity Create Completed',
        properties: {
          opportunityLocationType: data.locationType,
          opportunityType: data.type
        }
      })
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  const formValid = isValid && dirty && !!coverImage && !coverImageError && !isSubmitting

  useEffect(() => {
    if (touched.image && !coverImage) {
      setCoverImageError(t('workplace_giving.validation.requiredField'))
    }
  }, [coverImage, t, touched.image])

  useEffect(() => {
    validateForm()
  }, [validateForm, values.locationType, values.organisationId])

  return {
    coverImage,
    coverImageError,
    handleCoverImageChange,
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty,
    formValid,
    validateField,
    sessionsFailedToCreateError
  }
}
