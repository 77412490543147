import {
  Opportunity,
  OpportunityLocationType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'

export const formatOfflineOpportunityAddress = (opportunity: Pick<Opportunity, 'location'>) => {
  if (opportunity.location.type !== OpportunityLocationType.OFFLINE) return undefined

  const addressLine =
    opportunity.location.locationDisplayName ??
    [
      opportunity.location.locationName,
      opportunity.location.addressLineOne,
      opportunity.location.addressLineTwo,
      opportunity.location.city
    ]
      .filter(Boolean)
      .join(', ')

  return `${addressLine}, ${opportunity.location.zipCode} ${opportunity.location.country}`
}
