import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  Opportunity,
  OpportunityLocationType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { Icon, Text } from '@percent/lemonade'
import { getStyles } from './OpportunitiesList.styles'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function OpportunityCardContent({
  startDate,
  location,
  participants,
  timeTracking,
  isSeries
}: Readonly<Opportunity>) {
  const { t } = useTranslation()
  const { formatTime, formatDate } = useDateTimeFmt()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <Box sx={Styles.CardContentWrapper}>
      {timeTracking === 'manual' || !startDate ? null : (
        <>
          <Box data-testId="opportunity-card-date-info">
            <Icon name="calendar" size="m" color="neutral300" />
            <Text as="p" size="small" fontWeight="medium">
              {formatDate(new Date(startDate))}
            </Text>
          </Box>
          <Box>
            <Icon name="clock" size="m" color="neutral300" />
            <Text as="p" size="small" fontWeight="medium">
              {formatTime(new Date(startDate))}
            </Text>
          </Box>
        </>
      )}
      {isSeries ? (
        <Box>
          <Icon name="info" size="m" color="neutral300" />
          <Text as="p" size="small" fontWeight="medium">
            {t('workplace_giving.volunteering.multipleSessions')}
          </Text>
        </Box>
      ) : (
        <>
          <Box>
            <Icon
              name={location.type === OpportunityLocationType.VIRTUAL ? 'virtual-meeting' : 'pin'}
              size="m"
              color="neutral300"
            />
            <Text as="p" size="small" fontWeight="medium">
              {location.type === OpportunityLocationType.VIRTUAL
                ? t('workplace_giving.volunteering.virtualOpportunity')
                : `${location.city}, ${location.country}`}
            </Text>
          </Box>
          <Box>
            <Icon name="agents" size="m" color="neutral300" />
            <Text as="p" size="small" fontWeight="medium">
              {t('workplace_giving.volunteering.attendees')} {participants.count}
              {participants.spots ? `/${participants.spots}` : null}
            </Text>
          </Box>
        </>
      )}
    </Box>
  )
}
