import { Styles } from '@percent/workplace-giving/types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const Wrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: lemonadeTheme.sizes.m,
  marginTop: lemonadeTheme.sizes.s
}

export const Link: Styles = {
  textDecoration: 'none',
  borderBottom: '1px solid'
}
