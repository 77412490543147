import { DateTimePicker as MantineDateTimePicker, DatePickerInput, DateValue } from '@mantine/dates'
import { Box } from '@mui/material'
import { FocusEvent } from 'react'

import { useColorTheme } from '../../hooks/useColorTheme/useColorTheme'

import { getStyles } from './DateTimePicker.styles'

interface DateTimePickerProps {
  fieldName: string
  value?: DateValue
  error: boolean
  handleChange: (date?: Date) => void
  handleBlur: (e: FocusEvent<HTMLButtonElement, Element>) => void
  minDate?: Date
  noTimeField?: boolean
  disabled?: boolean
  handleFieldClear?: VoidFunction
  withinPopup?: boolean
}

export function DateTimePicker({
  fieldName,
  handleChange,
  handleBlur,
  error,
  value,
  minDate,
  noTimeField = false,
  disabled,
  handleFieldClear,
  withinPopup = false
}: Readonly<DateTimePickerProps>) {
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <Box sx={Styles.PickerStyles}>
      {!noTimeField ? (
        <MantineDateTimePicker
          left={0}
          name={fieldName}
          value={value}
          minDate={minDate}
          dropdownType="modal"
          modalProps={{ zIndex: 2000, withinPortal: !withinPopup }}
          popoverProps={{ zIndex: 2000 }}
          valueFormat="DD/MM/YYYY hh:mm A"
          placeholder="DD/MM/YYYY hh:mm"
          error={error}
          onBlur={e => {
            e.target.name = fieldName
            handleBlur(e)
          }}
          onChange={date => {
            handleChange(date ? new Date(date) : undefined)
          }}
          timeInputProps={{
            placeholder: 'hh:mm AM/PM'
          }}
          submitButtonProps={{
            'aria-label': 'Save time',
            onClick: e => {
              const blurEvent = e as unknown as FocusEvent<HTMLInputElement, Element>
              blurEvent.target.name = fieldName
              handleBlur(blurEvent as any)
            }
          }}
          disabled={disabled}
          clearable={!!handleFieldClear}
          clearButtonProps={{
            onClick: handleFieldClear,
            title: `clear ${fieldName}`
          }}
        />
      ) : (
        <DatePickerInput
          name={fieldName}
          value={value}
          minDate={minDate}
          dropdownType="modal"
          modalProps={{ zIndex: 2000, withinPortal: !withinPopup }}
          popoverProps={{ zIndex: 2000 }}
          valueFormat="DD/MM/YYYY"
          placeholder="DD/MM/YYYY"
          error={error}
          onBlur={handleBlur}
          onChange={date => {
            handleChange(date ? new Date(date) : undefined)
          }}
          disabled={disabled}
          clearable={!!handleFieldClear}
          clearButtonProps={{
            onClick: handleFieldClear,
            title: `clear ${fieldName}`
          }}
        />
      )}
    </Box>
  )
}
